@import "assets/core/_variables";
@import "assets/_mixins";

.three-d-generator, .game-concept .gdd-side-menu-3 {
  .ludo-modal {
    display: none;
  }

  .form {
    .initial-image-button {
      height: 95px;
      margin: 0;
    }
  }


  .file-wrapper {
    position: relative;
    width: fit-content;

    .sliders {
      flex-grow: 1;
      padding: 5px 20px;
      place-self: center;
      display: flex;
      height: 100%;
      flex-direction: column;

      .slider-title {
        margin-bottom: 10px;
        .inner-span {
          text-transform: capitalize;
        }
      }

      .MuiSlider-track {
        background: $lightBlue;
      }

      .MuiSlider-valueLabel {
        top: -16px;
        :after {
          display: none;
        }
      }

      .MuiSlider-mark {
        background: transparent;
      }

      span {
        color: $lightBlue;
      }

      .MuiSlider-thumb {
        background: $lightBlue;
        box-shadow: none;
        border: $primary solid 1px;

        span span {
          background: none;
        }
      }
    }

    .model-wrapper {
      position: relative;
      display: flex;
      align-self: anchor-center;
      align-self: center;
      .three-d-asset {
        width: 150px;
        height: 150px;

        .image-thumbnail {
          display: none;
        }
      }

      .delete-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #666;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 5px;
        z-index: 1;

        &:hover {
          color: #f44336;
          background-color: rgba(255, 255, 255, 0.9);
        }
      }

      .three-d-model-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e0e0e0;
        border-radius: 15px;
        padding: 20px;
        width: 150px;
        height: 150px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;

        .three-d-icon {
          font-size: 64px;
          color: #4a4a4a;
          margin-bottom: 10px;
          filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
        }

        .model-text {
          font-size: 14px;
          color: #4a4a4a;
          text-align: center;
          font-weight: 500;
          max-width: 90%;
        }
      }
    }
  }

  .three-d-wrapper {
    flex-direction: row !important;
    width: 100%;
  }

  .file-wrapper-grid {
    align-self: center;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .file-wrapper {
    //margin-bottom: 15px;
    img {
      object-fit: cover;
      width: 170px;
      height: 170px;
      border-radius: 15px;
    }
  }

  .main-generator-content {
    //max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      align-self: center;
      gap: 20px;

      .text-blue {
        color: $secondary;
      }

      .generate-more-button {
        align-self: center;
        margin-top: 0;
      }
    }

    .react-photo-gallery--gallery > div {
      place-content: center;
    }
  }

  @include respond($phone) {
    .image-options-wrapper {
      flex-direction: column;
    }
    .results {
      flex-direction: column;
    }
  }
}

.three-d-assets {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.three-d-asset {
  border-radius: 15px;
  position: relative;
  justify-items: center;
  align-items: center;
  width: 500px;
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  place-content: space-around;

  &.mesh {
    &.threeD {
      background: #8a8a8a !important;
    }
  }

  .my-image {
    img {
      max-height: 500px;
      width: auto;
    }
  }

  .button-linear-progress {
    position: absolute;
    bottom: 20px;
  }

  .controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
    gap: 10px;
    align-items: center;

    .mesh-toggle {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 25px;
      padding: 5px;
      transition: all 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
      }

      .MuiSwitch-root {
        width: 58px;
        height: 38px;
        padding: 0;
      }

      .MuiSwitch-switchBase {
        background: $secondary;
      }

      .MuiSwitch-thumb {
        width: 28px;
        height: 28px;
      }

      .MuiSwitch-track {
        border-radius: 20px;
      }

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        color: white;
      }
    }

    .button {
      background: $secondary;
      color: white;
      width: 35px;
      height: 35px;
      .MuiIconButton-label {
        width: 20px;
        svg {
          color: white;
        }
      }
    }
  }

  &.threeD {
    background: #bbbbbb;

    video {
      width: 100%;
      border-radius: 15px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      max-width: 100%;
    }

    .image-placeholder {
      z-index: 0;
      opacity: 0;
      img {
        height: auto;
        max-height: 500px;
      }
    }

    .image-thumbnail {
      border-radius: 20px;
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 1;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      width: min(20%, 100px);
      height: auto;

      .my-image,
      img {
        border-radius: 20px;
        height: 100%;
        padding: 5px;
        width: -webkit-fill-available;
      }
      .my-image {
        background: $formBlue;
        .image-actions,
        .gradient-top {
          display: none;
        }
      }
    }
  }

  &.image {
    background: $formBlue;

    .my-image {
      //height: 80%;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 70px;

      img {
        height: fit-content;
      }
    }

    .video-thumbnail {
      border-radius: 20px;
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 1;
      cursor: pointer;
      width: min(20%, 100px);
      height: auto;
      video {
        border-radius: 20px;
        width: 100%;
      }
    }
  }

  @include respond($phone) {
    max-width: 100vw;
    video {
      max-width: 100%;
    }
    &.image {
      .my-image {
        align-items: center;
      }
    }
  }
}
