/* Icon Grow */
@mixin icon-grow {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(transform, scale(1.3) translateZ(0));
		}
	}
}
