@import "assets/core/_variables";

.game-card {
  width: 230px;
  color: white;
  position: relative;
  cursor: pointer;
  margin: 15px auto;

  &.locked {

    &:hover {
      .lock-icon {
        display: flex;
      }
    }

    .icon {
      img {
        filter: blur(3px);
        -webkit-filter: blur(3px);
        -webkit-clip-path: inset(0);
        clip-path: inset(0);
      }
    }

    .cover-image {
      filter: blur(8px);
      -webkit-filter: blur(8px);
      -webkit-clip-path: inset(8x);
      clip-path: inset(0);

      .gradient-top {
        display: none;
      }
    }

    .name {
      background: $primary;
      opacity: 0.5;
      width: 70%;
    }

    .developer, .alt-text {
      background: $secondary;
      opacity: 0.5;
      width: 50%;
    }

    .alt-text {
      width: 35%!important;
    }

  }

  &:hover {
    z-index: 1;

    .game-actions {
      visibility: visible;
    }

    .source-icon {
      display: block;
    }

    .cover-image {
      .gradient-top {
        opacity: 1;
      }
    }
  }

  .game-actions {
    display: flex;
    visibility: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 3;
    flex-direction: row;

    button {
      padding: 5px;
    }

    .MuiAvatar-root {
      display: inline-flex;
      top: 15px;
    }
  }

  .cover-image {
    z-index: 1;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 180px;
    border-radius: 12px;

    .gradient-top {
      border-radius: 12px;
      opacity: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
    }
  }

  .card-content {
    z-index: 2;
    height: 100%;
    width: 100%;
  }

  .icon {
    z-index: 1;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -12px;
    left: -12px;
    border-radius: 20px;
    overflow: hidden;
    border: 4px solid white;
    box-sizing: content-box;
    box-shadow: 8px 8px 10px 1px rgba(0, 0, 0, 0.6);

    img {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }

  .source-icon {
    z-index: 1;
    display: none;
    width: 55px;
    height: 55px;
    position: absolute;
    border-radius: 20px;
    top: -10px;
    left: -10px;
    border: 2px solid transparent;
    background: white;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .change {
    right: 7px;
    left: initial;
    color: white;
    font-size: 14px;
    top: 5px;
    z-index: 1;
    position: absolute;
  }

  .card-info {

    margin: 5px 5px 5px 0;
    z-index: 1;

    .name, .developer, .alt-text {
      //filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
      display: block;
      margin: 5px 0;
    }

    .alt-text {
      ul {
        padding-left: 0;

        li {
          list-style: none;
          padding: 0;
        }
      }
    }

    .name {
      font-size: 15px;
      line-height: 18px;
      color: $primary;
      font-weight: bold;
    }

    .developer {
      font-size: 13px;
      line-height: 14px;
      color: $lightBlue;
    }

    .alt-text {
      display: flex;
      color: $lightBlue;
      font-size: 13px;
      line-height: 14px;
      cursor: default;
      width: fit-content;

      .MuiChip-root {
        height: 15px;
        background: none;
        color: $secondary;
        border: 1px solid $secondary;
        position: relative;

        span {
          font-size: 12px;
        }
      }

      span {
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 10px;
      }
    }
  }

  .genre {
    position: absolute;
    right: 10px;
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid white;
    display: inline-block;
    border-radius: 20px;
    padding: 0 10px;
    font-size: 14px;
  }
}
