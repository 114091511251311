@import "assets/core/_variables";

.trends-page-content {
  background: #f8f7fb;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $indigo;
  container-type: inline-size;

  .filters {
    padding: 20px;
    display: flex;
    gap: 15px;
    form {
      display: flex;
      gap: 15px;
      .MuiFormControl-root,
      .show-visible-if {
        max-width: 230px;
        width: 100%;
      }
      .MuiSelect-root {
        color: #4F80FF;
        font-weight: bold;
      }
      .MuiInput-underline {
        &::after, &::before {
          border-bottom: 2px solid rgba(#4F80FF, 0.15);
        }
        svg {
          color: #4F80FF;
        }
      }
    }
  }

  .trends-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    position: relative;
    flex-grow: 1;
    container-type: inline-size;

    .trends-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 20px;
      padding: 0;
      grid-template-areas:
        "overall overall overall specific"
        "landscape landscape landscape specific"
        "table table table specific";

      .overall {
        grid-area: overall;
      }

      .landscape {
        grid-area: landscape;
      }

      .table {
        grid-area: table;
      }

      .specific {
        height: fit-content;
        grid-area: specific;
        padding-bottom: 50px;
        .trends-box-content {
          display: flex;
          flex-direction: column;

          .borders {
            border-bottom: 2px solid rgba(40, 26, 105, 0.1);
          }

          .trends-listing-content {
            gap: 20px;
            .trends-listing-item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }

      @container (max-width: 1050px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
          "overall"
          "specific"
          "landscape"
          "table";
        .specific {
          height: unset;
          .trends-box-content {
            display: grid;
            grid-auto-columns: 1fr 5px 1fr 5px 1fr 5px 1fr;
            .borders {
              border-bottom: none;
            }
            .trends-listing-content {
              gap: 20px;
              display: flex;
              flex-direction: column;

              .trends-listing-item {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
      @container (max-width: 705px) {
        .specific {
          .trends-box-content {
            display: flex!important;
            .trends-listing-content {
              .trends-listing-item {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
              }
            }
          }
        }
      }
      @container (max-width: 400px) {
        .specific {
          .trends-box-content {
            display: flex!important;
            .trends-listing-content {
              .trends-listing-item {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    .single-request-results {
      max-width: 100%;
      padding-top: 20px;
      .single-title {
        display: flex;
        padding-left: 5px;
        span {
          font-size: 22px;
          font-weight: bold;
        }
      }
      .topic-expanded {
        .top-row {
          align-items: center;
        }
      }
    }

    .topics-wrapper {
      padding-left: 15px;
    }
  }

  .market-landscape {
    width: 100%;
    padding: 20px 20px 60px 80px;

    @container (max-width: 705px) {
      padding-left: 60px;
      .market-chart {
        .chart-label {
          &.vertical {
            &:nth-child(odd) {
              display: none;
            }
          }
        }
      }
    }

    .market-chart {
      min-height: 300px;
      width: 100%;
      position: relative;

      .chart-label {
        font-size: 14px;
        &.horizontal {
          position: absolute;
          left: -45px;
          text-align: right;
          width: 40px;
        }
        &.vertical {
          position: absolute;
          bottom: -30px;
        }
      }

      .axis-label {
        position: absolute;
        color: $indigo;
        white-space: nowrap;

        &.x-axis-label {
          bottom: -50px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.y-axis-label {
          left: -152px;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          transform-origin: center;
        }
      }

      .chart-axis {
        position: absolute;
        background-color: rgba($indigo, 0.1);

        &.x-axis {
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
        }

        &.y-axis {
          left: 0;
          bottom: 0;
          width: 2px;
          height: 110%;
        }
      }

      .chart-line {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.1);

        &.horizontal {
          left: 0;
          right: 0;
          height: 1px;
          border-top: 1px dashed rgba(0, 0, 0, 0.2);
        }
      }

      .market-game-wrapper {
        position: absolute;
        width: 30px;
        height: 30px;
        cursor: pointer;

        &:hover {
          z-index: 1;

          .market-game {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
            transform: scale(3);
          }
        }
        .market-game {
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          overflow: hidden;

          transition: all 0.08s linear;
          transform: translateX(-50%) translateY(50%);

          .icon {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
  }

  .trends-listing {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .top-bar {
      font-size: 16px;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-auto-rows: 1fr;
      align-items: center;
      .title-wrapper {
        display: grid;
        align-items: center;
      }
      .title {
        font-weight: bold;
      }
      .link,
      svg {
        color: rgba($indigo, 0.5);
      }
    }

    .trends-listing-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 5px;

      .trends-listing-item {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .image {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
        }

        span {
          text-align: center;
          font-size: 14px;
          line-height: 16px;
        }

        .MuiLinearProgress-root {
          width: 100%;
          max-width: 200px;
          height: 6px;
          background: #F4F3F7;
          .MuiLinearProgress-bar {
            background: $purpleGradient;
          }
        }
      }

      &.bars {
        grid-template-columns: 1fr;
        gap: 15px;
        .trends-listing-item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          align-items: center;
          span {
            text-align: left;
          }
        }
      }
    }
  }

  .table-container {
    overflow-x: auto;
  }

  .trends-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;

    thead {
      border-top: 2px solid rgba($indigo, 0.1) !important;
      span {
        text-transform: capitalize;
      }
      tr, th {
        background: white !important;
        border: none;
      }
    }

    th,
    td {
      padding: 15px;
      text-align: left;
      border: none;
      //border-bottom: 1px solid $border-color;
      font-size: 14px;
    }

    th {
      color: rgba($indigo, 0.51);
      font-weight: normal;
    }

    td,
    th {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }

    td {
      &:first-child {
        font-weight: bold;
      }
    }

    .table-body {
      :first-child {
        :first-child {
          border-radius: 10px 0 0 0;
        }
        :last-child {
          border-radius: 0 10px 0 0;
        }
      }
      :last-child {
        :first-child {
          border-radius: 0 0 0 10px;
        }
        :last-child {
          border-radius: 0 0 10px 0;
        }
      }
    }

    tr {
      color: $indigo;
      cursor: pointer;

      &:first-child {
        text-align: left;
      }

      &:nth-child(odd) {
        background: rgba($indigo, 0.04);
      }

      &:hover {
        background-color: $gray-500;
      }
    }
  }

  .trends-box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    max-width: calc(100vw - 40px);

    .trends-box-content {
      display: grid;
      //grid-template-columns: repeat(var(--item-count), 1fr);
      //grid-auto-rows: 1fr;
      grid-auto-columns: 1fr 5px 1fr 5px 1fr;
      grid-auto-flow: column;
      gap: 20px;
      padding-top: 20px;

      .borders {
        border-left: 2px solid rgba($indigo, 0.1);
        height: 100%;
      }
    }

    .trends-box-title {
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      display: flex;
      border-bottom: 2px solid rgba($indigo, 0.1);
      padding-bottom: 15px;
      margin: auto;
      place-content: center;
    }

    &.no-hr {
      .trends-box-title {
        border-bottom: none;
      }
    }

    @container (max-width: 800px) {
      flex-grow: 1;

      .trends-box-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .borders {
          width: 100%;
          border-top: 2px solid rgba($indigo, 0.1);
        }

        .trends-listing {
          max-width: 100%;

          .trends-listing-content {
            gap: 15px;

            .trends-listing-item {
              max-width: unset;
              align-items: center;

              span {
                overflow: hidden;
                //text-overflow: ellipsis;
                //white-space: nowrap;
                max-width: 300px;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
