@import "assets/core/_variables";

.subscription-modal {
  .MuiDialog-container .MuiPaper-root {
    padding-bottom: 0 !important;

    .MuiPaper-root {
      height: unset;
      width: unset;
    }
  }
}

.subscription-modal-content {

  overflow: hidden;
  width: 100%;

  .subscriptions-panel-wrapper {

    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100vh;

    .side-panel {
      width: 400px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      box-shadow: -11px 0px 5px 0px rgba(0, 0, 0, 0.15);

      .invoice-list {
        .invoice {
          background: #153775;
          color: white;
          margin: 5px 20px;
          display: flex;
          flex-direction: column;
          text-align: left;
          cursor: pointer;

          &:hover {
            background: transparentize(#153775, 0.25);
          }

          .price {
            text-align: center;
            display: inline-flex;
            place-content: center;
            font-weight: bold;
            color: white;
            width: 100%;
          }

          .paid-status {
            font-weight: bold;
            background: $red;

            &.paid-true {
              background: $lightBlue;
            }
          }
        }
      }

      .top-bar {
        height: 69px;
        background: $primary;
        position: relative;
        display: flex;
        flex-direction: row;
        background: $primary;
        color: white;
        padding: 25px;

        .title {
          flex-grow: 1;
          font-weight: bold;
        }

      }

      .panel-content {
        overflow-y: auto;
        height: 100%;
        background: $primary;

        .add-credit-card-wrapper {
          color: white;
          background: #153775;
          padding: 20px 20px;

          .collapse-control, .collapse-control svg {
            color: white;
          }
        }

        .credit-cards-wrapper .actions {
          display: block;
        }

      }
    }

    .credit-cards-wrapper {

      .card-loading-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparentize(white, 0.5);
        border-radius: 15px;
        z-index: 1;
        display: flex;
        place-content: center;
        align-items: center;

      }
    }

    .credit-cards-list {
      padding: 10px 20px;

      .card-grid {
        flex-basis: fit-content;
      }

      .topspan {
        text-align: center;
        display: block;
        margin-bottom: 20px;
        font-weight: bold;
      }

      .actions {
        display: none;
        position: absolute;
        bottom: 0;
        left: 15px;

        svg {
          color: white;
        }
      }
    }

    .subscriptions-main-content {

      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
      padding-bottom: 50px;

      .top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $primary;
        color: white;
        padding: 25px;

        .options {
          display: flex;
          flex-direction: row;
          place-content: flex-end;
          padding-right: 10px;
          flex-grow: 1;

          button {
            margin-top: 0 !important;
          }

          span {
            color: white;
          }
        }
      }
    }
  }
}

.subscriptions {

  padding: 0 50px;

  .credit-cards-wrapper, .user-details {
    max-width: 700px;
    margin: auto;
    padding-bottom: 50px;
  }

  .top-span {
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }

  .MuiStepper-root {
    width: 100% !important;
    max-width: 820px !important;
    height: 200px !important;
    margin: 0 auto !important;

    .bg-success {
      background: $lightBlue !important;
      cursor: pointer;
    }
  }

  .interval-tabs {
    position: relative;
    top: 10px;
    border-bottom: 1px solid $secondary !important;

    .MuiTab-wrapper {
      color: $primary;
    }

    .MuiTabs-root {
      border-bottom: 1px solid $secondary !important;
    }

    .MuiTab-root {
      overflow: visible !important;
    }

  }

  .MuiSwitch-track {
    background: #1D4EA4 !important;
  }

  .payment-summary {
    border: 1px solid $lightBlue;
    border-radius: 10px;
    padding: 25px;
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .plan-action {
      margin: 25px auto 0 auto;
      .action-button {
        margin: 0 auto !important;
        display: block;
        border-radius: 30px;
        background: $purpleGradient !important;
        .MuiButton-label {
          color: white !important;
        }

        &.Mui-disabled {
          background: none;
          color: white;

          svg {
            color: white;
          }
        }
      }
    }

    hr {
      width: 100%;
      border-top: 2px solid rgba($primary, 0.1);
    }

    .row {
      display: flex;
      flex-direction: row;
      .grow {
        flex-grow: 1;
      }
    }
  }

  .promo-code {

    .promo-fields {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .MuiTextField-root {
        max-width: 250px;
      }

      button {
        margin-top: -5px !important;
        margin-left: 10px;
      }
    }
    .MuiPaper-root {
      padding: 0 10px !important;
    }
  }

  .add-credit-card-wrapper {
    margin: 20px auto;
    max-width: 400px;
  }

  .all-plans {
    display: flex;
    margin: auto;
    gap: 0 50px;
    justify-content: center;
    flex-wrap: wrap;
    .plans-wrapper {
      display: flex;
      flex-direction: column;

      .monthly-switch {
        display: flex;
        margin: 50px auto 80px 0;
        width: 100%;
        gap: 10px;
        justify-content: center;

        hr {
          width: 100%;
          border-top: 2px solid rgba(black, 0.1);
        }

        .MuiSwitch-root {
          width: 42px;
          height: 26px;
          padding: 0;
        }
      }

      .just-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        height: 100%;
      }
    }

  }

  .plan-wrapper {
    position: relative;
    width: fit-content;
    margin: auto;
    height: 100%;
    max-width: 330px;

    .special-tag {
      position: absolute;
      width: 80%;
      left: 10%;
      top: -40px;
      border-radius: 20px;
      padding: 5px 0;
      text-align: center;
      background: $secondary;
      color: white;
      font-weight: bold;
      border: 1px solid $secondary;

      &.white {
        background: white;
        color: $secondary;
      }
    }
  }

  .plan {
    max-width: 330px;
    height: 100%;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 12px rgba(20, 20, 43, .08);
    border-radius: 24px;
    padding: 40px 33px;
    min-width: 320px;
    border: 1px solid $secondary;
    transition: all 0.3s ease-in-out;

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 100%;

      .name {
        display: flex;
        flex-direction: row;
        align-items: center;

        .box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          width: 72px;
          height: 72px;
          border-radius: 16px;
          background: rgba(232, 238, 255, .72);
        }
      }

      .price-request {
        display: block;
        font-size: 25px;
        line-height: 25px;
      }

      .plan-action {
        .blue {
          color: #70ACE7;
        }
      }

      .description {
        font-size: 15px;
      }

      .price-content {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .amount {
          font-weight: bold;
          font-size: 35px;
          &.promo {
          }
        }

        .period {
          font-size: 15px;
          color: #9a9a9a;
          line-height: 15px;
        }

        strike {
          opacity: 0.5;
        }
      }

      .features {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;

        .feature-icon {
          width: 25px;
          display: inline-block;
        }

        .red {
          color: #CC071A;
        }

        .green {
          color: $lightBlue;
        }

        .yellow {
          color: $secondary;
        }

        .small {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .plan-action {
        .action-button {
          margin: 0 auto !important;
          display: block;
          border-radius: 30px;
          background: $purpleGradient !important;
          .MuiButton-label {
            color: white !important;
          }

          &.Mui-disabled {
            background: none;
            color: white;

            svg {
              color: white;
            }
          }
        }
      }
    }

    &.pointer {
      &:hover {
        box-shadow: 0 4px 20px rgba(20, 20, 43, .32);
      }
    }

    &.recurring {
      background: #5780f7;
      border: 1px solid #5780f7;

      .card-content {
        color: white;

        .name {
          .box {
            background: white;
          }
        }

        .price-content {
          .period {
            color: #d4d4dc;
          }
        }

        .features {
          .feature-icon {
            svg {
              color: white;
            }
          }
        }

        .action-button {
          background: white !important;

          .MuiButton-label {
            background: rgb(162, 104, 238);
            background: linear-gradient(81deg, $secondary 0%, $secondary 15%, $lightBlue 50%, $secondary 85%, $secondary 100%);
            background-clip: text;
            background-size: 300% 100%;
            animation: slide 2.5s linear infinite;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}
