@import "assets/core/_variables";

.favorites {

  .app-page-title-bg {
    height: 100% !important;
  }

  .MuiButtonBase-root {
    overflow: visible;
  }

  .MuiTab-wrapper {
    .MuiBadge-badge {
      font-size: 13px;
    }
  }

  .favorites-mobile-dropdown-wrapper {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: column;

    .main-content {
      padding: 0 30px 30px 30px;

      &.tab-0 {
        padding: 0 0 30px 0;
      }

    }

  }
}
