@import "assets/core/_variables";

.image-generator-modal {
  .MuiPaper-root {
    overflow-y: scroll;
  }

  .modal-content {
    box-shadow: unset;
  }

  .dropzone-wrapper {
    max-width: 90vw;
  }

  .gallery-wrapper {
    width: 600px;
    max-height: 100vh !important;
    max-width: 80vw !important;
    min-width: 600px !important;
    min-height: fit-content !important;
  }
}

.image-generator {
  .ludo-modal {
    display: none;
  }

  .form-wrapper {
    .input-fields {
      flex-direction: row;
    }
  }

  .file-wrapper-grid {
    align-self: center;
  }

  .file-wrapper {
    img {
      object-fit: cover;
      width: 170px;
      height: 170px;
      border-radius: 15px;
    }
  }

  .image-options-wrapper {
    display: flex;
    align-items: center;
  }

  .slider-wrapper {
    flex-direction: column;
    display: flex;
    gap: 20px;

    .title {
      place-self: unset;

      .title-span {
        opacity: 0.7;
        font-size: 15px;
      }
    }

    .slider {
      .MuiSlider-markLabel {
        display: none;
      }

      .MuiSlider-rail,
      .MuiSlider-track {
        height: 2px;
        background: $lightBlue;
      }

      .MuiSlider-mark {
        background: transparent;
      }

      .MuiSlider-thumb {
        background: $lightBlue;
        box-shadow: none;
        border: $primary solid 1px;

        span span {
          background: none;

          span {
            color: $lightBlue;
            font-weight: bold;
            font-size: 15px;
          }
        }
      }
    }

    .retain-wrapper {
      display: flex;
      flex-direction: column;

      .checkboxes {
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
      }
    }
  }

  .main-generator-content {
    //max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      align-self: center;
      gap: 20px;

      .text-blue {
        color: $secondary;
      }

      .generate-more-button {
        align-self: center;
        margin-top: 0;
      }
    }

    .react-photo-gallery--gallery > div {
      place-content: center;
    }

    .my-image {
      img {
        max-height: 550px;
        width: auto;
      }
    }

    img {
      height: auto;
    }
  }
}

.image-editor-modal {
  .top-right {
    button {
      svg {
        color: $lightBlue !important;
      }
    }
  }

  .text-primary {
    padding: 0 24px 16px 24px;
  }

  .MuiPaper-root {
    overflow-y: hidden;
  }

  .modal-content {
    box-shadow: unset;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .MuiSlider-track {
      background: $lightBlue !important;
    }

    .description {
      color: $tertiary;
      font-size: 15px;
    }

    .results-panel {
      min-width: 350px;
      width: 350px;
      height: 100%;
      background: $darkBlue;
      color: white;
      padding: 20px 20px;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .title {
        display: inline-flex;
        font-size: 24px;
        font-weight: bold;
        color: $lightBlue;
      }

      .form {
        padding-top: 20px;

        .error {
          color: $red;
          font-size: 14px;
          margin-top: 10px;
          text-align: center;
          display: block;
        }

        .select-field {
          background: black;
          border-radius: 25px;
          padding-left: 20px;
          display: flex;
          place-content: center;
          justify-content: center;
          margin-top: 15px;

          .MuiSelect-root {
            display: flex;
            gap: 15px;
            .MuiListItemIcon-root {
              align-items: center;
              min-width: unset;
            }
          }

          svg {
            color: white;
          }

          span {
            color: white !important;
            font-weight: bold;
          }

          .MuiInput-root {
            margin-top: 5px;
          }

          .MuiListItemText-secondary {
            color: white;
            opacity: 0.8;
            display: none;
          }

          .MuiSelect-icon {
            color: white;
            margin-right: 5px;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after, &:before, &:after {
            display: none!important;
          }

          .MuiFormControl-root > div:before {
            border-bottom-color: white !important;
          }

          .MuiFormLabel-root {
            display: none;
          }
        }

        .text-field {
          color: white !important;
          margin-top: 20px;

          .MuiInputBase-root {
            background: black;
            border-radius: 25px;
            padding: 15px;

            &::after {
              display: none;
            }
          }

          textarea {
            &::placeholder {
              opacity: 0.75;
            }
          }

          .MuiInputBase-root,
          label {
            color: white;
          }
        }

        .slider {
          display: flex;
          gap: 25px;
          margin-top: 25px;
          padding: 0 10px;

          .MuiSlider-markLabel {
            color: white;
          }

          .title-span {
            white-space: nowrap;
          }
        }
      }
      .results {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .image-history {
          display: flex;
          flex-direction: column-reverse;
          gap: 5px;

          .image-wrapper {
            border: 2px solid transparent;
            padding: 1px;
            border-radius: 13px;
            position: relative;
            &.selected {
              border: 2px solid $lightBlue;
            }

            &:hover .image-buttons {
              display: flex;
            }

            .image-buttons {
              position: absolute;
              display: none;
              gap: 40px;
              top: 0;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              pointer-events: none;

              .icon-button {
                pointer-events: auto;
                background: $secondary;
                width: 50px;
                height: 50px;
                padding: 5px !important;
                svg {
                  font-size: 30px;
                  color: white;
                }
              }
            }
          }
        }

        .small-title {
          color: $lightBlue;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    .image-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-self: center;
      .description {
        align-self: center;
        font-size: 19px;
        font-weight: bold;
        color: $tertiary;
        margin-bottom: 20px;
      }
    }

    .mode-toggle {
      position: relative;
      margin: 0 auto 10px auto;
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      justify-content: center;

      &.crop {
        .canvas-controls {
          display: flex;

          .clear-crop {
            display: flex;
          }
        }
      }

      &.segmentation {
        .canvas-controls {
          display: flex;

          .points,
          .clear-points,
          .clear-mask {
            display: flex;
          }
        }
      }

      &.mask {
        .canvas-controls {
          display: flex;

          .slider-wrapper,
          .clear-mask {
            display: flex;
          }
          .slider-wrapper {
            width: 150px;
          }
        }
      }

      .canvas-controls {
        display: none;
        flex-direction: row;
        flex-grow: 1;
        max-width: 450px;
        gap: 15px;
        align-self: center;

        .clear-crop {
          width: 100px;
          display: none;
        }

        .clear-mask {
          width: 100px;
          display: none;
        }

        .clear-points {
          width: 100px;
          display: none;
        }

        .points {
          display: none;
          flex-direction: row;
          flex-grow: 1;
          max-width: 300px;
          gap: 10px;
          white-space: nowrap;
          place-items: center;
        }

        .slider-wrapper {
          display: none;
          flex-direction: row;
          flex-grow: 1;
          max-width: 300px;
          gap: 10px;
          white-space: nowrap;
          place-items: center;
          color: white;
        }
      }

      button {
        padding: 5px 15px;
        border: 1px solid $gray-500;
      }
    }

    .mask-container {
      width: fit-content;
      margin: auto;
      position: relative;
      max-width: calc(min(90vw, 1200px));
      height: auto;

      &.has-mask,
      &:hover {
        .mask-canvas {
          opacity: 0.35;
        }
        .segmentation-mask {
          opacity: 0.25;
          top: 0;
        }
      }

      &.hovered-image {
        .mask-canvas {
          opacity: 0;
        }
      }

      .cropped-image {
        height: 100%;
        width: 100%;
        max-height: calc(min(70vh, 600px));
        margin: auto;
        z-index: 0;
        position: relative;

        background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
          linear-gradient(-45deg, #ccc 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #ccc 75%),
          linear-gradient(-45deg, transparent 75%, #ccc 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
      }

      .points-canvas {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
        opacity: 0.5;
        top: 0;
      }

      .mask-canvas {
        position: absolute;
        z-index: 99;
        //opacity: 0.5;
        opacity: 0;
        background: white;
        top: 0;
      }

      .loading-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        opacity: 0.5;
        z-index: 101;
        pointer-events: none;
      }
      .segmentation-mask {
        position: absolute;
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
        z-index: 100;
        left: 0;
      }
    }

    .image-container {
      //height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: fit-content;
      margin: auto;

      max-width: calc(min(90vw, 1200px));
      height: auto;

      .bg-image {
        //width: 100%;
        max-height: calc(min(70vh, 600px));
      }

      .segmentation-mask-wrapper {
        position: absolute;
        //top: 0;
        .segmentation-mask {
          position: absolute;
          opacity: 0.5;
          user-select: none;
          pointer-events: none;
          z-index: 100;
          width: 100%;
          height: 100%;
        }
      }

      .ReactCrop {
        margin: auto;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;

        .ReactCrop__drag-handle::after {
          width: 20px;
          height: 20px;
        }
      }

      & > div {
        video {
          height: 600px;
          object-fit: contain;
        }
      }
    }

    .controls {
      margin: auto;
      display: flex;
      gap: 20px;
      justify-content: center;

      .MuiButtonBase-root {
        padding: 5px 10px;
        border-radius: 5px;
      }

      svg {
        font-size: 30px;
      }
    }

    .my-button {
      display: flex;
      margin: 20px auto;
    }
  }
}
