.game-grid {
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  margin-top: 20px;
  width: 100%;

  .game-grid-item {
    align-content: flex-start!important;
  }

  .MuiGrid-root {
    justify-content: center;
    place-content: center;
  }

  &.has-selected {
    .game-card {
      opacity: 0.5 !important;
    }

    .selected .game-card {
      opacity: 1 !important;
    }
  }

}
