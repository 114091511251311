.tutorials-page {

  .app-page-title-bg {
    height: 100% !important;
  }

  .content {
    padding: 30px;

    .video-container {
      max-width: 100%;
      iframe {
        max-width: 100%;
      }
    }
  }

}
