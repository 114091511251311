@import "assets/core/_variables";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  .hamburger-wrapper,
  .mobile-bar {
    display: none;
  }

  .only-mobile {
    display: none !important;
  }

  @include respond($phone) {
    #root {
      //max-height: calc(100% - env(safe-area-inset-bottom) - env(safe-area-inset-top));
      overflow: hidden;
      //top: 30px;
      position: relative;
      height: 100%;
    }

    .new-gdd-modal .MuiPaper-root {
      margin: 10px;
      .new-gdd-modal-content {
        padding: 0 10px 20px 10px;
        .options {
          .MuiGrid-root .MuiGrid-root {
            width: 100%;
          }
        }
      }
    }

    .app-wrapper {
      height: unset;
      //max-height: 100%;//calc(100vh - 120px);
      overflow: auto;
      position: relative;
      flex-grow: 1;
    }

    .app-main {
      //position: absolute;
      //height: calc(100% - 50px);
      height: 100%;
    }

    .header-popup {
      display: none !important;
    }

    body {
      overflow-y: hidden !important;
    }

    .gdd-page {
      //height: calc(100vh - 50px);
      .toc-indicator {
        display: none !important;
      }
    }

    .universal-search {
      .upload-media-wrapper .upload-media {
        right: -20px;
      }
    }

    .gallery-wrapper {
      max-width: 100vw;
      min-width: 100vw;
      min-height: 300px;
      padding: 0 10px;
    }

    .full-screen-modal {
      .top-bg-content {
        text-align: center;

        .app-page-title--first {
          place-content: center;
          position: relative;
          top: 10px;
        }
      }
    }

    .app-page-title {
      min-height: unset;

      .top-bg-content {
        padding: 0 5px !important;
      }

      .title-first-line {
        max-width: 100%;
        place-content: center;
        margin: 5px 0;

        h1 {
          border-right: unset;
          padding-right: unset;
        }
      }

      .title-wrapper {
        flex-direction: column;

        h1 {
          font-size: 30px;
        }

        h1,
        span {
          align-self: center;
        }

        span {
          font-size: 17px;
        }
      }

      .app-page-title--first,
      .app-page-title--description {
        padding: 0;
      }
    }

    .mobile-warning {
      background: $secondary;
      color: white;
      padding: 35px 15px;
      text-align: center;
      font-weight: bold;
      z-index: 99999999999999;
      position: absolute;
      bottom: calc(0px + env(safe-area-inset-bottom));
      width: 100%;

      .message {
        text-align: center;
        width: 100%;
      }

      .top-right {
        top: 0;
        right: 0;
      }
    }

    .auth-form-wrapper {
      margin-bottom: 30px !important;

      .auth-wrapper {
        min-height: 170px !important;
        max-height: 170px !important;
      }
    }

    .only-mobile {
      display: flex !important;
    }

    .only-desktop {
      display: none !important;
    }

    .ludo-error-page {
      .app-content {
        .content-wrapper {
          padding: 15px !important;

          img {
            max-width: 70vw;
            height: auto;
          }

          .large-text {
            font-size: 30px;
            line-height: 30px;
            margin: 25px 0;
          }

          span {
            text-align: center;
          }
        }
      }
    }

    .confirm-dialog {
      .MuiDialogActions-root {
        max-width: 100%;
        .d-flex {
          flex-direction: column;
        }
      }
    }

    .subscription-modal {
      .subscriptions-main-content {
        .top-bar {
          .back {
            min-width: 80px;
          }
          .options {
            flex-wrap: wrap;
          }
        }
      }

      .subscriptions {
        padding: 0 10px;

        .all-plans {
          .plans-wrapper {
            margin: unset;
            .just-cards {
              flex-direction: column;
              flex-wrap: nowrap;
              gap: 70px;
            }
          }
        }

        .subscription-status {
          padding-bottom: 15px;
          display: block;
        }

        .promo-code {
          button {
            margin-top: 0 !important;
            max-width: 100%;
            margin-left: 5px;
          }
        }

        .MuiTypography-h4 {
          padding: 20px 0;
        }

        .MuiStepper-root {
          align-items: flex-start;
          max-height: 120px;

          .MuiStep-horizontal {
            padding: 0;
          }

          .MuiStepLabel-labelContainer {
            max-width: 100%;

            span {
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: break-spaces;
            }
          }

          .MuiStepLabel-iconContainer {
            & > div {
              max-width: 50px;
              max-height: 50px;
            }
          }
        }
      }
    }

    .projects-modal {
      .MuiDialog-paper {
        max-height: 100%;
      }

      .main-content {
        .project-details {
          position: relative;
          padding: 10px;

          .MuiButton-root {
            width: 100% !important;
            //max-width: 60vw;
          }
        }

        .project-list {
          width: 40%;
          min-width: 40%;

          .project-badge {
            padding: 10px;

            span,
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .delete-icon {
              display: block;
            }
          }
        }
      }
    }

    .subscription-modal {
      max-width: 100% !important;
      margin: 0;
      //max-height: 100vh;
      overflow-x: hidden;

      .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
      }

      .MuiContainer-root {
        padding: 35px 10px !important;
        max-width: 100vw;
        margin: 0;
      }

      .subscriptions {
        .MuiGrid-container {
          place-content: center;
        }
      }
    }

    .video-editor-modal,
    .image-editor-modal {
      .modal-content {
        min-width: 100vw;
      }

      .player-container {
        max-width: 90vw;

        .ReactCrop {
          display: none;
        }
      }

      video {
        max-width: 90vw;
      }
    }

    .ludo-modal {
      max-width: 100vw;
      min-width: 0;

      .content-wrapper {
        padding: 0 !important;
      }

      .MuiDialog-paperScrollPaper {
        max-height: 100%;
      }

      .MuiPaper-root {
        min-width: 0;
        padding: 20px 10px !important;
        margin: 0 !important;
        max-width: 100vw;
      }

      .team {
        margin: 0 !important;

        .add-team-member {
          width: fit-content !important;
        }
      }
    }

    .image-search {
      .results-wrapper {
        padding: 0;
      }
    }

    .competitive-analysis {
      .source-country-form-wrapper {
        padding: 0 10px !important;
        .MuiGrid-root {
          margin: 0 !important;
        }
      }

      .metrics {
        padding: 0 15px !important;
        .metric {
          .content {
            min-width: unset;
            .modebar-container {
              display: none;
            }

            .content-left {
              min-width: unset;
              padding: 20px 15px;
              width: 100%;

              .data-wrapper {
                flex-direction: column;
                gap: 10px;
                padding: 15px !important;

                .filler {
                  display: none;
                }

                .description-box {
                  width: 100%;
                }
              }
            }

            .plot {
              max-width: 100%;
              min-width: unset;
              width: 100%;
            }
            .js-plotly-plot {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .video-generator {
      .input-fields-wrapper {
        width: 100%;
      }

      .file-wrapper {
        .row {
          flex-direction: column;
        }

        .reference-video,
        .reference-image {
          flex-direction: column;
          min-width: 100%;
          max-width: 100%;

          .slider {
            max-width: unset;
          }
        }
      }

      .main-generator-content {
        padding: 20px;
        gap: 50px;

        .page-divider {
          span {
            background: #f4f5fd;
          }
        }

        .generated-video {
          width: 100%;

          .date {
            margin-left: 0;
          }

          .content {
            flex-direction: column-reverse;
          }

          .meta {
            width: 100% !important;
          }

          .media {
            width: 90vw !important;
            height: 90vw !important;

            .square-thumbnail {
              width: 90vw !important;
              height: 90vw !important;
            }
          }
        }
      }
    }

    .assistant-chat {
      .content-wrapper {
        max-width: 100vw;
        padding-bottom: 5px;
      }

      .archives {
        position: absolute;
        width: 100vw;
        height: calc(100vh - 100px);
        max-width: unset;
      }

      .chat-content {
        padding: 5px;
      }
      .messages {
        min-height: unset;
      }
      .follow-ups {
        flex-direction: column;
        .follow-up {
          max-width: 100%;
        }
      }
      .input-container {
        max-width: 100%;
        flex-direction: column-reverse;
        .input-box {
          &::before {
            font-size: 15px;
          }
        }
      }
    }

    .image-generator {
      .slider {
        width: unset !important;
        padding-right: 20px;
      }

      .main-generator-content {
        padding-bottom: 50px;
      }
    }

    .image-generator-modal {
      min-width: unset !important;

      .MuiPaper-root {
        padding: 0 !important;
        overflow-x: hidden;
      }

      .modal-content {
        padding-top: 20px;
        max-width: 100vw;

        .gallery-wrapper {
          min-width: unset !important;
        }
      }
    }

    .achievement-toasts {
      top: 10px;
      right: 5px;
      max-width: calc(100% - 10px);

      .achievement-toast {
        max-width: 100%;
        padding: 10px;
        margin-bottom: 10px;

        .top-line {
          img {
            height: 35px;
          }

          button {
            display: none;
          }
        }
      }
    }

    .achievement-modal-content {
      .achievements {
        padding: 10px;

        .bar-info {
          flex-direction: column;

          .description {
            width: 100%;
          }
        }
      }
    }

    .dismissable-message {
      display: none;
    }

    #menu-mixing_mode {
      .description {
        font-size: 13px;
      }
    }

    .hint-wrapper {
      display: none;
    }

    .MuiTooltip-tooltip {
      max-width: 60vw !important;
    }

    .full-wrapper {
      max-height: calc(
        100% - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );
      height: calc(
        100% - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );
      position: relative;

      .upgrade-bar {
        display: none;
      }

      .mobile-bar {
        display: flex;
        height: 50px;
        position: relative;
        top: 0;

        .counters-bar {
          top: unset;
          right: unset;
          flex-grow: 1;
          position: relative;
          display: flex;
          place-content: center;

          .counter {
            top: 3px;

            .bar {
              border: 2px solid $primary;

              span {
                top: 0;
              }
            }
          }

          .achievements-counter {
            img {
              width: 50px;
            }
          }
        }

        .mini-credits-counter {
          display: inline-flex;
          align-self: center;
          margin-top: 2px;

          span {
            cursor: pointer;
          }
        }

        .profile-button {
          position: relative;
          top: 13px;
          left: 0;

          button {
            color: $primary !important;
          }
        }

        .logo-wrapper {
          display: block;
          position: relative;

          .app-sidebar-logo {
            place-content: center;

            img {
              padding: 10px;
            }
          }
        }

        .hamburger-wrapper {
          display: flex;
          position: relative;
          z-index: 1299;
          padding: 15px;
          width: fit-content;
          cursor: pointer;
        }
      }

      .app-content--inner {
        height: 100%;
      }

      /*
        .app-content--inner {
          max-height: calc(100vh - 50px - env(safe-area-inset-bottom)) !important;
          max-height: -webkit-fill-available !important;
          padding-bottom: 20px !important;

          &.gdd, &.game-concept {
            max-height: calc(100vh - 50px - env(safe-area-inset-bottom)) !important;
            max-height: -webkit-fill-available !important;
            padding-bottom: 20px !important;
          }
        }

        .app-sidebar--content {
          max-height: calc(100vh - 50px - env(safe-area-inset-bottom)) !important;
          max-height: -webkit-fill-available !important;
          padding-bottom: 20px !important;
        }

        .page-content {
          max-height: calc(100vh - 50px - env(safe-area-inset-bottom)) !important;
          max-height: -webkit-fill-available !important;
          padding-bottom: 20px !important;
        }*/

      .app-wrapper {
        .app-sidebar {
          display: none;
        }

        &.open-mobile-menu {
          .app-sidebar {
            display: block;
            width: 100% !important;
            max-height: 100%;
            position: absolute;

            .logo-wrapper {
              display: none;
            }
          }
        }
      }

      .app-content {
        padding-left: 0 !important;
        height: 100%;

        .credits-counter {
          display: none !important;
        }

        .achievements-counter {
          display: none;
        }

        .MuiTabs-flexContainer {
          overflow-x: auto;
        }

        .form-wrapper {
          padding: 15px 10px;

          .examples {
            max-width: 100%;
            .description {
              display: none;
            }
          }

          .MuiGrid-root {
            max-width: 100%;
          }

          .MuiTabs-indicator {
            //display: none;
          }

          &.sticky-top {
            position: relative !important;
          }

          .universal-search {
            .MuiAutocomplete-inputRoot {
              padding-right: 5px !important;
            }
          }

          .horizontal-spacing .MuiGrid-root {
            padding: 0;
          }

          .input-fields {
            flex-direction: column;

            .universal-search,
            .mode-field,
            .text-field {
              border-radius: 30px;
              width: 100%;
            }

            .mode-field {
              height: 50px;
              padding-right: 10px;
            }
          }
        }

        .games-wrapper {
          max-width: 100% !important;
        }

        .topic-list-expanded {
          margin: 0 !important;
        }

        .topics-wrapper {
          padding-left: 0;

          .top-row {
            left: 0;
            width: 100%;

            .more-options {
              display: none;
            }
          }
        }

        .topic-expanded {
          .position {
            line-height: 18px !important;
            margin-top: 4px !important;
          }

          .keywords {
            //padding-left: 5px !important;
            max-width: 100%;
            flex-flow: wrap;
            gap: 3px;

            .MuiChip-label {
              padding: 0;
            }
          }

          .position,
          .keywords b {
            font-size: 18px !important;
          }
        }

        .trending-content {
          padding: 10px !important;

          .MuiTabs-flexContainer {
            place-content: center;
          }
        }

        .topics-wrapper {
          .mode-icon {
            display: none;
          }
        }

        .profile-button {
          display: none;
        }

        .top-hr {
          display: none;
        }

        .ludo-carousel {
          ul li {
            padding: 0 7px;

            &:first-of-type {
              padding-left: 0;
            }
          }
        }

        .my-image {
          .image-actions,
          .gradient-top {
            display: flex;
          }
        }

        .mechanic {
          .favorite-button-wrapper {
            display: block;
            opacity: 1;
          }
        }

        .game-grid {
          .game-card {
            width: 140px;
          }
        }

        .game-card {
          max-width: 100%;

          .game-actions {
            visibility: visible;
          }

          .gradient-top {
            opacity: 1;
          }
        }

        .generated-game-card {
          .game-actions {
            display: flex;
          }

          .generated-image {
            height: unset;
          }

          .genres {
            .MuiChip-root {
              margin-top: 5px;
            }
          }
        }

        .game-elements {
          .generated-game-list {
            padding: 0 20px 20px 20px;
          }

          .mechanic {
            width: 100%;
          }
        }

        .trending {
          .upload-media-wrapper .upload-media {
            right: 0;
          }
          .search-row {
            flex-direction: column;
          }
          .trending-content {
            overflow-x: hidden;
            .topic-expanded {
              .top-row {
                align-items: center;
              }
              .keywords .MuiChip-label {
                font-size: 15px;
              }
            }
          }
        }

        .game-ideator {
          .generator-wrapper {
            .form-wrapper {
              overflow-x: hidden;
            }
          }

          .main-generator-content {
            .section-title {
              display: flex;
              flex-direction: column;

              .title-text {
                padding-bottom: 5px;
              }
            }

            .generation {
              max-width: 100%;
              min-width: 100px;
              padding: 20px 15px;

              .game-actions {
                margin-right: 5px !important;
              }
            }
          }

          .progress-wrapper {
            place-self: center;
          }

          .input-fields {
            flex-direction: column;

            .universal-search {
              border-radius: 30px;
            }

            .mode-field {
              border-radius: 30px;
              width: 100%;
              padding-right: 20px;
              height: 50px;
            }
          }
        }

        .details-panel {
          max-width: 100%;
          transition: width 0s !important;

          .game-card {
            width: unset !important;
            margin: 20px 0 !important;
          }

          .content {
            max-width: 100%;
          }

          .MuiTabs-flexContainer {
            place-content: center;
            overflow: hidden;
          }

          .tabs {
            &.one {
              width: 100%;
            }
          }

          &.image {
            .image-wrapper {
              img {
                max-height: 50vh;
              }
            }
          }

          &.game {
            .content-wrapper {
              margin: 0 5px !important;

              .trend-info {
                padding: 10px 0;

                .info {
                  .MuiGrid-container {
                    width: 100%;
                    margin: 0;

                    .svg-container {
                      max-width: 100%;
                      margin: auto;
                    }
                  }
                }
              }
            }
          }

          &.trend_topic {
            .keywords .MuiGrid-root {
              place-content: center;
            }

            .games {
              .MuiGrid-container {
                place-content: center;

                .game-card {
                  width: 190px;
                }
              }
            }
          }
        }

        .app-footer {
          display: none;
        }

        .page-content {
          .loading-tip {
            margin: 30px;
          }

          .top-charts {
            .form-wrapper {
              .MuiGrid-root {
                margin: 0 !important;
              }
            }

            .chart-game {
              .title,
              .sub-title {
                max-width: calc(100% - 75px);
              }
            }

            .title-hide {
              display: contents;
            }

            .game .actions {
              visibility: visible !important;

              svg {
                color: $secondary !important;
              }
            }
          }

          .tutorial {
            display: none;
          }

          .gdd,
          .game-concept,
          .game-concept-old {
            width: 100% !important;
            padding-bottom: 50px;
          }

          .gdd-page-wrapper {
            .gdd-preview {
              .section-content-wrapper {
                &.active,
                &.comments-active {
                  padding-top: 0;
                  background: none;
                }
              }
              .gdd-top-bar-wrapper
                .main-description
                .header-icon-wrapper.selected
                .add-wrapper {
                display: flex !important;
              }
              .gdd-top-bar-wrapper .main-description .header-icon-wrapper {
                border: none;
                .header-icon {
                  display: none !important;
                }
              }
              .section .game-element.large .image-menu-wrapper {
                border: none !important;
              }
            }

            .export-project-header {
              padding: 10px;
            }

            .export-project {
              flex-direction: column;
              .action-bar {
                flex-wrap: wrap;
                font-size: 16px;
                justify-content: space-around;
              }
            }

            .top-bar {
              //display: none;
              z-index: 100000;

              .right-section {
                display: none;
              }
            }

            .section-content-wrapper {
              &.active {
                padding-top: 40px;
              }
            }

            .hide-preview {
              //display: none;
            }

            .show-preview {
              //display: block;
            }

            [contenteditable="true"]:empty:before {
              //color: transparent !important;
            }

            .comments,
            .comment-button {
              display: none;
            }

            .game-element {
              width: 100%;
              &.new {
                //display: none !important;
              }
            }

            .icons-wrapper {
              overflow: auto;
              height: 70px;
              padding-right: 10px;
              align-items: center;
              hr {
                top: 23px;
              }
            }

            .gdd-side-menu-3 {
              max-height: calc(100% - env(safe-area-inset-bottom) - 50px);
              z-index: 10000000;
            }

            .gdd-side-menu,
            .icons-wrapper,
            .add-icon-wrapper,
            .gdd-section-actions {
              .fa-icon {
                left: 0;
              }
              .suggestions-wrapper {
                margin-left: 0;
              }
            }

            .platform-selection .add-icon-wrapper {
              display: block;
            }

            .genres-wrapper {
              padding: 15px;
            }

            .hover-options {
              //display: none !important;
            }

            .main-description {
              padding: 0;
              min-width: 100vw;
              .gdd-section-actions {
                display: none;
              }
              .section-content-wrapper {
                padding-top: 0;
              }
            }

            .section {
              padding: 15px !important;
              min-width: 100vw;

              &:hover {
                .hover-options,
                .hover-options .action {
                  //display: none !important;
                }
              }

              &.game-inspiration {
                .game-card {
                  width: 40vw;
                }
              }

              &.game-summary {
                padding-top: 25px !important;
              }

              &.header {
                padding: 0 !important;
                height: 270px !important;
                display: flex !important;
                flex-direction: column !important;
                align-items: center;
                margin-top: 0 !important;

                .empty-header {
                  background-position-y: 0;
                }

                .header-add-image {
                  display: none !important;
                }

                .background-overlay {
                  display: none;
                }

                .header-banner-add {
                  position: absolute;
                }

                .header-icon-wrapper {
                  position: relative !important;
                  top: initial !important;
                  left: initial !important;
                  bottom: initial !important;
                  right: initial !important;
                  margin-top: 20px;
                }

                .review-bar {
                  padding: 0;
                  bottom: -10px;
                  position: relative;
                  width: 100%;
                  display: flex;
                  place-content: center;

                  .genres-wrapper {
                    margin: 0;
                    justify-content: center;
                    .genre {
                      flex-flow: wrap;
                    }
                  }

                  .platform-selection svg {
                    display: none;
                  }

                  .right-section {
                    display: none;
                  }
                }
              }

              .my-image {
                &.fake {
                  display: none;
                }
              }

              .hide-preview {
                //display: none !important;
              }

              /*.editable.menu-wrapper,
              .game-element,
              .menu-wrapper,
              .editable {
                &:hover {
                  border: unset !important;
                  background: unset !important;
                }

                border: unset !important;
                background: unset !important;

                .hover-options,
                .hover-options .action {
                  display: none !important;
                }
              }*/

              .content-editable-wrapper {
                min-width: unset;
              }

              .gallery-wrapper {
                padding: 0;
                display: contents;
              }
            }

            .MuiChip-deletable {
              .MuiChip-label {
                padding-right: 25px;
              }

              .MuiChip-deleteIcon {
                display: none;
              }
            }
          }

          .step-generator {
            text-align: center;
            .content-wrapper {
              padding: 30px 10px;
              min-height: unset;
              gap: 10px !important;
              .main-title {
                text-align: center;
                font-size: 20px;
                .section-name {
                  margin: 0 2px;
                }
              }
              .step-content {
                min-width: unset;
                .steps {
                  flex-direction: column;
                  .step {
                    width: 100%;
                  }
                }
                .choices {
                  gap: 10px 0;
                  .choice {
                    min-width: 50%;
                    .choice-text {
                      width: 90%;
                      justify-content: center;
                    }
                    .input-wrapper {
                      min-width: 80vw;
                    }

                    &.single {
                      min-width: 100%;
                      .choice-text {
                        width: 100%;
                      }
                    }
                  }
                }
              }
              .actions {
                flex-flow: wrap;
                gap: 0;
                justify-content: space-between;
                hr {
                  display: none;
                }
                .step-number {
                  white-space: nowrap;
                }
                .MuiIconButton-label {
                  span {
                    display: none;
                  }
                }
                .buttons {
                  display: contents;
                  .my-button {
                    width: 100% !important;
                    .MuiButton-label {
                      font-size: 18px !important;
                    }
                  }
                }
              }
            }
          }

          .headquarters2 {
            .smaller-title,
            .welcome {
              text-align: center;
            }

            .continue-project,
            .steps-wrapper {
              .content-wrapper,
              .content-wrapper-intro {
                padding: 40px 10px;
                flex-direction: column;
                gap: 30px;
              }
              .line1,
              .line2,
              .line3 {
                text-align: center;
                font-size: 25px;
              }

              .actions,
              .action-buttons {
                width: 100%;
                .my-button {
                  width: 100% !important;
                  .MuiAvatar-root,
                  .MuiButton-label {
                    font-size: 25px !important;
                  }
                }
              }
            }

            .padded-content {
              max-width: 100%;
            }

            .features {
              display: flex;
              flex-direction: column;
              width: 100%;
            }

            .header {
              .logo {
                display: none !important;
              }

              .form {
                padding: 0;
              }

              .input-fields {
                max-width: 100%;
                place-content: center;
                flex-direction: column !important;
                align-items: center;
                gap: 10px;

                .info-text {
                  top: -30px;
                }

                .genre-field,
                .platform-field {
                  border-radius: 30px;
                  height: 46px;
                  width: 100%;

                  .MuiChip-root {
                    flex-grow: 1;
                    padding: 20px 5px;
                  }

                  .MuiSelect-root span {
                    place-content: center;
                    margin: auto;
                  }
                }
              }
            }

            .menu {
              display: none !important;
            }
          }

          .favorites {
            .main-content {
              padding: 5px;
            }

            .suggestions {
              padding: 20px 15px;
            }

            .favorites-mobile-dropdown-wrapper {
              display: flex;
              padding: 10px;
              .favorites-mobile-dropdown {
                width: 100%;
                .MuiSelect-root {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .MuiTabs-root {
              display: none;
              margin: 20px 5px !important;

              .MuiButtonBase-root {
                padding-top: 10px;
              }

              .MuiTabs-indicator {
                display: none;
              }
            }
          }
        }

        .app-page-title {
          margin: 0;

          .top-bg-content {
            padding: 0 10px 20px 10px;
          }

          h1 {
            font-size: 30px;
            text-align: center;
            margin: auto;
          }

          hr {
            display: none;
          }

          .app-page-title--second {
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }

    .help-icon {
      right: 5px;
      bottom: 5px;
      svg {
        height: 10px;
        width: 10px;
      }
    }

    .faq-modal {
      max-width: 100vw;

      .MuiPaper-root {
        padding-top: 0 !important;

        .top-right {
          right: 4px;
          top: 8px;
        }

        .MuiDialogTitle-root {
          h2 {
            span {
              font-size: 18px;
            }
          }
        }

        .faq-items {
          padding: 5px;
        }

        .bottom {
          flex-direction: column;
          margin: 30px 0;
        }

        .discord {
          display: none;
        }
      }
    }

    .import-concept-modal {
      .import-concept-form.text {
        .forms-wrapper {
          flex-direction: column-reverse;
        }
      }
    }

    .add-to-gdd-modal {
      ul {
        padding-left: 0;
      }

      &.project {
        height: 100%;

        .MuiPaper-root {
          height: 100%;
        }

        .project-content {
          height: 100%;
          .header {
            place-content: center;
            min-height: 70px;
            height: 70px;
            .background-gradient {
              height: 100%;
            }
            .icon-wrapper {
              display: none;
            }
          }

          .sections {
            margin-top: unset;
            padding: 20px 20px 40px 20px;
          }

          .footer {
            height: 20px;
          }
        }
      }
    }
  }

  @include respond($tablet-landscape) {
    .headquarters {
      .header {
        .header-form-wrapper {
          .form {
            padding-right: 200px;

            .input-fields {
              max-width: calc(100% - 200px);
              margin: 0 40px;
            }

            .genre-field {
              min-width: 140px;
              span {
                font-size: 15px;
              }
            }

            .platform-field {
              padding: 5px;
              .MuiChip-root {
                padding: 20px 5px;
                span {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
