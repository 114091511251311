@import '_mixins';

$bison: Bison;
$bisonBold: "./fonts/Bison/Bison-Bold";

@include font-face($bison, $bisonBold, bold, normal, woff);

$lato: Lato;
$latoRegular: "./fonts/Lato/Lato-Regular";
$latoBold: "./fonts/Lato/Lato-Bold";
$latoLight: "./fonts/Lato/Lato-Light";

@include font-face($lato, $latoBold, bold, normal, woff);
@include font-face($lato, $latoRegular, normal, normal, woff);
@include font-face($lato, $latoLight, 100, normal, woff);
