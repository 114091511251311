@import "assets/core/_variables";

.ludo-error-page {

  color: white;
  position: absolute;
  z-index:99999999999999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  span {

    font-size: 20px;
    opacity: 0.65;
    font-weight: bold;

    &.large-text {
      font-size: 50px;
      opacity: 1;
      margin: 20px;
    }

  }

}
