@import "assets/core/_variables";

.game-guide-header {
  padding-left: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1;

  .icon {
    min-width: 150px;
    width: 150px;
    height: 150px;
    background: #f0eff3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;

    .icon-image {
      width: 150px;
      height: 150px;
      background-size: cover;
    }

    img {
      height: 100%;
    }

    svg {
      font-size: 40px;
      color: #cdcdd9;
    }
  }

  .description {
    font-size: 28px;
    line-height: 30px;
    flex-grow: 1;
  }

  .subtitle {
    font-size: 20px;
    margin-top: 4px;
  }
}

.game-guide {
  display: flex;
  container-type: inline-size;
  container-name: game-guide;
  background: #f8f7fb;

  .top-bar {
    display: flex;
    margin: 0 auto;
    padding: 50px 30px;
    width: 100%;

    &.align-right {
      justify-content: flex-end;
    }

    .reset-level {
      padding: 8px 16px;
      border: none;
      border-radius: 35px;
      background: none;
      height: 40px;
      color: white;
      background: $purpleGradient;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;

      &.grey {
        background: white;
        color: $secondary;
        border: 3px solid $secondary;
        font-weight: bold;
      }
    }

    .skill-level-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex-grow: 1;
      color: $lightBlue;

      .prompt-indicator {
        position: relative;

        .info-icon {
          font-size: 18px;
          color: $lightBlue;
          cursor: help;
          transition: color 0.2s ease;
        }

        .prompt-tooltip {
          position: absolute;
          top: calc(100% + 8px);
          left: 50%;
          transform: translateX(-50%);
          width: 300px;
          background: white;
          border-radius: 8px;
          padding: 12px;
          box-shadow: 0 4px 12px $lightBlue;
          border: 1px solid $lightBlue;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s ease;
          z-index: 100;

          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 10px;
            height: 10px;
            background: white;
            border-left: 1px solid $lightBlue;
            border-top: 1px solid $lightBlue;
          }

          .tooltip-title {
            font-size: 12px;
            color: $lightBlue;
            margin-bottom: 4px;
            font-weight: bold;
          }

          .tooltip-content {
            font-size: 14px;
            color: $lightBlue;
            line-height: 1.4;
            max-height: 200px;
            overflow-y: auto;
          }
        }

        &:hover .prompt-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }

      .current-level {
        display: flex;
        align-items: center;
        gap: 8px;

        .label {
          color: $lightBlue;
          font-size: 16px;
        }

        .text-bold {
          font-weight: bold;
          font-size: 15px;
        }

        select {
          padding: 12px 16px;
          font-size: 15px;
          border: 1px solid $light;
          border-radius: 8px;
          background: mix($light, #fff, 50%);
          color: $darkBlue;
          cursor: pointer;
          min-width: 200px;
          transition: all 0.2s ease;

          &:hover {
            background: $light;
            border-color: $lightPurple;
          }

          &:focus {
            outline: none;
            border-color: $lightBlue;
            box-shadow: 0 0 0 3px rgba($lightBlue, 0.1);
          }
        }
      }
    }
  }

  .skill-level-selector {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;

    .setup-section {
      max-width: 1200px;
      margin: 0 auto 40px auto;

      h3 {
        font-size: 20px;
        color: $indigo;
        margin-bottom: 16px;
      }
    }

    .engine-selector {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 40px 0 24px 0;
      justify-content: center;

      .engine-button {
        display: flex;
        place-content: center;
        position: relative;
        width: 160px;
        padding: 12px 30px;
        border-radius: 35px;
        background: #fff;
        color: $darkBlue;
        cursor: pointer;
        transition: all 0.2s ease;
        overflow: visible;
        border: 3px solid $secondary;

        &:hover,
        &.selected {
          background: $secondary;
          span {
            color: #fff;
          }
        }

        span {
          display: flex;
          position: relative;
          color: $secondary;
          font-weight: bold;
          font-size: 16px;
          z-index: 2;
        }
      }
    }

    .prompt-input {
      textarea {
        width: 100%;
        padding: 16px;
        border: 5px solid $light;
        border-radius: 35px;
        font-size: 16px;
        resize: vertical;
        transition: border-color 0.2s ease;

        &:focus {
          border-color: $lightPurple;
          outline: none;
        }
      }

      .hint {
        margin-top: 8px;
        font-size: 14px;
        color: mix($darkBlue, #fff, 60%);
      }
    }

    .examples {
      text-align: left;
      padding: 10px 30px 0 30px;
      .subtitle {
        font-size: 16px;
        color: transparentize($darkBlue, 0.6);
        margin: 0 !important;
      }

      ul {
        margin-top: 10px;
        padding-inline-start: 0;
        li {
          color: $secondary;
          list-style: none;
          font-size: 16px;
          margin-bottom: 5px;
          cursor: pointer;
        }
      }
    }

    .button-group {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      hr {
        flex-grow: 1;
        width: 100%;
        border-top: 2px solid rgba($primary, 0.1);
      }

      .cancel-button {
        padding: 12px 24px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid #ccc;
        color: #666;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease;
        width: 200px;

        &:hover {
          background-color: #f5f5f5;
          border-color: #999;
          color: #333;
        }
      }

      .submit-button {
        padding: 16px;
        background: $purpleGradient;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.2s ease;
        width: 350px;

        &:disabled {
          background: $light;
          cursor: not-allowed;
        }
      }
    }

    h2 {
      font-size: 32px;
      color: $darkBlue;
      margin-bottom: 16px;
      background: linear-gradient(45deg, $darkBlue, $lightPurple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .subtitle {
      color: mix($darkBlue, #fff, 60%);
      font-size: 18px;
      margin-bottom: 48px !important;
    }

    .levels {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: center;

      .level-card {
        background: #fff;
        border: 2px solid $light;
        border-radius: 35px;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;
        overflow: visible;
        display: flex;
        flex-direction: column;
        padding: 5px;
        max-width: 450px;

        &:before {
          position: absolute;
          content: "";
          background: $purpleGradient;
          opacity: 0;
          width: 100%;
          height: 100%;
          border-radius: 35px;
          left: 0;
          top: 0;
          z-index: 0;
          transition: 0.1s all linear;
        }

        &:hover,
        &.selected {
          box-shadow: 0 8px 24px rgba($darkBlue, 0.1);
          &:before {
            opacity: 1;
          }
        }

        .level-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          background: $purpleGradient;
          padding: 40px 0 20px 0;
          border-radius: 32px 32px 0 0;
          position: relative;
          z-index: 0;

          &:before {
            position: absolute;
            content: "";
            background: $purpleGradient;
            width: calc(100% + 10px);
            height: calc(100% + 5px);
            border-radius: 32px 32px 0 0;
            left: -5px;
            top: -5px;
            z-index: 0;
          }

          .icon {
            top: -28px;
            width: 57px;
            height: 57px;
            position: absolute;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 5px solid $secondary;
            z-index: 1;

            svg {
              font-size: 35px;
              color: $darkPurple;
            }
          }

          h3 {
            font-size: 20px;
            font-weight: bold;
            color: white;
            margin: 0;
            z-index: 1;
          }
        }

        .level-content {
          padding: 24px;
          z-index: 1;
          position: relative;
          background: white;
          border-radius: 0 0 32px 32px;
          flex-grow: 1;
          transition: 0.1s linear all;

          .description {
            color: mix($darkBlue, #fff, 70%);
            font-size: 16px;
            margin-bottom: 20px !important;
            line-height: 1.5;
            text-align: left;
            font-weight: bold;
          }

          .features {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              position: relative;
              padding-left: 24px;
              color: mix($darkBlue, #fff, 60%);
              font-size: 14px;
              margin-bottom: 12px;
              text-align: left;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $lightBlue;
              }
            }
          }
        }
      }
    }
  }

  .loading {
    width: 100%;
    .skeleton {
      background: linear-gradient(
        90deg,
        $light 25%,
        lighten($light, 5%) 50%,
        $light 75%
      );
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
      border-radius: 4px;

      &.title {
        height: 24px;
        width: 60%;
        margin-bottom: 16px;
      }

      &.description {
        height: 16px;
        width: 90%;
        margin-bottom: 24px;
      }

      &.text-line {
        height: 16px;
        width: 100%;
        margin-bottom: 12px;

        &:nth-child(2) {
          width: 94%;
        }

        &:nth-child(3) {
          width: 88%;
        }
      }

      &.back-button {
        height: 38px;
        width: 120px;
      }

      &.nav-button {
        height: 38px;
        width: 160px;
      }

      &.list-item {
        height: 16px;
        width: 90%;
        margin-bottom: 12px;

        &:nth-child(2) {
          width: 85%;
        }

        &:nth-child(3) {
          width: 80%;
        }
      }
    }
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
  }

  p {
    white-space: pre-wrap;
    margin: 0;
    padding: 0;
  }

  .topics-view {
    max-width: 1200px;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .category {
      background: white;
      border-radius: 35px;
      box-shadow: 0 4px 12px rgba($darkBlue, 0.05);

      &.loading {
        padding: 50px;
      }

      .top {
        padding: 30px 60px 0 60px;
        border-bottom: 5px solid transparentize(#938cb4, 0.9);
        h2 {
          font-size: 24px;
          color: $darkBlue;
          background: linear-gradient(45deg, $darkBlue, $lightPurple);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding-top: 15px;
        }

        .description {
          padding-bottom: 15px;
          color: mix($darkBlue, #fff, 60%);
          font-size: 16px;
          margin-bottom: 24px;
          line-height: 1.6;
        }
      }

      .topics {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px 60px 30px 60px;

        .topic-container {
          background: #ffffff;
          border-radius: 12px;
          overflow: hidden;
          transition: all 0.2s ease;

          .topic-header {
            padding: 24px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 0.2s ease;

            .topic-meta h3 {
              font-weight: bold;
            }

            .topic-info {
              flex: 1;
              min-width: 0; // Helps with text truncation

              h3 {
                font-size: 18px;
                color: $darkBlue;
                margin: 0 0 8px 0;
                font-weight: 500;
              }

              p {
                font-size: 14px;
                line-height: 1.5;
                color: mix($darkBlue, #fff, 60%);
                margin: 0;
                // Optional: add truncation for very long descriptions
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
              }
            }

            .meta {
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 12px;

              span {
                color: transparentize($indigo, 0.5);
                padding: 4px 12px;
                border-radius: 16px;
              }
            }
          }

          // Checkbox to handle expand/collapse
          input[type="checkbox"] {
            //display: none;

            &:checked {
              & ~ .topic-header {
                .expand-icon {
                  transform: rotate(180deg);
                }
              }

              & ~ .topic-steps {
                visibility: visible;
                opacity: 1;
                height: auto;
                border-top-width: 1px;

                .overview,
                .steps {
                  transform: translateY(0);
                }
              }
            }
          }

          .topic-steps {
            //visibility: hidden;
            visibility: visible;
            //opacity: 0;
            //height: 0;
            transition: all 0.3s ease;
            overflow: hidden;

            .steps {
              padding: 24px 0;
              display: flex;
              flex-direction: column;
              gap: 3px;
              transform: translateY(-10px);
              transition: transform 0.3s ease 0.1s;

              .step {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 30px 20px;
                border: 1px solid $light;
                background: transparentize($darkPurple, 0.96);
                border-radius: 8px;
                cursor: pointer;
                transition: all 0.2s ease;
                position: relative;
                overflow: hidden;

                &:first-child {
                  border-radius: 35px 35px 0 0;
                }

                &:last-child {
                  border-radius: 0 0 35px 35px;
                }

                &:hover {
                  //transform: translateX(4px);
                  border-color: $lightPurple;
                  box-shadow: 0 4px 12px rgba($darkBlue, 0.05);

                  .step-number {
                    background: $lightLightPurple;
                    color: $darkPurple;
                  }

                  svg {
                    transform: translateX(4px);
                    color: $lightPurple;
                  }
                }

                &:active {
                  transform: translateX(4px) scale(0.995);
                }

                .step-number {
                  width: 32px;
                  height: 32px;
                  background: $light;
                  border-radius: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-weight: 500;
                  color: $darkBlue;
                  transition: all 0.2s ease;
                  flex-shrink: 0;
                }

                .step-content {
                  flex: 1;
                  min-width: 0;

                  h4 {
                    font-size: 16px;
                    color: $indigo;
                    margin-bottom: 4px;
                    font-weight: 500;
                  }

                  p {
                    font-size: 14px;
                    color: transparentize($indigo, 0.5);
                    line-height: 1.5;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                svg {
                  color: $lightPurple;
                  font-size: 20px;
                  transition: all 0.2s ease;
                  flex-shrink: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .all-guides {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px 24px 24px;

    .guides-header {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      justify-content: center;

      h2 {
        font-size: 32px;
        color: $darkBlue;
        background: $purpleGradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        font-weight: bold;
      }
    }

    .guides-list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .guide-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        background: #ffffff;
        border-radius: 35px;
        cursor: pointer;
        transition: all 0.1s ease;
        border: 3px solid $light;

        &:hover {
          border: 3px solid $secondary;
        }

        .guide-main-info {
          display: flex;
          gap: 20px;
          align-items: flex-start;
        }

        .guide-icon {
          width: 90px;
          min-width: 90px;
          height: 90px;
          background: $darkPurple;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;

          svg {
            font-size: 55px;
            color: white;
          }
        }

        .guide-details {
          .guide-meta {
            display: flex;
            gap: 12px;
            margin-bottom: 12px;

            span {
              font-size: 13px;
              color: $darkPurple;
              padding: 4px 12px;
              border-radius: 16px;
              background: $lightLightPurple;
              font-weight: 500;
            }
          }

          .guide-prompt {
            font-size: 20px;
            color: $darkBlue;
            font-weight: bold;
            margin-bottom: 12px;
            line-height: 1.5;
          }

          .guide-dates {
            display: flex;
            gap: 16px;
            font-size: 14px;
            color: mix($darkBlue, #fff, 60%);

            span {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }

        svg.chevron-icon {
          color: $lightPurple;
          font-size: 24px;
          transition: transform 0.2s ease;
        }

        &:hover svg.chevron-icon {
          transform: translateX(4px);
        }

        &.new {
          background: $purpleGradient;
          .guide-icon {
            background: white;
            svg {
              font-size: 55px;
              color: $darkPurple;
            }
          }
          .guide-details {
            align-self: center;
          }
          .guide-prompt {
            color: white;
            margin: 0;
          }
        }
      }
    }
  }

  .subtopic-detail {
    padding: 32px 0;
    margin: 0 auto;
    background: white;

    .top-bar {
      background: #f8f7fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
      gap: 16px;
      flex-wrap: wrap;
      padding: 30px;

      .title {
        text-align: center;
        display: flex;
        flex-direction: column;
        background: $purpleGradient3;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        h1 {
          font-size: 32px;
          font-weight: bold;
        }
        .subtitle {
          font-size: 16px;
          opacity: 0.7;
        }
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      background: white;
      padding: 30px;
      max-width: 1200px;
      margin: auto;

      button {
        padding: 10px 16px;
        border: 1px solid $light;
        border-radius: 8px;
        background: mix($light, #fff, 30%);
        color: $darkBlue;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;

        &:hover {
          background: $light;
          border-color: $lightPurple;
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }

      .nav-button {
        display: flex;
        gap: 12px;
        cursor: pointer;
        text-align: left;
        background: $purpleGradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        svg {
          font-size: 60px;
          color: $secondary;
        }

        .text {
          display: flex;
          flex-direction: column;

          .label {
            font-size: 24px;
            font-weight: bold;
          }
        }

        &.next {
          text-align: right;
          svg {
            color: $lightBlue;
          }
        }
      }
    }

    .content {
      max-width: 1200px;
      margin: auto;
      padding: 20px;

      .ask-question {
        margin-top: 48px;
        background: #faf7fe;
        border-radius: 16px;
        padding: 24px;

        .question-prompt {
          border-radius: 16px;
          transition: all 0.2s ease;
          padding: 24px 50px;
          margin: auto;

          .prompt-header {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            background: $purpleGradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            max-width: 650px;
            margin: 0 auto 24px auto;

            .text {
              flex: 1;

              h3 {
                font-size: 20px;
                color: $darkBlue;
                margin-bottom: 4px;
                font-weight: bold;
              }

              p {
                font-size: 16px;
                color: $darkBlue;
                margin: 0;
                line-height: 1.5;
              }
            }
          }

          .chat {
            max-width: 850px;
            margin: auto;
            .top-banner {
              display: none;
            }

            .messages {
              .body-wrapper .message-body {
                color: $indigo;
                background: linear-gradient(135deg, #eaf0f6 0%, #d6dbf5 100%);
              }
              .own {
                .body-wrapper .message-body {
                  background: $indigo;
                  color: white;
                }
              }
            }

            .follow-ups {
              .follow-up {
                color: transparentize($indigo, 0.5);
                border: 1px solid transparentize($indigo, 0.5);
                &:hover {
                  background: unset;
                  color: $indigo;
                  border: 1px solid $indigo;
                }
              }
            }
          }
        }
      }

      .main-content {
        color: mix($darkBlue, #fff, 80%);
        line-height: 1.7;
        font-size: 16px;
        margin-bottom: 40px;

        p {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        h2 {
          margin-top: 15px;
        }

        code {
          font-family: "Fira Code", monospace !important;
          font-size: 13px;
          background-color: $lightLightPurple;
          color: $darkPurple;
          padding: 2px 4px;
          border-radius: 4px;
          white-space: nowrap;
        }

        pre {
          code {
            position: relative;
            margin-top: 15px;
            font-family: "Fira Code", monospace !important;
            font-size: 13px;
            line-height: 1.5;
            background: $primary;
            color: $tertiaryLight;
            padding: 20px;
            overflow-x: auto;
            display: block;
            white-space: pre;
            border-radius: 8px;
          }

          code::before {
            content: attr(class);
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 8px;
            font-size: 12px;
            font-family: monospace;
            background: rgba(255, 255, 255, 0.1);
            color: #ffffff;
            border-radius: 0 6px 0 6px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .top-bar {
    container-type: inline-size;
    container-name: topics-view;
    max-width: unset;
  }

  .topics-view {
    container-type: inline-size;
    container-name: topics-view;
  }

  .subtopic-detail {
    container-type: inline-size;
    container-name: subtopic-detail;
  }

  .ask-question {
    container-type: inline-size;
    container-name: ask-question;
  }

  .main-wrapper {
    container-type: inline-size;
    container-name: main-wrapper;
  }

  .top-bar {
    container-type: inline-size;
    container-name: top-bar;
  }

  .skill-level-selector {
    container-type: inline-size;
    container-name: skill-level-selector;
  }

  .all-guides {
    container-type: inline-size;
    container-name: all-guides;
  }

  @container gdd-content (max-width: 600px) {
    .top-bar {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center!important;
      margin: 0;
    }

    .top-bar,
    .subtopic-detail {
      padding-left: 0;
      padding-right: 0;
    }
    .main-wrapper {
    }
  }
  // Top Bar
  @container top-bar (max-width: 600px) {
    .top-bar {
      .reset-level {
        max-width: 130px;
      }

      .skill-level-indicator {
        padding: 10px 0;
        gap: 0;
        flex-direction: column;

        .current-level {
          flex-wrap: wrap;
          gap: 6px;
          order: 1;

          .label {
            font-size: 13px;
          }

          span.text-capitalize {
            flex: 1;
          }
        }
      }
    }
  }

  // Topics View
  @container topics-view (max-width: 600px) {
    .topics-view {
      .category {
        h2 {
          font-size: 20px;
          padding-bottom: 12px;
        }

        > p {
          font-size: 14px;
          margin-bottom: 20px;
        }

        .top {
          padding: 15px 20px;
          .description {
            margin-bottom: 0;
            line-height: 15px;
          }
        }

        .topics {
          gap: 12px;
          padding: 0;

          .topic-container {
            .topic-header {
              position: relative;
              padding: 16px;

              .topic-info {
                padding-right: 32px; // Make space for the expand icon

                h3 {
                  font-size: 16px;
                  margin-bottom: 6px;
                  padding-right: 24px; // Prevent text from going under icon
                }

                p {
                  font-size: 13px;
                }
              }
              .meta {
                margin-top: 8px;
                flex-wrap: wrap;
                gap: 6px;

                span {
                  font-size: 12px;
                  padding: 3px 10px;
                }
              }
            }

            .topic-steps {
              .steps {
                padding: 12px !important;
                gap: 8px !important;

                .step {
                  position: relative;
                  padding: 12px;
                  padding-right: 32px; // Space for potential icon/indicator

                  .step-number {
                    width: 28px;
                    height: 28px;
                    font-size: 13px;
                  }

                  .step-content {
                    h4 {
                      font-size: 14px;
                      margin-bottom: 4px;
                    }

                    p {
                      font-size: 13px;
                      white-space: normal;
                      overflow: visible;
                    }
                  }

                  svg {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                  }

                  &:hover {
                    transform: none;
                  }
                }
              }
            }

            &:hover {
              transform: none;
              border-left-width: 1px;
            }
          }
        }
      }
    }
  }

  // Subtopic Detail
  @container subtopic-detail (max-width: 600px) {
    .subtopic-detail {
      padding: 0;

      .navigation {
        flex-direction: column;

        .nav-buttons {
          width: 100%;
          flex-direction: column;
        }
      }

      .content-header {
        h1 {
          font-size: 24px;
        }

        .topic-context {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  // Chat Component
  @container ask-question (max-width: 500px) {
    .subtopic-detail .content .ask-question .question-prompt {
      padding: 0;

      .prompt-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 12px;
        margin-bottom: 16px;

        .icon {
          width: 40px;
          height: 40px;

          svg {
            font-size: 20px;
          }
        }

        .text {
          h3 {
            font-size: 15px;
            margin-bottom: 6px;

            i {
              display: inline-block; // Prevents orphaned italic text
            }
          }

          p {
            font-size: 13px;
            line-height: 1.4;
            max-width: 280px; // Improve readability on small screens
            margin: 0 auto;
          }
        }
      }
    }
  }

  @container all-guides (max-width: 600px) {
    .guides-list {
      .guide-item {
        .guide-main-info {
          flex-direction: column;

          .guide-icon {
          }

          .guide-details {
            .guide-meta {
              justify-content: center;
            }

            .guide-prompt {
              font-size: 18px;
              line-height: 20px;
              text-align: center;
            }

            .guide-dates {
              justify-content: space-between;
            }
          }
        }
        .chevron {
          display: none;
        }
        &.new {
          justify-content: center;
        }
      }
    }
  }

  @container skill-level-selector (max-width: 600px) {
    .skill-level-selector {
      padding: 24px 16px;
      gap: 24px;

      h2 {
        font-size: 24px;
      }

      .subtitle {
        font-size: 15px;
        margin-bottom: 32px !important;
      }

      .setup-section {
        margin-bottom: 32px;

        h3 {
          font-size: 18px;
          margin-bottom: 12px;
        }

        .levels {
          grid-template-columns: 1fr;
        }

        .engine-selector {
          gap: 8px;
          flex-direction: column;

          .engine-button {
            width: 100%;
            padding: 10px 20px;
            font-size: 14px;
          }
        }

        .examples {
          padding: 20px 0 0 0;
        }

        .prompt-input {
          textarea {
            padding: 12px;
            font-size: 15px;
            min-height: 100px;
          }

          .hint {
            font-size: 13px;
            margin-top: 6px;
          }
        }
      }

      .button-group {
        hr {
          display: none;
        }
      }
    }
  }
}
