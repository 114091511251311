@import "assets/core/_variables";

.add-to-gdd-modal {

  .MuiPaper-root {
    width: 700px;
    max-width: 700px;

    .top-right, .top-left {
      z-index: 10;
    }

    .top-left {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }

  &.project {

    .MuiPaper-root {
      padding: 0 !important;
      overflow-x: hidden;
      .top-right {
        svg {
          color: white!important;
        }
      }
    }

    .project-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      z-index: 0;

      .background-gradient {
        background: $purpleGradient3;

        &.has-image {
          background: rgba($primary, 0.85);
        }
      }

      .background, .background-gradient {
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        opacity: 1;
        height: calc(100% - 90px);
        top: 0;
      }

      .background {
        &.has-image {
          opacity: 0.2;
        }
      }

      .header {
        width: 100%;
        height: 250px;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;
        padding: 10px;

        span {
          color: white;
          font-size: 30px;
          line-height: 30px;
          font-weight: bold;
          text-align: center;
          z-index: 1;
        }

        .icon-wrapper {

          padding: 3px;
          background: white;
          border-radius: 10px;
          z-index: 1;

          .icon-image {
            border-radius: 10px;
            width: 100px;
            height: 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &.empty {
              background: #EDECF1;

              svg {
                font-size: 50px;
              }
            }
          }
        }
      }

      .sections {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 0 15% 30px 15%;
        margin-top: -40px;

        .title {
          font-weight: bold;
          font-size: 20px;
        }

        ul {
          width: 100%;
          margin-top: 20px;
          padding: 0;
          gap: 10px;
          display: flex;
          flex-direction: column;

          li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .my-button {
              height: 35px;
              margin-top: 0!important;
              width: 80px!important;

              .stop, .MuiCircularProgress-root {
                position: absolute;
                top: unset;
                left: unset;
                color: $secondary;
                svg {
                  color: $secondary;
                }
              }

              span {
                font-size: 15px;
              }

              .MuiCircularProgress-root {
                margin-left: -1px;
              }
            }
          }
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 100%;
        position: relative;

        .my-button {
          z-index: 2;
          top: -30px;
          position: relative;
        }

        .background, .background-gradient {
          height: 100%;
          top: unset;
          z-index: 0;
        }

        .background {
          filter: blur(3px);
          -webkit-filter: blur(3px);
          -webkit-clip-path: inset(0);
          clip-path: inset(0);
        }
      }
    }
  }
}
