@import "assets/core/_variables";

.step-generator {

  background: $primary;
  height: 100%;

  .generator-wrapper {

    height: fit-content;
    transition: height 1s ease-in-out;

    padding-top: 50px;

    .steps-wrapper {
      margin: auto;
      max-width: 1251px;
      width: 100%;
      border-radius: 20px;
      height: fit-content;
      position: relative;
      overflow: hidden;

      .bg-image {
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .bg-overlay {
        background: $purpleGradient;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.9;
      }

      .content-wrapper {
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: white;
        padding: 30px;
        position: relative;
        min-height: 492px;
      }

      .main-title, .sub-title {
        margin: auto;
      }

      .sub-title {
        opacity: 0.75;
        font-size: 16px;
      }

      .main-title {
        font-size: 32px;
        font-weight: bold;
        .section-name {
          font-weight: bold;
          background: white;
          color: $secondary;
          border-radius: 23px;
          padding: 2px 15px;
          margin: 0 5px;
          text-wrap: nowrap;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .step-number {
          background: transparentize($indigo, 0.60);
          padding: 5px 15px;
          border-radius: 23px;
          font-weight: bold;
        }

        .navigation-icon {
          span {
            color: white;
            font-size: 14px;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          margin: auto;

          .my-button {
            margin-top: 0;
          }
        }

        hr {
          border-top: 2px solid rgba(white, 0.1);
          flex-grow: 1;

          &.short {
            flex-grow: 0;
            width: calc(50% - 320px);
          }
        }
      }

      .step-content {
        width: fit-content;
        margin: auto;
        min-width: 500px;
        max-width: 100vw;
        min-height: 210px;
        display: flex;
        flex-direction: column;
      }

      .steps {

        display: flex;
        flex-wrap: wrap;
        gap: 15px 20px;
        min-height: 100px;
        align-items: center;
        margin: auto;
        text-align-last: center;
        width: fit-content;

        &.final {
          min-height: 259px;
        }

        .step {
          cursor: pointer;
          width: min-content;
          text-align: center;
          flex: 0 0 30%;
          gap: 3px;
          display: flex;
          font-size: 16px;
          flex-wrap: wrap;
          flex-direction: column;
          .step-choice {
            text-decoration: underline;
            font-weight: bold;
          }
        }
      }

      .choices {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 20px;
        min-height: 70px;
        align-items: center;
        margin: auto;
        text-align-last: center;
        justify-content: center;
        align-content: center;

        .loading-wrapper {
          margin: auto;
          svg {
            color: white;
          }
        }

        .input-wrapper {

          position: relative;
          max-width: 250px;
          width: 100%;
          display: flex;

          .check {
            background: white;
            width: 30px;
            position: absolute;
            height: 80%;
            top: 10%;
            right: 3px;
            border-radius: 5px 20px 20px 5px;
            cursor: pointer;

            &.disabled {
              cursor: unset;
              opacity: 0.5;
            }

            svg {
              color: $indigo;
              font-size: 15px;
            }
          }

          .text-field {
            width: 100%;
            display: flex;
            margin: 0;
            flex-grow: 1;
            border-radius: 25px;
            background: transparentize($indigo, 0.5);
            font-size: 13px;
            padding: 0 45px 0 15px;

            color: white;

            span, input, .MuiInputBase-root, label {
              color: white;
            }

            .MuiInputBase-root {
              &:after, &:before {
                display: none;
              }
            }
          }
        }

        .choice {
          font-weight: bold;
          width: min-content;
          text-align: center;
          flex: 0 0 30%;
          display: flex;
          justify-content: center;

          &.rounded-border {
            .choice-text {
              border-radius: 23px;
              border: 1px solid transparentize(white, 0.5);
            }
          }

          &.single {
            flex-grow: 1;
            justify-content: center;
            margin-top: 15px;
            width: auto;
          }

          .ludo-score {
            width: 25px;
            min-width: 25px;
            height: 25px;
            position: relative;
            place-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;

            .MuiLinearProgress-root {
              width: 30px;
              height: 10px;
              background: transparentize(black, 0.9);
            }
          }

          .choice-text {
            cursor: pointer;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            gap: 7px;
            svg {
              color: white;
            }

            &:hover {
              background: transparentize(white, 0.15);
              color: $secondary;
              border-radius: 23px;
              padding: 5px 15px;
              svg {
                color: $secondary;
              }
            }
          }

          &.selected {
            .choice-text {
              background: white;
              color: $secondary;
              border-radius: 23px;
              padding: 5px 15px;
            }
          }
        }
      }

      .loading-wrapper {
        min-height: 100px;
      }

    }
  }

  .main-generator-content {
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;

    .loading-tip {
      margin-left: 60px;
    }

    .generated-game-card-grid-detail {
      border-radius: 20px;
      .ludo-score-wrapper {
        border-radius: 20px 0 0 0;
      }
    }

    .seeds {
      .more-ideas {
        display: flex;
        align-self: center;
        position: relative;
        .icon-button {
          border: 1px solid transparentize(white, 0.5);
          padding: 5px!important;
          svg {
            font-size: 35px;
            color: white;
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-self: center;
      gap: 20px;
      margin-bottom: 20px;

      .text-blue {
        color: $secondary;
      }

      .generate-more-button {
        align-self: center;
        margin-top: 0;
      }
    }
  }

}
