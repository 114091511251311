@import "assets/core/_variables";

.game-topics-details-panel {

  .loading {
    padding-top: 100px;
  }

  .white-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;
  }

  .info-content {

    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;

    .topic {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .left {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          color: $tertiary;
          font-weight: bold;
          font-size: 18px;
        }

        .vr {
          flex-grow: 1;
          border-left: 1px solid $secondary;
          border-bottom: 1px solid $secondary;
          margin-left: 5px;
          opacity: 0.8;
          margin-bottom: 10px;
        }
      }

      .right {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .topic-actions {
          display: flex;
          flex-direction: row;
          color: $tertiary;
          font-weight: bold;
          gap: 5px;

          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;
          }
        }

        .chips {

          display: flex;
          flex-direction: row;
          gap: 5px;
          flex-flow: wrap;

          .MuiChip-root {
            background: $purpleGradient;
            color: white;
            border: none;
            font-weight: bold;
            height: 20px;
            width: fit-content;

            span {
              text-transform: capitalize;
              font-size: 13px;
            }
          }
        }

        .games-wrapper {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: bold;
            color: $lightBlue;
          }
        }

        .games {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .icon {
            z-index: 1;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            overflow: hidden;
            border: 3px solid white;
            box-sizing: content-box;
            cursor: pointer;

            &.steam {
              img {
                width: auto;
                right: 50%;
                position: relative;
              }
            }

            img {
              z-index: 2;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 65px;
    padding-bottom: 10px;

    .icon {
      z-index: 1;
      width: 95px;
      height: 95px;
      border-radius: 20px;
      overflow: hidden;
      border: 3px solid white;
      box-sizing: content-box;
      cursor: pointer;
      margin: auto;

      &.steam {
        img {
          width: auto;
          right: 50%;
          position: relative;
        }
      }

      img {
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }

    &.cover-header {
      position: static;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    .game-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 15px 0;

      .game-title {
        font-weight: bold;
        font-size: 20px;
      }

      .game-developer {
        font-weight: bold;
        font-size: 20px;
        color: $gray-700;
      }

      .my-button {
        margin-top: 10px;
      }
    }

  }

}
