@import "assets/core/_variables";

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;

  .chat-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    flex-grow: 1;
    overflow-y: auto;
  }

  * {
    user-select: text !important;
  }

  .top-banner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;

    .logo-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: 90px;
      height: 90px;
      border: 3px solid $secondary;
      display: flex;
    }

    .top-text {
      display: inline-block;
      font-weight: bold;
      font-size: 22px;
      line-height: 25px;
      text-align: center;
      .blue {
        color: $lightBlue;
      }

      .purple {
        color: $tertiary;
      }
    }
  }

  .disclaimer {
    background: $indigo;
    padding: 15px;

    .disclaimer-content {
      color: rgba(255, 255, 255, 0.35);
      padding: 5px 15px 5px 15px;
      border: 1px solid rgba(255, 255, 255, 0.35);
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-radius: 5px;
      text-align: center;
      width: 100%;
      margin: auto;
      place-content: center;
    }
  }

  .follow-ups {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .follow-up {
      color: white;
      border: 1px solid white;
      padding: 5px 10px;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: $indigo;
      }
    }
  }

  .messages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    place-content: flex-end;
    width: 100%;
    color: black;
    gap: 10px;
    padding: 10px 20px;

    .message {
      color: white;
      display: flex;
      flex-direction: column;
      position: relative;

      .body-wrapper {
        display: flex;
        flex-direction: column;
      }

      .regenerate,
      .cancel {
        padding: 0;
        min-width: 35px;
        align-self: flex-start;
        position: absolute;
        right: 0;
        top: 5px;
      }

      &.waiting {
        @keyframes cursor-blink {
          0% {
            opacity: 0;
          }
        }

        .message-body::after {
          content: "";
          width: 5px;
          height: 15px;
          background: white;
          display: inline-block;
          animation: cursor-blink 1.5s steps(2) infinite;
        }
      }

      .message-author {
        display: none;
      }

      .images-wrapper {
        img {
          max-width: 100%;
        }
      }

      .message-body {
        overflow-wrap: break-word;
        white-space: pre-wrap;
        margin: 0;
        flex-grow: 1;
        font-size: 15px;
        max-width: 100%;
        color: #eaf0f6;
        background: $indigo;
        padding: 20px 25px 20px 20px;
        border-radius: 15px;
        gap: 5px;
        display: flex;
        flex-direction: column;

        .generating-image {
          position: relative;
          border-radius: 10px;
          max-width: 336px;
          background: transparentize($indigo, 0.9);

          img {
            max-width: 100%;
            height: auto;
          }

          .button-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .my-button {
              height: 50px;
              max-width: 100%;
            }
          }
        }

        .image-description {
          opacity: 0.75;
          font-style: italic;
        }

        .links {
          display: flex;
          flex-direction: row;
          gap: 0 10px;
          flex-wrap: wrap;
          padding-bottom: 15px;
        }

        .references {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-style: italic;
          gap: 5px;

          .description {
            opacity: 0.5;
          }

          .show-all {
            cursor: pointer;
            text-decoration: underline;
            opacity: 0.5;
          }

          .reference {
            background: none;
            border: 1px solid $secondary;
            .MuiChip-label {
              color: $secondary;
              padding-left: 5px;
            }
            svg,
            .MuiChip-label {
              color: #a268ee !important;
            }
            .topic-icon {
              position: relative;
              top: 0;
              border-radius: 50%;
              height: 23px;
              width: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
              margin-right: 2px;

              svg {
                height: 23px;
                width: 23px;
                border: 1px solid $secondary;
                border-radius: 50%;
              }
            }
          }
        }

        pre,
        p,
        ul,
        ol {
          padding-bottom: 0;
          margin-bottom: 0;
          white-space-collapse: collapse;
        }

        p {
          white-space: break-spaces;
        }

        ol {
          padding-inline-start: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        li {
          padding: 2px 0 !important;

          &::marker {
          }
        }

        ol li {
          padding-top: 0;
          padding-bottom: 0;
        }

        pre code {
          padding: 20px;
        }

        code {
          font-family: "Fira Code", monospace !important;
          font-size: 13px;
          background: $primary;
          color: $tertiaryLight;
          border-radius: 6px;
          overflow-x: auto;
          word-wrap: break-word;
          width: fit-content;
          white-space: pre-wrap !important;
          display: inline-flex;
          padding: 0 5px;
          &[class^="language"] {
            display: flex;
            width: 100%;
          }
        }
      }

      &.own {
        .body-wrapper {
          .message-body {
            align-self: flex-end;
            background: $formBlue;
            place-items: flex-end;
          }
        }
      }
    }
  }

  .outside-container {
    display: flex;

    .clear-history svg {
      color: $secondary !important;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: calc(100% - 20px);
      transition: border 0.1s linear;
      color: $primary;
      margin: 10px;
      place-items: center;
      gap: 15px;
      background: white;
      padding: 10px 20px;
      border-radius: 25px;

      .MuiButtonBase-root {
        height: 20px;
        width: 20px;
        margin: 0;
        padding: 0;
      }

      .horizontal {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .images {
        display: flex;
        width: 100%;
      }

      .attachment {
        margin-right: 5px;
        max-height: 50px;
        position: relative;

        .close {
          z-index: 1;
          position: absolute;
          top: -7px;
          right: -5px;
          font-size: 20px;
          cursor: pointer;
          background: white;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .input-box {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: bold;

        &::before {
          color: transparentize($primary, 0.5);
        }
      }
    }
  }
}
