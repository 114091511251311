// React Dropzone

.dropzone-upload-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none !important;

  & * {
    outline: none !important;
  }

  .dropzone-inner-wrapper {
    padding: ($spacer * 3) ($spacer);
    cursor: pointer;
    transition: $transition-base;
    text-align: center;
    color: $tertiary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed $secondary;
    @include border-radius($border-radius);
    background-color: $indigo;

    &:hover {
      border-color: $tertiary;
    }

    .icon-blob-animated {
      transition: all 0.3s ease-in-out;
      animation: float 2s ease-in-out infinite;
    }

    &.dropzone-inner-wrapper-alt {
      background: transparent;
      border-color: transparent;
      border-width: 1px;

      &:hover {
        background: $gray-100;
        border-color: $gray-700;
      }
    }

    &.dropzone-avatar {
      cursor: initial;

      .avatar-image {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: 0;
      }

      .avatar-button {
        z-index: 5;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: rotateY(0deg) rotateZ(0deg) translateZ(0px) rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-2deg) rotateZ(-2deg) translateZ(-5px) rotateX(-4deg) translateY(-15px);
  }
  75% {
    transform: rotateY(4deg) rotateZ(4deg) translateZ(8px) rotateX(8deg) translateY(8px);
  }
  100% {
    transform: rotateY(0deg) rotateZ(0deg) translateZ(0px) rotateX(0deg) translateY(0px);
  }
}
