@import "assets/core/_variables";

.profile-modal-content {

  .top-bar {
    display: flex;
    flex-direction: row;
    place-content: space-between;

    .back {
      place-self: center;
    }

    .my-button {
      margin-top: 0;
      width: 175px;
    }
  }

  .section-title {
    display: flex;
    flex-direction: column;
    span {
      font-size: 25px;
      color: $primary;
    }
    .section-icon {
      color: $primary;
      font-size: 50px;
      opacity: 0.5;
      margin: 15px 0 30px 0;
    }
  }
}
