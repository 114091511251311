@import "assets/core/_variables";

.ludo-carousel {

  min-height: 269px;

  .button-left {
    left: 0;
  }

  .button-left, .button-right {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 60px);
    position: absolute;
    cursor: pointer;
    background: $secondary;
    border-radius: 50%;
    transition: all ease-in 100ms;
    box-shadow: -1px 5px 3px 3px rgba(0, 0, 0, 0.35);

    .arrow-icon {
      position: relative;
      color: white;
    }
  }

  .react-multi-carousel-list {
    max-width: 100%;
    align-self: center;
    overflow-y: visible;

    .react-multi-carousel-item {
      min-width: fit-content;
    }

    .react-multi-carousel-dot-list {
      .react-multi-carousel-dot button {
        border-color: $primary;
      }

      .react-multi-carousel-dot--active button {
        background: $primary;
      }
    }

    .button-right {
      right: 0;

      &.MuiCircularProgress-root {
        right: 0;
      }
    }

    .progress {
      position: absolute;
      z-index: 2;
      top: 28px;
      right: 5px;
    }

    .button-right {
      right: 5px;
      .arrow-icon {
        right: -2px;
      }
    }

    .button-left {
      left: 5px;
      .arrow-icon {
        left: -2px;
      }
    }

  }

  .game-card {
    left: 10px;
    max-width: 100%;

    &.locked {
      margin-top: 0;
    }
  }

  .react-multi-carousel-track {
    ul {
      li {
        padding: 0 20px;
        align-self: flex-start;
      }
    }
  }
}
