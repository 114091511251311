@import "assets/core/_variables";

.project-shared {

  .top-menu {
    height: 44px;
    background: #131648;
    width: 100%;
    color: #4E9AF6;
    font-weight: bold;
    z-index: 1299;

    .content {
      position: relative;
      margin: auto;
      width: 100%;
      max-width: 500px;
      justify-content: space-between;
      height: 100%;
      align-items: center;
    }

    span {
      cursor: pointer;
    }

    .active {
      color: white;
    }
  }

  .shared-content {
    padding: 20px 50px;
  }

  .scrollTo {
    height: 50px;
    display: block;
  }

  .section-title {
    display: block;
    padding: 0 20px 20px 20px;
  }

}
