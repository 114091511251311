@import "assets/core/_variables";
@import "assets/_mixins";

.headquarters3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: $darkBlue;

  // Header styles
  .header {
    text-align: center;
    background: $purpleGradient2;
    color: white;
    position: relative;
    z-index: 2;

    .top-bg-content {
      .MuiFormLabel-root,
      .MuiInputBase-root {
        background: none;
      }
    }

    .header-form-wrapper {
      padding: 10px 20px;
      min-height: 80px;

      &.short {
        .input-fields {
          margin: auto;
        }
      }

      .hq-form-wrapper {
        background: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
          padding: 0 15px 0 0;
          margin: 0 !important;
          position: relative;
          height: 50px;
        }
      }

      .form {
        width: 100%;
        margin: auto;
        padding-right: 101px;

        .input-fields {
          justify-content: center;
          position: relative;
          top: 10px;
          .info-text {
            position: absolute;
            top: -25px;
            width: 100%;
            display: flex;
            place-content: center;
            font-size: 14px;
            overflow: visible;
            text-wrap: nowrap;
            .text {
              opacity: 0.6;
            }

            .help {
              opacity: 0.6;
            }
          }
        }

        .platform-field {
          display: flex;
          padding: 5px 30px;
          background: transparentize(white, 0.7);
          border-radius: 0 30px 30px 0;
          gap: 10px;
          .MuiChip-root {
            padding: 20px;
            border-radius: 25px;
            background: none;
            span {
              color: white;
              font-size: 17px;
            }
            &.active {
              background: white;
              span {
                color: $secondary;
                font-weight: bold;
              }
            }
          }
        }

        .genre-field {
          width: 200px;
          background: white;
          border-radius: 30px 0 0 30px;
          padding-right: 10px;
          padding-left: 10px;
          height: 50px;
          display: flex;
          place-content: center;
          justify-content: center;
          margin-bottom: 0;

          span {
            color: $secondary;
            font-weight: bold;
            font-size: 17px;
            text-align: center;
            width: 100%;
          }

          svg {
            color: $secondary;
          }

          .MuiInput-root {
            margin-top: 0;
          }

          .MuiSelect-icon {
            color: $primary;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            display: none;
          }

          .MuiFormControl-root > div:before {
            border-bottom-color: white !important;
          }

          .MuiFormLabel-root {
            display: none;
          }
        }
      }
    }

    @include respond($phone) {
      display: none;
    }
  }

  .back-to-top-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }

    @include respond($not-phone) {
      display: none;
    }
  }

  .showcase-gallery-container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 90px;

    .gallery-header {
      text-align: center;

      hr {
        width: 100%;
        margin: 20px auto 80px auto;
        max-width: 700px;
        height: 1px !important;
        background: $purpleGradient;
        opacity: 0.6;
      }

      .gallery-title {
        font-size: 1.8rem;
        color: #ffffff;
        margin-bottom: 8px;
        font-weight: 600;
      }

      .gallery-description {
        font-size: 1rem;
        color: #c3b9e6;
        max-width: 700px;
        margin: 0 auto;
      }
    }

    .showcase-gallery {
      width: 100%;

      .showcase-item {
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        border-radius: 15px;

        .tooltip {
          position: absolute;
          display: flex;
          flex-direction: column;
          gap: 30px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 5px solid white;
          border-radius: 15px;
          z-index: 1;
          padding: 20px;
          font-size: 15px;
          line-height: 17px;

          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            background: $purpleGradient;
            opacity: 0;
            top: 0;
            left: 0;
            border-radius: 15px;
            transition: all 0.2s linear;
          }

          .title {
            font-weight: bold;
            font-size: 16px;
            margin-top: 10px;
          }

          span,
          .button-wrapper {
            transition: all 0.2s linear;
            opacity: 0;
            z-index: 1;
          }

          .button-wrapper {
            position: absolute;
            top: 15px;
            right: 15px;
          }

          &:hover {
            .bg {
              opacity: 0.9;
            }
            span,
            .button-wrapper {
              opacity: 1;
            }
          }
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 15px 30px $tertiary;
        }

        .generated-game-card {
          border: 5px solid white;
          .generated-image {
            width: 100%;
            height: 100%;
            .ludo-score-wrapper {
              display: none;
            }
          }
        }

        img,
        .three-d-asset,
        .generated-game-card {
          width: 100%;
          max-width: unset;
          transition: transform 0.5s ease;
        }
      }
    }
  }

  // Main content area
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    color: white;
    background-color: $darkBlue;
    overflow-y: auto;
    gap: 80px;

    .welcome-message {
      text-align: center;

      h1 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: #ffffff;
      }

      h2 {
        font-size: 2.3rem;
        font-weight: bold;
      }
    }

    // Section buttons
    .section-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
      width: 100%;
      //max-width: 1000px;

      .section-button {
        flex: 1;
        min-width: 200px;
        max-width: 350px;
        min-height: 100px;
        background: white;
        padding: 0.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: transform 0.3s ease;
        overflow: hidden;
        position: relative;
        border-radius: 50px;
        text-align: center;
        border: 3px solid $secondary;

        &:hover {
          //background: darken(white, 10%);
          transform: scale(1.05);
        }

        &.active {
          background: $purpleGradient;
          border: 2px solid $secondary;
          box-shadow: 0 0 15px $secondary;
          .section-text {
            background-clip: unset;
            background: unset;
            -webkit-text-fill-color: unset;
            .title {
              color: white;
            }
          }
        }

        .section-text {
          display: flex;
          flex-direction: column;

          background: rgb(162, 104, 238);
          background: linear-gradient(
            81deg,
            $secondary 0%,
            $secondary 15%,
            $lightBlue 50%,
            $secondary 85%,
            $secondary 100%
          );
          background-clip: text;
          background-size: 300% 100%;
          animation: slide 2.5s linear infinite;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          .title {
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 0.1rem;
          }

          .subtitle {
            font-size: 1rem;
            //opacity: 0.9;
          }
        }
      }
    }

    // Options section when a category is selected
    .options-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 50px;

      .section-desc {
        text-align: center;

        span {
          font-size: 1.1rem;
          opacity: 0.7;
        }

        h3 {
          font-size: 1.4rem;
          font-weight: 500;
          margin-top: 0.5rem;
        }
      }

      .options-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;

        .option-card {
          flex: 1;
          min-width: 300px;
          max-width: 450px;
          width: 100%;
          background: $primary;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          transition: all 0.3s ease;
          cursor: pointer;
          border: 3px solid $secondary;
          overflow: hidden;
          padding: 0;

          &:hover {
            background: lighten($primary, 5%);
            transform: translateY(-5px);
          }

          .option-image {
            width: 100%;
            height: 220px;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              object-fit: cover;
              transition: transform 0.3s ease;
            }
          }

          .option-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 1.5rem 2rem 2rem;
            min-height: 220px;
            justify-content: space-between;

            h4 {
              font-size: 1.5rem;
              font-weight: 600;
              margin-bottom: 1rem;
              color: white;
            }

            p {
              font-size: 1.1rem;
              opacity: 0.8;
              margin-bottom: 1.5rem;
            }

            .option-button {
              align-self: center;
              width: 100%;
              background: $purpleGradient;
              border: none;
              border-radius: 25px;
              color: white;
              padding: 0.7rem 0;
              font-size: 1.1rem;
              font-weight: 500;
              cursor: pointer;
              transition: all 0.3s ease;

              &:hover {
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .header-form-wrapper {
      position: relative;
      top: 5px;
      padding: 35px !important;
      .hq-form-wrapper {
        img {
          display: none !important;
        }

        .form {
          padding-right: 0 !important;

          .info-text {
            top: -40px !important;
            max-width: 90vw;
            text-wrap: wrap !important;
            line-height: 16px;
          }

          .input-field {
            flex-direction: column;
          }

          .platform-field {
            padding: 5px;

            .MuiChip-root {
              padding: 20px 5px;

              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .main-content {
      padding: 2rem 1rem;
      gap: 40px;

      .welcome-message {
        h1 {
          font-size: 1.3rem;
        }

        h2 {
          font-size: 1.8rem;
        }
      }

      .section-buttons {
        .section-button {
          min-width: 100%;
          max-width: 100%;
        }
      }

      .options-container {
        .option-card {
          min-width: 100%;
        }
      }

      .showcase-gallery-container {
        gap: 40px;
      }
    }
  }
}
