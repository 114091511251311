@import "assets/core/_variables";

.gdd-content {
  .section-wrapper {

    &.no-editable {
      .genres-wrapper {
        pointer-events: none !important;
      }

      .header-wrapper, .empty-header {
        border: none !important;
        cursor: default !important;
      }
    }

    .header {
      max-width: 100%;
      padding: 0;
      position: relative;
      height: 195px;
      z-index: 10;
      margin-bottom: 50px;

      .hidden-image {
        display: none;
      }

      .add-button {
        button {
          background: $secondary;
        }
      }

      &.active, .selected {
        z-index: 1;
      }

      &.has-banner {
        height: 300px;
        margin-top: 0;
      }

      .loading-wrapper, .add-button {
        position: absolute;
        display: flex;
        width: 100%;
        top: 70px;
        align-items: center;
        justify-content: center;
      }

      .header-wrapper {
        position: absolute;
        width: 100%;
        height: 300px;
        top: 0;

        &:hover {

          .header-banner {
            cursor: pointer;
            border: 3px solid $tertiary;
            img {
              border: 3px solid $tertiary;
            }
          }
        }

        .header-banner {
          z-index: 3;
          position: absolute;
          width: 100%;
          height: 300px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          display: flex;
          place-content: center;

          img {
            position: relative;
            width: auto;
          }

          &.blurry-color {
            z-index: 1;
          }

          &.blurry {
            z-index: 2;
            background-size: cover;
            filter: blur(12px);
            -webkit-filter: blur(12px);
            -webkit-clip-path: inset(0);
            clip-path: inset(0);
            background-blend-mode: darken;
          }
        }
      }

      .empty-header {
        width: 100%;
        height: 100%;
        background-repeat: repeat no-repeat;
        opacity: 0.1;
        cursor: pointer;
      }

      .header-gradient {
        display: none;
        z-index: 4;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        background: linear-gradient(180deg, transparentize($secondary, 0.3) 0%, transparent 20%, transparent 80%, transparentize($secondary, 0.3) 100%);
      }

      &.no-header {

        .review-bar {
          .right-section .icon-buttons {
            span, svg {
              color: $secondary !important;
            }

            .MuiFormControl-root ::after, .MuiFormControl-root ::before {
              border-color: $secondary !important;
            }
          }
        }

        .header-add-image {
          padding-top: 50px;
        }

        .header-wrapper {
          height: 200px !important;
          border: 3px solid #E7E7EC;

          &:hover {
            border: 3px solid $secondary;
          }
        }

        .header-popup-wrapper {
          top: 40% !important;
        }
      }

    }

    .review-bar {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      color: white;
      font-weight: bold;
      place-content: space-between;
      padding: 5px 30px 5px 50px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      & > div {
        pointer-events: auto;
      }

      .right-section {
        display: flex;
        justify-content: flex-end;

        .ai-chat-icon {
          place-self: center;

          .icon-wrapper {
            cursor: pointer;
            background: $secondary;
            color: white;
            padding: 5px 10px;
            border-radius: 10px;
            display: flex;
            gap: 10px;
            align-items: center;

            span {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }

      }

      .edit-mode-selector {
        .description {
          display: none;
        }
      }

      .description {
        color: $secondary;
        font-style: italic;
        font-weight: normal;
      }

      .read-only {
        flex-grow: 1;
      }

      .editable {
        margin-right: 20px;
        flex-direction: row;
        display: flex;
        align-items: center;
      }

      .toggle-wrapper {
        display: flex;
        height: 30px;
        border-radius: 20px;
        overflow: clip;
        align-items: center;
        width: fit-content;
        margin-right: 20px;

        span {
          cursor: pointer;
          background: white;
          color: lightgrey;
          padding: 0 40px;
          line-height: 30px;
          transition: all linear 0.15s;
          font-size: 14px;
          font-weight: bold;

          &.active {
            cursor: default;
            color: white;
            background: $lightBlue;
          }
        }
      }

      .icon-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: white;
        }
      }

      .comments-popup {
        .MuiPaper-root {
          border-radius: 0;

          li {
            padding: 3px 30px !important;
            cursor: pointer;

            .highlight {
              display: flex;
              flex-direction: row;
              width: 100%;

              & > span {
                display: inline-flex;
                flex-grow: 1;
              }

              &:hover, &.active {
                svg, .text {
                  color: $secondary !important;
                }
              }

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }

      & > span {
        padding: 13px;
        opacity: 0.75;
        cursor: pointer;

        &.active {
          opacity: 0.9;
          color: $secondary;
        }

        &:hover {
          opacity: 1;
        }
      }

    }

    .gradient-top {
      background-image: linear-gradient(to top, rgba($primary, 0.1), transparent);
      z-index: 0;
      height: 70px;
      border-radius: 0;
      bottom: 0;
      top: unset;
    }

    .header-icon-wrapper {
      z-index: 11 !important;
      position: absolute !important;
      left: 40px;
      bottom: -50px !important;
      height: 150px !important;
      width: 150px !important;
      cursor: pointer;

      &.generating {
        .header-icon {
          display: flex !important;
          z-index: 10;

          .dropzone-wrapper {
            display: none;
          }
        }

        &.has-image {
          .header-icon {
            opacity: 0;
          }
        }
      }

      &:hover {
        border: 3px solid $secondary;
        border-radius: 10px;
      }

      .icon-image {
        position: absolute;
        background-size: contain;
        height: 100%;
        width: 100%;
        border: 3px solid white;
        border-radius: 10px;
      }

      .add-wrapper { //this is the little icon when there's no image
        position: absolute;
        background: #F0EFF3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        z-index: 1;

        svg {
          color: #CDCDD9;
          font-size: 100px !important;
        }
      }

      .header-icon { //wrapper for add element, including hover menu
        width: 100%;
        height: 100%;
        box-shadow: 0 10px 10px 5px transparentize(black, 0.85);
        border-radius: 8px;
        //background: white !important;
        position: absolute;
        opacity: 1;
        display: none;

        .add-icon {
          place-content: center;
          border: 3px solid transparent !important;

          .loading-wrapper {
            z-index: 100;
          }

          .hover-options {
            display: none !important;
          }
        }

        .dropzone-wrapper {
          opacity: 1;
          display: block;
          position: absolute;
          z-index: 20;
          left: 0;
          top: 0;

          .dropzone-inner-wrapper {
            background: transparentize($darkPurple, 0.3) !important;
            opacity: 1;
          }

          .instructions {
            gap: 0;

            span {
              opacity: 1 !important;
            }
          }
        }

        .MuiCircularProgress-root {
          position: absolute;
        }

        .icon-image {
          z-index: 19;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 8px;
        }
      }

      &.selected {
        border: 3px solid $secondary !important;
        border-radius: 10px;

        .add-wrapper {
          display: none;
        }

        .hover-options {
          display: flex !important;
        }

        .header-icon {
          display: block;
        }
      }
    }
  }
}

.header-popup {
  width: 250px;
  padding: 10px 20px;
  gap: 7px;

  .title {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 15px;
    width: fit-content;
  }

  .item {
    display: flex;
    gap: 10px;
    color: $secondary;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
  }

  .dropzone-wrapper {
    width: auto;

    .dropzone-upload-wrapper .dropzone-inner-wrapper {
      background: #F2E9FF;
      color: $secondary;
      font-weight: bold;
      border: none;
      padding: 15px 0 !important;

      .browse {
        border: 2px solid transparentize($secondary, 0.4);
      }

      svg {
        color: $secondary;
        opacity: 0.7;
      }
    }
  }
}
