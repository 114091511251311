@import "assets/core/_variables";

.achievement-toasts {
  position: fixed;
  z-index: 1300;
  right: 30px;
  top: 20px;
  display: flex;
  flex-direction: column;

  .achievement-toast {
    width: 500px;
    height: fit-content;
    background: white;
    border-radius: 15px;
    //margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px transparentize($black, 0.4);
    color: $primary;
    font-weight: bold;
    padding: 15px;
    box-sizing: border-box;
    position: relative;

    .back {
      position: absolute;
      top: 10px;
      right: 20px;
    }

    .top-line {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        padding: 5px;
        font-size: 14px!important;
        margin-right: 15px;
        margin-left: 10px;
      }

      img {
        height: 50px;
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        .title {
          font-size: 17px;
        }
        .description {
          font-size: 14px;
        }
      }
    }
  }
}

.achievements {
  padding: 30px 60px;

  .big-bar {
    width: 100%;
    padding-bottom: 20px;

    .bar-info {
      display: flex;
      flex-direction: row;
      place-items: center;
      padding: 10px;

      .title, .description {
        font-weight: bold;
        color: $primary;
      }

      .title {
        font-size: 25px;
        flex-grow: 1;
      }
    }

    .bar-wrapper {
      background: $lightBlue;
      padding: 2px;
      border-radius: 30px;
    }

    .MuiLinearProgress-root {
      border-radius: 30px;
      height: 16px;
      background: $primary;

      .MuiLinearProgress-bar {
        border-radius: 10px;
        background: $secondary;
      }
    }
  }

  .achievement {
    max-width: 400px;
    flex-direction: column;
    padding-top: 18px;
    margin: auto;

    .name {
      display: flex;
      flex-direction: row;
      background: #F1F6FC;
      color: $primary;
      font-size: 20px;
      padding: 15px;
      font-weight: bold;

      span {
        flex-grow: 1;
        place-self: center;
      }

      .emblem {
        svg {
          width: 50px;
          color: #09BC3E;
        }
      }
    }

    .steps {
      .step {

        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #F1F6FC;
        padding: 15px;
        align-items: flex-start;
        position: relative;

        &.last-completed {
          background: $primary;

          .last-completed-text {
            color: $secondary;
            font-style: italic;
            font-weight: bold;
            display: block;
            margin-top: 3px;
          }

          .collapse {
            .collapse-title {
              color: white;
              svg {
                color: white;
              }
            }
          }
          .description {
            color: white;
          }
        }

        .collapse-wrapper {
          display: flex;
          flex-direction: column;
        }

        .go-button {
          min-width: 50px;
          margin: 0 !important;
          color: $secondary!important;
          background: white;
          border: 1px solid $secondary;
          height: 30px;
          position: absolute;
          top: 12px;
          right: 20px;
        }

        .check-mark {
          min-width: 50px;
          color: $lightBlue;
          position: absolute;
          top: 18px;
          right: 15px;
        }

        .description {
          padding-right: 10px;
          color: $primary;
          padding-top: 15px;
        }

        .collapse {
          flex-grow: 1;

          .collapse-title {
            flex-grow: 1;
            font-weight: bold;
            color: $primary;
            display: flex;
            flex-direction: row-reverse;
            justify-content: left;
            padding-right: 40px;

            svg {
              color: $primary;
              min-width: 40px;
              max-width: 40px;
            }
          }
        }

      }
    }
  }
}
