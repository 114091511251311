@import "assets/core/_variables";

* {
  font-family: "Lato", Arial !important;
}

html,
body {
  overflow: hidden;
}

html {
  .app-wrapper {
    flex-grow: 1;

    &.bg-gradient {
      background: $purpleGradient3;
    }
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  .MuiRating-root {
    color: $lightPurple;
  }

  .gradient-text {
    background: rgb(162, 104, 238);
    background: linear-gradient(
      81deg,
      rgba(162, 104, 238, 1) 0%,
      rgba(162, 104, 238, 1) 44%,
      rgba(69, 177, 248, 1) 74%,
      rgba(0, 212, 255, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.soft {
      background: rgb(162, 104, 238);
      background: linear-gradient(
        81deg,
        rgba(162, 104, 238, 1) 0%,
        rgba(162, 104, 238, 1) 44%,
        rgba(69, 177, 248, 1) 74%,
        rgba(69, 177, 248, 1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .gradient-text2 {
    background: rgb(162, 104, 238);
    background: $purpleGradient3;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text3 {
    background: rgb(162, 104, 238);
    background: $purpleGradient5;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .MuiChip-root {
    background: $lightBlue;

    &.MuiChip-sizeSmall {
      line-height: 24px;
    }

    &.no-background {
      background: none;

      span {
        color: $lightBlue;
      }
    }

    &.secondary {
      background: $secondary;
    }
  }

  .full-wrapper {
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.upgrade-bar-on {
      .app-content--inner {
        max-height: calc(100vh - 30px);

        &.gdd,
        &.game-concept-old,
        &.game-concept {
          max-height: calc(100vh - 30px);
        }
      }

      .app-sidebar--content {
        height: calc(100vh - 30px) !important;
      }
    }
  }

  .upgrade-bar {
    height: 30px;
    position: relative;
    background: #f58a07;
    color: white;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    cursor: pointer;

    .upgrade-button {
      margin-left: 30px;
      background: white;
      color: $lightBlue;
      border-radius: 5px;
      padding: 3px 10px;
      font-size: 12px;
      position: relative;
      top: -1px;
    }
  }

  .place-content-center {
    place-content: center;
  }

  .game-grid,
  .gallery-wrapper {
    margin: auto;
  }

  .game-grid {
    max-width: 1800px;
  }

  .gallery-wrapper {
    max-width: 1500px;
    //min-height: 400px;
    //min-width: 300px;
    width: 100%;

    & > div {
      & > div {
        //gap: 10px;
        .gradient-top {
          width: calc(100% - 10px);
          left: 5px;
          top: 5px;
        }

        img {
          padding: 5px;
        }
      }
    }
  }

  .icon-gallery {
    padding: 0 20px;

    .mode-icon {
      color: $secondary;
      margin-bottom: 25px;
    }

    .blur-top {
      border-radius: 5px;
    }

    .icon-wrapper {
      display: flex;
      flex-direction: row;

      .details {
        padding-top: 5px;
        padding-left: 10px;
        width: 195px;
        white-space: break-spaces;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 16px;

        &.blurred {
          .title {
            width: 80%;
            background: $primary;
            opacity: 0.65;
            height: 18px;
          }

          .developer {
            width: 50%;
            background: $secondary;
            opacity: 0.65;
            height: 16px;
          }
        }

        .title,
        .developer {
          cursor: pointer;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }

        .title {
          color: $primary;
          margin-bottom: 10px;
        }

        .developer {
          color: $secondary;
        }
      }
    }
  }

  .mode-icon {
    cursor: pointer;
    color: $primary;
    align-items: center;
    display: flex;
    flex-direction: row;

    svg {
      font-size: 12px;
      position: relative;
      top: 0;
    }
  }

  .hint-wrapper {
    text-align: center;

    .hint-tooltip .MuiTooltip-tooltip {
      max-width: 300px !important;
    }
  }

  .app-sidebar--header {
    padding: 0 !important;

    &.selected {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      margin-right: 15px;
      background: $primary !important;

      span {
        color: white !important;
      }
    }

    a {
      width: 100%;
      height: 100%;
      line-height: 78px;
    }

    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    ul {
      padding-left: 1.2em;
    }
  }

  .my-chip-input-wrapper {
    width: 100%;
    max-width: 100%;

    & > div > div {
      flex-grow: 1;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    input {
      width: fit-content;
      min-width: 220px;
    }

    .MuiInputBase-root:not(.MuiInput-underline).Mui-focused {
      box-shadow: none !important;
    }

    .MuiInputBase-root &:focus {
      box-shadow: none !important;
    }
  }

  .p-05 {
    padding: 2px;
  }

  .pointer {
    cursor: pointer;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-red {
    color: red;
  }

  .text-green {
    color: green;
  }

  .rccs__card {
    cursor: pointer;
  }

  .selected {
    .rccs__card--front {
      border: 4px solid $secondary;
    }
  }

  .w-30px {
    width: 30px;
  }

  .w-fit {
    width: fit-content;
  }

  .h-fit {
    height: fit-content;
  }

  .project-selector {
    display: flex;
    width: 500px;
    max-width: calc(100% - 100px);

    .MuiFormControl-root {
      flex-direction: row;
      display: flex;
    }

    .input-wrapper {
      width: 100%;

      .MuiInputBase-root {
        width: 100%;
      }
    }

    .project-selector-edit {
      top: 25px;
      position: relative;
      left: 15px;
    }
  }

  form {
    width: 100%;
    position: relative;
  }

  .generated-game-autocomplete {
    .MuiSelect-root {
      //margin-top: 13px;
    }
  }

  .no-hover-change {
    &:hover {
      .MuiListItemIcon-root,
      a,
      .MuiListItemText-primary {
        color: inherit !important;
      }
    }
  }

  .MuiFormControl-root {
    margin-bottom: 5px;

    &.white {
      svg,
      .MuiSelect-root {
        color: white;
      }

      ::after,
      ::before {
        border-bottom: 2px solid white;
      }
    }
  }

  .chip-select {
    .extra-elements {
      position: relative;
      top: 3px;
    }

    .MuiSelect-icon {
      top: calc(50% - 5px);
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputLabel-formControl {
      transform: translate(0, 36px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.75) !important;
    }

    .MuiSelect-root {
      min-height: 40px;
    }

    .MuiInputBase-root {
      max-width: 100%;
      width: 100%;
    }

    .MuiChip-root {
      height: 40px;
      border-radius: 50px;
      font-size: 16px;

      svg {
        color: inherit;
      }
    }
  }

  .MuiFormHelperText-root {
    &.Mui-error {
      margin-left: 0;
      position: absolute;
      color: #c25317;
      font-size: 14px;
      font-weight: bold;
      bottom: -23px;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiTextField-root {
    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputBase-root {
      width: 100%;
      max-width: 100%;
    }
  }

  .game-search,
  .universal-search {
    .MuiFormControl {
      width: 500px;
    }
  }

  .universal-search {
    background: transparentize(white, 0.75); //#ACC2E1;
    border-radius: 30px;
    padding: 0 10px 0 10px;

    .upload-media-wrapper {
      width: 60px;
      height: 20px;
      position: relative;

      .upload-media {
        top: -11px;
        right: -42px;
        position: absolute;
        background: linear-gradient(135deg, #a268ee 0%, #4e9af6 100%);
        height: 40px;
        width: 70px;
        border-radius: 30px;
        display: flex;
        place-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          color: white;
          font-size: 30px;
        }
      }
    }

    input::-webkit-input-placeholder {
      opacity: 0.85;
      color: white;
      font-weight: bold;
      font-style: italic;
    }

    .MuiInputAdornment-root svg {
      color: white;
    }

    .chips {
      position: relative;
      top: 6px;
      display: inline-block;
      align-items: center;
      max-width: 100%;

      .MuiChip-root {
        height: fit-content !important;
        min-height: 36px !important;
        //max-height: 36px !important;
        max-width: 100%;
        border-radius: 20px !important;

        .MuiChip-label {
          white-space: break-spaces;
          text-wrap: pretty;
        }
      }
    }

    .input-wrapper {
      flex-grow: 1;
      margin: 0 10px;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: none !important;
    }

    .MuiInputBase-root {
      top: 7px;
    }

    .MuiAutocomplete-inputRoot {
      padding-right: 30px !important;
    }

    .MuiAutocomplete-popupIndicator {
      display: none;
    }

    .MuiAutocomplete-clearIndicator {
      display: none;
    }

    &.has-input {
      .MuiAutocomplete-clearIndicator {
        display: inline-block;
      }
    }

    .generated-game-icon {
      position: relative;
      top: -6px;

      .heart {
        color: $secondary !important;

        path {
          //stroke: white !important;
          //stroke-width: 30px !important;
        }
      }

      .lightbulb {
        position: absolute;
        left: 11px;
        top: 10px;
        color: white !important;

        path {
          //stroke: white !important;
          //stroke-width: 40px !important;
        }
      }
    }

    .topic-icon {
      position: relative;
      top: 0;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: white;
      border: 1px solid $tertiary;

      .trending {
        width: 80%;
        height: 80%;
        color: $tertiary !important;
      }
    }
  }

  .zoom {
    transition: transform 0.15s;
  }

  .zoom:hover {
    z-index: 1;
    transform: scale(1.5);
    box-shadow: -1px 5px 30px 7px rgba(0, 0, 0, 0.35);
  }

  .MuiFormControl-root > div:before {
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  .generator-wrapper {
    container-type: inline-size;
    container-name: generator-wrapper;
  }

  .form-wrapper,
  .tabbed-form-wrapper {
    width: 100%;
    position: relative;
    background: $darkBlue;
    padding: 2.4em;
    margin-bottom: 15px;
    color: $lightBlue;

    .examples {
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        gap: 15px;
        align-items: center;
        span {
          color: white;
          font-weight: bold;
          font-size: 14px;
        }
        hr {
          border-top: 1px solid $secondary;
          flex-grow: 1;
        }
      }

      .examples-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }

      .chips {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .chip-wrapper {
          .MuiChip-root {
            background: none;
            height: fit-content;
            .MuiChip-label {
              color: $secondary;
              white-space: normal;
            }
          }

          &:nth-of-type(1) {
            svg,
            .MuiChip-label {
              color: #a268ee !important;
            }
            .topic-icon {
              border: 1px solid #a268ee;
            }
          }

          &:nth-of-type(2) {
            svg,
            .MuiChip-label {
              color: #8e7bf1;
            }
            .topic-icon {
              border: 1px solid #8e7bf1;
            }
          }

          &:nth-of-type(3) {
            svg,
            .MuiChip-label {
              color: #7c8df3 !important;
            }
            .topic-icon {
              border: 1px solid #7c8df3;
            }
          }

          &:nth-of-type(4) {
            svg,
            .MuiChip-label {
              color: #6893f4 !important;
            }
            .topic-icon {
              border: 1px solid #6893f4;
            }
          }

          &:nth-of-type(5) {
            svg,
            .MuiChip-label {
              color: #4e9af6 !important;
            }
            .topic-icon {
              border: 1px solid #4e9af6;
            }
          }
        }

        .topic-icon {
          position: relative;
          top: 0;
          border-radius: 50%;
          width: 28px;
          min-width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .filters-form {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      background: $formBlue;
      border-radius: 10px;
      padding: 0 20px 20px 20px;
    }

    .slider {
      //width: 250px;
      min-width: 150px;
      max-width: 250px;

      .MuiTypography-gutterBottom {
        margin-bottom: 0;
      }

      .slider-primary {
        margin-top: 25px;
        margin-bottom: 0;
      }

      .MuiSlider-markLabel {
        top: 8px;
      }

      .MuiSlider-valueLabel {
        top: -10px;

        span {
          height: 15px;
        }
      }

      .MuiSlider-root {
        padding: 0;
      }

      .MuiSlider-track {
        background: $lightBlue !important;
      }

      .MuiSlider-markLabel {
        color: $lightBlue;
      }
    }

    .input-fields-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }

    .input-fields {
      flex-grow: 1;
      max-width: 100%;

      .universal-search {
        border-radius: 0 30px 30px 0;
        background: $formBlue;

        input::-webkit-input-placeholder {
          opacity: 0.6;
          color: white;
          font-weight: bold;
          font-style: italic;
        }
      }

      &.full-border-radius {
        .universal-search {
          border-radius: 30px;
        }
      }

      .text-field {
        background: transparentize(white, 0.75); //#ACC2E1;
        border-radius: 0 30px 30px 0;
        padding-left: 20px;
        padding-right: 20px;

        .MuiInputBase-root {
          margin-top: 10px;
          margin-bottom: 8px;

          &::after,
          &::before {
            display: none;
          }
        }

        .MuiFormLabel-root {
          color: white;
          padding-left: 20px;
        }
      }

      .mode-field {
        width: 205px;
        min-width: 205px;
        background: $lightBlue;
        border-radius: 30px 0 0 30px;
        padding-left: 30px;
        display: flex;
        place-content: center;
        justify-content: center;
        padding-bottom: 5px;

        .description {
          display: none;
        }

        span {
          color: white !important;
          font-weight: bold;
        }

        .MuiInput-root {
          margin-top: 5px;
        }

        .MuiSelect-icon {
          color: white;
          margin-right: 5px;
        }

        .MuiInput-underline:before,
        .MuiInput-underline:after {
          display: none;
        }

        .MuiFormControl-root > div:before {
          border-bottom-color: white !important;
        }

        .MuiFormLabel-root {
          display: none;
        }
      }
    }

    .MuiTabs-root {
      border-bottom: 2px solid rgba($lightBlue, 0.7) !important;
    }

    .MuiSelect-root {
      color: white !important;
      font-weight: bold;
    }

    .MuiSelect-icon {
      color: white !important;
    }

    .MuiGrid-root {
      margin-bottom: 8px;
      padding: 0 5px;
    }

    .checkbox {
      align-items: center;

      .MuiButtonBase-root {
        color: $lightBlue;
        margin-right: 5px;
      }

      span {
        font-size: 16px;
      }
    }

    .floating-buttons-right {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .filters-wrapper {
      display: flex;
      flex-direction: row;

      hr {
        flex-grow: 1;
        margin: 12px 20px;
        border-top: 2px solid rgba($primary, 0.1);
      }
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid #3f66a9;
    }

    .MuiFormControl-root > div:before {
      border-bottom-color: #3f66a9 !important;
    }

    .MuiFormLabel-root {
      color: $lightBlue;
      font-weight: bold;
    }

    .MuiInputBase-input {
      color: white;
    }

    .my-button,
    .button-linear-progress {
      background: $secondary !important;

      &.Mui-disabled {
        background: $secondary !important;
      }
    }

    .MuiChip-root {
      height: 40px;
      border-radius: 50px;
      font-size: 16px;
      //background: $secondary;

      svg {
        color: inherit;
      }
    }

    .initial-image-button {
      width: unset !important;
      background: none !important;
      border: 1px dashed $lightBlue;
      color: $lightBlue !important;

      .MuiButton-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
    }

    .file-wrapper {
      display: flex;
      flex-direction: column;

      .mask {
        position: absolute;
        opacity: 0.5;
      }

      .title {
        font-weight: bold;
      }

      .row {
        display: flex;
        flex-direction: row;
        gap: 15px;

        span {
          place-self: center;
        }
      }
    }
  }

  .tabbed-form-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;

    .tabs-container {
      background: $purpleGradient6;
      padding: 15px 0 15px 30px;
      .MuiTabs-root {
        border: none !important;
        height: 100%;
        .MuiTabs-indicator {
          display: none;
        }
        .MuiTabs-scroller {
          display: flex;
          align-items: center;
        }
        .MuiTabs-flexContainer {
          .MuiTab-root {
            padding: 0 20px;
            margin: 0;
            color: white;
            min-height: 35px;
            height: 100%;
            border-radius: 30px 0 0 30px;
            &.Mui-selected {
              background: white;
              color: $indigo;
            }
            span {
              text-wrap: nowrap;
              display: flex;
              align-items: flex-end;
              font-size: 15px;
            }
          }
        }
      }
    }
    .form {
      padding: 20px 25px;
      width: 100%;
      align-self: center;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .examples-wrapper {
        max-width: 400px;
        min-width: 200px;
        width: 100%;
        .header {
          align-items: flex-start;
          .description {
            .MuiButtonBase-root {
              padding: 0;
            }
          }
        }
      }

      .input-fields-wrapper {
        display: flex;
        flex-wrap: wrap;
        min-width: 300px;
        max-width: 100%;
        flex-grow: 1;

        textarea {
          width: 100%;
          resize: none;
          background: #04062b;
          color: $tertiaryLight;
          border: 1px solid $secondary;
          padding: 10px;
          border-radius: 20px;
        }
      }
    }

    @container generator-wrapper (max-width: 600px) {
      flex-direction: column;
      .tabs-container {
        padding: 10px;
        .MuiTabs-root {
          .MuiTabs-flexContainerVertical {
            flex-direction: row;

            .MuiTab-root {
              border-radius: 30px;
            }
          }
        }
      }
    }
  }

  .MuiChip-clickable {
    &:hover,
    &:focus {
      background-color: $secondary;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #176ad5;
  }

  .app-content--inner {
    padding: 0 !important;
    overflow: hidden;
    //overflow-y: auto !important;
  }

  .app-page-title-bg {
    width: 100%;
    height: 100% !important;
    display: block;
    position: absolute;
  }

  .app-page-title,
  .headquarters .header {
    background: $purpleGradient2;
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    position: relative;
    padding: 0;
    min-height: 80px;

    .title-wrapper {
      display: flex;
    }

    .top-bg-content {
      z-index: 2;
    }

    .tutorial {
      padding: 0 2.4rem;
    }

    .app-page-title--second {
      padding-bottom: 0;
      margin: 0;
      padding-left: 1rem;
    }

    .title-first-line {
      width: 100%;
      flex-direction: row;
      display: flex;
      padding-left: 2.4rem;
      margin-top: 15px;
      max-width: calc(100% - 280px);

      h1 {
        white-space: nowrap;
        position: relative;
        font-weight: bold;
        font-size: 40px;
        padding-right: 20px;
        border-right: 1px solid rgba($light, 0.5);
      }

      span {
        line-height: 16px;
        font-size: 16px;
      }

      svg {
        font-size: 60px;
      }
    }
  }

  .button-linear-progress,
  .my-button {
    background: $primary;
    color: white !important;
    margin-top: 20px;
    text-transform: none;

    &.primary {
      &:hover {
        background: transparentize($primary, 0.2);
      }
    }

    &.blue {
      background: $lightBlue !important;
    }

    &.bold {
      .MuiButton-label {
        font-weight: bold;
      }
    }

    &.clear {
      background: transparent !important;
      border: 2px solid white;
    }

    &.round {
      border-radius: 41px;
    }

    &.text-gradient {
      background: white !important;

      .MuiButton-label {
        background: rgb(162, 104, 238);
        background: linear-gradient(
          81deg,
          $secondary 0%,
          $secondary 15%,
          $lightBlue 50%,
          $secondary 85%,
          $secondary 100%
        );
        background-clip: text;
        background-size: 300% 100%;
        animation: slide 2.5s linear infinite;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        svg {
          color: $secondary;
        }
      }

      &.loading {
        .MuiButton-label,
        svg {
          color: white;
          -webkit-text-fill-color: unset;
        }
      }

      @keyframes slide {
        from {
          background-position: 0% 0%;
        }
        to {
          background-position: -200% 0%;
        }
      }
    }

    &.no-shadow {
      box-shadow: none !important;
    }

    &.rounded {
      border-radius: 10px;
    }

    &.fit {
      width: fit-content !important;
    }

    &.text-gradient-static {
      background: white !important;

      .MuiButton-label {
        background: rgb(162, 104, 238);
        background: linear-gradient(81deg, $lightBlue 0%, $secondary 100%);
        background-clip: text;
        background-size: 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        svg {
          color: $secondary;
        }
      }

      &.loading {
        .MuiButton-label,
        svg {
          color: white;
          -webkit-text-fill-color: unset;
        }
      }
    }

    &.transparent {
      background: transparent !important;
    }

    &.gradient {
      background: $purpleGradient !important;

      .MuiButton-label {
        color: white !important;
      }
    }

    &.Mui-disabled {
      background: $primary !important;
    }

    &.tertiary-light-light {
      background: #8581f5;

      .MuiButton-label {
        color: white !important;
      }
    }

    &.tertiary-light {
      background: $tertiaryLight;

      .MuiButton-label {
        color: $secondary !important;
      }
    }

    &.tertiary {
      background: $tertiary;

      .MuiButton-label {
        color: white !important;
      }
    }

    &.secondary {
      background: $secondary;

      &.Mui-disabled {
        background: $secondary !important;
      }
    }

    &.grey {
      background: #f6f6f6;

      &.Mui-disabled {
        background: #f6f6f6 !important;
      }
    }

    &.loading {
      background: rgba($lightBlue, 0.5) !important;

      .text-content,
      p {
        opacity: 0.8;
        color: white !important;
      }
    }

    .progress-content {
      z-index: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
    }

    .text-content {
      z-index: 1;
      color: white;
    }

    .MuiCircularProgress-root {
      margin-left: 10px;
      top: -1px;
      position: relative;

      svg {
        color: white;
      }
    }

    .MuiLinearProgress-root {
      position: absolute;
      display: inline-block;
      top: 0 !important;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0.5;
    }
  }

  .no-background {
    background: transparent !important;
    color: inherit !important;
  }

  .horizontal-spacing {
    .MuiGrid-root {
      padding: 0 30px;
    }
  }

  .text-white {
    color: white;
  }

  .clickable {
    cursor: pointer;
  }

  .generated-game-list {
    padding: 0;
  }

  .animate__animated.animate__fadeIn {
    --animate-duration: 0.7s;
  }

  .mechanics {
    padding: 5px;

    .mechanics-title {
      font-weight: bold;

      .reload-button {
        position: absolute;
        top: 4px;
        margin-left: 0;
      }
    }

    .mechanics-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-width: 100%;
      align-content: flex-start;

      .item {
        width: fit-content;
      }

      .mechanic {
        position: relative;
        background: $light;
        height: fit-content;
        width: fit-content;
        margin: 15px 0 0 15px;
        border-radius: 8px;
        color: $primary;
        padding: 40px 40px 40px 50px;
        white-space: pre-wrap;
        line-height: 17px;

        .generated-image {
          margin-bottom: 10px;
        }

        .type-image {
          position: absolute;
          top: -15px;
          left: -15px;
          border-radius: 50%;
          background: $secondary;
          border: 4px solid white;
          padding: 5px;
          box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.3);
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;

          span {
            color: white;
            font-weight: bold;
          }
        }

        &:hover {
          opacity: 0.9;

          .element-actions {
            opacity: 1;
          }
        }

        .element-actions {
          position: absolute;
          right: -15px;
          top: 0;
          width: 100px;
          display: flex;
          flex-direction: row;
          transition: all 0.1s;
          opacity: 0;

          .more {
            width: 1em;
          }
        }
      }
    }
  }

  .gradient-top {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25),
      transparent
    );
    border-radius: 5px;
    height: 50px;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .thumbnail-preview {
    .thumbnail-controls {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      padding: 10px 10px;
      height: fit-content;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);
    }

    .square-thumbnail {
      position: relative;
      background-color: black;
      overflow: hidden;

      img,
      video {
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .my-image {
    position: relative;

    &.fake {
      width: 50px;
      height: 50px;
    }

    .nsfw-warning {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      top: 0;
      left: 0;
      cursor: pointer;

      .nsfw-content {
        width: fit-content;
        margin: auto;
        padding: 10px;
        background: transparentize(white, 0.3);
        border-radius: 10px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-self: center;
      }
    }

    &:hover {
      .image-actions,
      .gradient-top {
        display: flex;
      }
    }

    .gradient-top {
      display: none;
    }

    &.blurred {
      .gradient-top {
        display: none !important;
      }

      .blur-top {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      img {
        filter: blur(8px);
        -webkit-filter: blur(8px);
        -webkit-clip-path: inset(0);
        clip-path: inset(0);
      }

      .image-actions {
        display: none !important;
      }
    }

    .image-actions {
      top: 5px;
      right: 5px;
      position: absolute;
      z-index: 99;
      display: none;

      button {
        width: 30px;
        height: 30px;
      }

      & > button,
      & > div {
        padding: 0;
      }
    }
  }

  .favorite-button-wrapper,
  .dislike-button-wrapper {
    &.slim {
      button {
        padding: 0;
      }

      .favorite-button,
      .dislike-button {
        height: 16px;
      }
    }

    &.white {
      .favorite-button,
      .dislike-button {
        color: white;
      }

      svg {
        color: white;
      }
    }

    .favorite-button {
      color: $secondary;
      height: 24px;
    }

    .dislike-button {
      color: $secondary;
      height: 24px;
    }
  }

  .MuiTabs-scroller {
    z-index: 0;

    .MuiBadge-root span {
      color: $lightBlue;
      font-weight: bold;
    }

    .MuiBadge-badge {
      color: white !important;
    }

    .Mui-selected {
      .MuiBadge-root span {
        color: $lightBlue;
      }
    }

    .MuiTabs-indicator {
      background: $lightBlue;
    }
  }

  .text-secondary {
    color: $secondary;
  }

  .text-white {
    color: white;
  }

  .text-white-important {
    color: white !important;
  }

  .text-tertiary {
    color: $tertiary;
  }

  .text-blue {
    color: $lightBlue;
  }

  .generated-game-card,
  .details-panel {
    .description {
      .highlight {
        &.junk {
          opacity: 0.5;
        }
      }
    }
  }

  .page-content {
    position: relative;
    width: 100%;
    display: block;
    //top: -78px;//TODO
    background: white;
    height: fit-content;
    //min-height: calc(100vh - 126px);
    flex-grow: 1;

    & > div {
      height: fit-content;
    }
  }

  .white-controls {
    label,
    div,
    svg,
    span,
    input {
      color: white !important;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid white;
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid white;
    }
  }

  .MuiTab-wrapper {
    span {
      font-family: inherit;
      font-size: inherit;
    }
  }

  .MuiTab-wrapper {
    font-size: 1.2em;
  }

  .lato,
  .MuiTypography-body1 {
    font-family: "Lato", arial;
  }

  .MuiDialog-container .MuiPaper-root {
    padding: 0 !important;
  }

  .bg-light {
    display: contents;
  }

  .MuiSwitch-root {
    .MuiSwitch-thumb {
      background: white;
    }
  }

  .MuiDialogActions-spacing {
    margin: auto;
  }

  .app-header {
    .trial {
      flex-grow: 1;
      text-align: right;
    }
  }

  .app-content--inner {
    &.gdd,
    &.game-concept-old,
    &.game-concept {
      .details-panel {
        position: absolute;
        right: 0;
        z-index: 10;
      }
    }

    .app-content--inner__wrapper {
      z-index: 4;

      .top-hr {
        top: 70px;
        width: calc(100% - 70px);
        margin: 0 35px;
        box-sizing: border-box;
      }
    }
  }

  .hr-blue {
    border-bottom: 2px solid #819ac7;
    opacity: 0.5;
  }

  .faded-text {
    opacity: 0.6;
  }

  .custom-carousel {
    max-width: 680px;
    margin: auto;

    .slider-wrapper {
      border: 2px solid $secondary;
      height: 100px;
      width: calc(100% - 100px);
      box-sizing: border-box;
      margin-bottom: 50px;
      margin-left: 50px;
      margin-right: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50px;
        border-color: transparent;
        border-style: solid;
      }

      &::before {
        border-width: 1.6em;
        border-left-color: $secondary;
        border-top-color: $secondary;
      }
    }

    .carousel-slider {
      display: flex;
      flex-direction: column-reverse;
      height: 150px;

      button {
        height: 100px;
      }

      .slide {
        background: none;
        padding: 20px 50px;

        .info {
          color: $secondary;
          font-weight: bold;
          font-size: $font-size-lg;
        }
      }

      .control-arrow {
        top: 10px;
        left: -15px;
        font-size: 50px;

        &.right {
          right: -5px;
          left: unset;
        }

        &:hover {
          background: none;
        }
      }

      .carousel-status {
        left: 53px;
        top: -2px;
        color: $primary;
        font-size: $font-size-xl;
      }
    }

    .thumbs-wrapper {
      display: none;
    }

    .carousel .control-dots {
      .dot {
        box-shadow: none !important;
        width: 15px;
        height: 15px;
        background: none;
        border: 2px solid $secondary;

        &.selected {
          background: $secondary;
          border: 1px solid $secondary;
        }
      }
    }
  }

  .page-content,
  .full-screen-modal {
    .top-bg {
      background-size: cover;
      background-image: linear-gradient(
        90deg,
        rgba(#914d3a, 0.65),
        rgba($primary, 0.65) 25%,
        rgba($primary, 0.85) 75%,
        rgba($secondary, 0.65) 100%
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: local;
      opacity: 0.7;
      z-index: 0;
      top: 0;
    }
  }

  .top-bg {
    background: rgba(63, 102, 170, 0.8)
      url("../assets/images/background/title-background2.jpg") no-repeat top
      center local;
    opacity: 0.1;
    position: absolute;
    width: 100%;
    height: 100%;
    //opacity: 0.2;
    z-index: 0;
  }

  [contenteditable="true"]:empty:before {
    content: attr(placeholder);
    display: block;
    color: $primary;
  }

  .pre {
    white-space: pre-wrap;
    overflow: hidden;
  }

  .top-bg-content {
    z-index: 1;
    color: white !important;

    .MuiFormHelperText-root.Mui-error {
      color: $primary;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: $secondary;
    }

    .MuiFormLabel-root {
      background: white;
      padding: 3px;
      border-radius: 3px;
    }

    .MuiInputBase-root {
      background: white;
    }

    .MuiStepLabel-label {
      color: white !important;
    }

    .MuiStepLabel-iconContainer {
      .bg-success {
        cursor: pointer;
      }
    }
  }

  .new-gdd-modal {
    .MuiPaper-root {
      background: $purpleGradient3;
      color: white;
      overflow: hidden;
      padding: 0 !important;

      .new-gdd-modal-content {
        overflow-y: auto;
        padding: 0 50px 20px 50px;
      }

      .custom-button-wrapper {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        background: $purpleGradient3;

        .my-button {
          margin: 0;
          width: 350px !important;

          .credits {
            margin-left: 15px;
          }
        }
      }

      .top-right {
        top: 10px;
      }

      .large-title {
        font-size: 20px;
        font-weight: bold;
        align-self: center;
        padding: 15px 0;
        z-index: 1;
      }

      .MuiTabs-root {
        align-self: center;
        margin-bottom: 20px;
        width: 100%;

        .MuiTabs-indicator {
          background: white;
        }

        .MuiTabs-flexContainer {
          place-content: center;
        }
      }

      .hint {
        svg {
          color: white !important;
          opacity: 0.9;
        }
      }

      .credits {
        background: $secondary;
        color: white;
        font-size: 13px;
        padding: 3px 5px;
        border-radius: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .secondary-title {
          width: 100%;
          font-weight: bold;
          padding-bottom: 10px;
        }

        .group {
          display: flex;
          flex-direction: column;
          background: rgba(255, 255, 255, 0.1);
          padding: 15px;
          border-radius: 10px;
        }
      }

      .options {
        width: 600px;
        max-width: 100%;

        .option {
          width: 100%;
          background: transparentize(white, 0.9);
          border-radius: 10px;
          padding: 10px 20px;
          cursor: pointer;
          height: 100%;
          border: 2px solid transparent;

          .title-wrapper {
            display: flex;
            justify-content: space-between;
          }

          &:hover {
            border: 2px solid white;
          }

          .title {
            display: block;
            font-weight: bold;
            margin-bottom: 10px;
          }

          ul {
            padding: 0;
            margin: 0;

            li {
              list-style: none;
              opacity: 0.8;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .lock-modal {
    .MuiDialog-paper {
      width: 800px !important;
      max-width: 800px;
    }

    .content-wrapper {
      width: 100%;
    }
  }

  .transparent-modal {
    .MuiDialog-paper {
      background: transparentize($indigo, 0.2);
    }
  }

  .full-screen-modal {
    //z-index: 100001 !important;

    &.no-padding {
      .MuiPaper-root {
        padding: 0 !important;
      }
    }

    .title-first-line h1 {
      border-right: none;
    }

    &.no-scroll {
      overflow: hidden;

      .MuiPaper-root {
        overflow: hidden;
      }

      .scrollable {
        overflow-y: auto;
      }
    }

    .MuiDialog-container {
      overflow-y: hidden;
    }

    .MuiDialogContent-root {
      overflow: hidden;
    }

    .MuiPaper-root {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      margin: 0;
      overflow-x: hidden;
      padding-bottom: 50px !important;

      .top-bar {
        background: $primary;
        color: white;
        padding: 25px;
      }
    }
  }

  .ludo-modal {
    .MuiPaper-root {
      padding: 20px 50px !important;
      min-width: fit-content;
    }

    .share-content {
      flex-wrap: wrap;
      justify-content: center;
    }

    &.no-padding {
      .MuiPaper-root {
        padding: 0 !important;
      }
    }

    .MuiDialogTitle-root {
      text-align: center;
      color: $primary;
      border-bottom: none !important;

      h2 {
        font-family: inherit;
        font-size: 30px !important;
      }
    }

    .MuiDialogActions-root {
      border-top: none !important;

      .MuiButton-root {
        margin-top: 0 !important;
      }
    }

    .column-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      align-items: center;
    }

    &.small-modal {
      max-width: 95vw;
      max-height: 95vh;
      width: 600px;
      height: 400px;
      min-height: 400px;
      margin: auto;
      .MuiPaper-root {
        padding: 0 !important;
        border-radius: 10px;
        min-height: 400px;
      }
    }
  }

  .welcome-form-modal {
    .MuiPaper-root {
      background: $purpleGradient3;

      .welcome-form-wrapper {
        max-width: 800px;
        margin: auto;
        padding: 50px 10px 50px 10px;
        color: white;
        font-family: "Lato", Arial !important;
      }

      h1,
      h2 {
        color: white;
        text-align: center;
      }

      img {
        margin-bottom: 20px;
      }

      h1 {
        font-size: 25px;
      }

      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      span {
        font-weight: bold;
      }

      fieldset {
        max-width: unset !important;
      }

      .hs-input[type="text"],
      select {
        width: 100% !important;
      }

      .form-columns-2 {
        //display: flex;
        //gap: 20px;
      }

      .hs-form-required {
        color: red;
        padding-left: 5px;
        font-size: 20px;
      }

      select {
        height: 30px;
      }

      ul {
        margin: 10px 0 0 0;
        padding: 0;

        li {
          list-style: none;

          .hs-form-checkbox-display {
            line-height: 20px;
          }

          span {
            font-weight: normal;
          }

          .hs-input {
            width: unset;
            margin-right: 10px;
          }
        }
      }

      .hs-error-msg,
      .hs-error-msgs label {
        color: red;
        background: transparentize(white, 0.1);
        border-radius: 2px;
        padding: 5px;
      }

      .hs-email {
        display: none !important;
      }

      .hs-button {
        background: $lightBlue;
        color: white;
        width: fit-content;
        font-size: 18px;
        padding: 5px 50px;
        border: none;
        border-radius: 16px;
        outline: none;
        cursor: pointer;
        min-width: 200px;
        text-align: center;
        margin-bottom: 20px;

        &:hover {
          background: transparentize($lightBlue, 0.3);
        }

        &.secondary {
          background: $secondary;

          &:hover {
            background: transparentize($secondary, 0.3);
          }
        }
      }
    }
  }

  .whats-new-modal {
    .MuiPaper-root {
      max-width: 1083px;
      min-width: 800px;
      padding: 0 !important;
      border-radius: 30px;

      .MuiDialogTitle-root {
        background: $secondary;

        .MuiInput-underline {
          &:after,
          &:before {
            display: none;
          }
        }

        h2 {
          margin: auto;
          color: white;
          font-size: 27px;
          font-weight: bold;
        }
      }

      .MuiDialogContent-root {
        padding: 0;

        .whats-new-item {

          border-bottom: 4px solid #ECEAF1;
          font-size: 16px;

          .content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title {
              font-size: 32px;
              color: $secondary;
              font-weight: bold;
            }

            h1 {
              font-size: 27px;
            }

            h2 {
              font-size: 24px;
            }

            h3 {
              font-size: 19px;
              font-weight: bold;
            }

            p {
              display: flex;
              gap: 15px;
              flex-wrap: wrap;
              margin: 0;
              img {
                max-height: 600px;
                max-width: 600px;
                width: auto;
              }
              &:first-child {
                color: $secondary;
              }
            }

            ul {
              gap: 15px;
            }

            .date {
              font-weight: bold;
              color: $lightBlue;
            }

            .description {
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }
      }
    }
  }

  .faq-modal {
    .MuiPaper-root {
      max-width: 1083px;
      min-width: 800px;
      padding: 0 !important;
      border-radius: 30px;

      .MuiDialogTitle-root {
        background: $secondary;

        .MuiInput-underline {
          &:after,
          &:before {
            display: none;
          }
        }

        h2 {
          width: min-content;
          margin: auto;

          span {
            font-size: 27px;
            font-weight: bold;
          }
        }

        svg {
          color: white;
        }

        .MuiSelect-root {
          color: white;
        }
      }

      .MuiDialogContent-root {
        padding: 0;
      }
    }

    .faq-items {
      display: flex;
      flex-direction: column;
      padding: 10px 30px;

      .MuiAccordion-root {
        width: unset;
        max-width: unset;
        min-width: unset;
        border-radius: unset;

        box-shadow: none;
        border-top: 1px solid $light;
        //border-bottom: 1px solid $light;

        &:first-child {
          border: none;
        }

        &.Mui-expanded {
          margin: 0;
        }

        .MuiAccordionSummary-content {
          margin: 12px 0 !important;

          p {
            font-weight: bold;
            font-size: 20px;
            color: #13164b;
          }

          &.Mui-expanded {
            p {
              color: $secondary;
            }
          }
        }

        h4 {
          font-size: 1.25rem;
        }

        .MuiButtonBase-root svg {
          color: #13164b;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .others {
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;

      .title {
        color: $secondary;
        font-weight: bold;
      }
    }

    .discord {
      width: 450px;
      height: 200px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: auto;
      margin-top: 10px;
      overflow: hidden;

      .answers {
        display: flex;
        font-weight: bold;
        font-size: 39px;
        line-height: 46px;
        width: 200px;
        text-align: center;
        align-self: center;
      }

      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        height: 200px;
        position: relative;

        .discord-icon {
          position: relative;
          z-index: 0;

          .bg {
            background: white;
            width: 50px;
            height: 30px;
            position: absolute;
            top: 30px;
            left: 10px;
            z-index: -1;
          }
        }

        .square {
          background: $lightBlue;
          position: absolute;
          width: 300px;
          height: 300px;
          transform: rotate(-15deg);
          right: -84px;
          top: 30px;
          z-index: 0;
        }

        img {
          z-index: 1;
        }

        span {
          color: white;
          z-index: 1;
          font-weight: bold;
        }

        .MuiButton-root {
          background: $secondary;
          width: 200px !important;
        }
      }
    }
  }

  .counters-bar {
    position: absolute;
    top: 28px;
    right: 65px;
    display: flex;
    z-index: 10;

    .counter {
      position: relative;
      color: white;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      top: -3px;
      //width: 100px;

      .progress-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .bar {
          border-radius: 20px;
          background: white;
          width: 65px;
          height: 20px;
          padding: 2px 2px;
          position: relative;
        }

        span {
          font-size: 13px;
          position: absolute;
          font-weight: bold;
          width: 100%;
          top: 1px;
          left: -5px;
          display: block;
          margin: auto;
          text-align: center;
          color: $primary;
        }
      }
    }

    .credits-counter {
      .bar {
        margin-right: -10px;
        width: 65px !important;
      }

      .info-icon {
        background: white;
        height: 30px;
        width: 30px;
        z-index: 1;
        border-radius: 30px;
        left: -5px;
        position: relative;

        .animated-amount {
          background: $secondary;
          color: white;
          width: 80%;
          height: 80%;
          position: absolute;
          z-index: 1;
          border-radius: 50%;
          left: 10%;
          top: 10%;
          display: flex;
          place-content: center;
          align-items: center;

          animation: bounceIn 1s, zoomOut 1s 2s;
          animation-fill-mode: forwards;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .achievements-counter {
      display: inline-flex;
      margin-right: -5px;

      .bar {
        left: 30px;
      }

      .achievement-icon {
        //left: -30px;
        top: -5px;
        position: relative;
        z-index: 1;

        img {
          width: 60px;
        }
      }

      button {
        margin-top: 0 !important;
      }
    }
  }

  .profile-button {
    position: absolute;
    top: 25px;
    right: 35px;
    z-index: 11;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .profile-menu {
    .MuiPaper-root {
      border: 1px solid $primary;
    }

    svg {
      margin: auto;
    }

    .MuiListItemText-root {
      padding: 0 20px 0 10px;
    }
  }

  .image-tooltip {
    width: calc(100vw - 460px);
    height: 100vh;
    z-index: 2200;
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    left: 0;
    top: 0;
    transform: unset !important;
    transition: none !important;
    will-change: unset !important;

    .MuiTooltip-tooltip {
      min-width: 100%;
      min-height: 100%;
      background: none;
      box-sizing: border-box;
      display: flex;
      place-content: center;
      margin: 0;
      box-shadow: none !important;
      transform-origin: unset !important;
      transition: none !important;

      img {
        max-width: 90%;
        max-height: 90%;
        height: auto;
        width: auto;
        place-self: center;
        position: absolute;

        &.horizontal {
          min-width: 60%;
        }

        &.vertical {
          min-height: 80%;
        }
      }
    }
  }

  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      text-indent: 0 !important;
      max-width: 500px !important;
      width: max-content !important;
      font-size: 13px;
      text-wrap: pretty;
    }

    &.secondary {
      .MuiTooltip-tooltip {
        background: $secondary;
        color: white;

        .MuiTooltip-arrow {
          color: $secondary;
        }
      }
    }

    &.primary {
      .MuiTooltip-tooltip {
        background: $primary;
        color: white;

        .MuiTooltip-arrow {
          color: $primary;
        }
      }
    }

    &.max-width {
      .MuiTooltip-tooltip {
        max-width: 300px !important;
      }
    }
  }

  .underline {
    text-decoration: underline !important;
  }

  .register,
  .login,
  .forgot-password,
  .join-team,
  .reset-password {
    .auth-form-wrapper {
      max-width: 510px;
      margin: auto;

      .info {
        text-align: center;
        display: block;
        width: 100%;

        a {
          color: white;
          text-decoration: underline;
        }
      }

      .MuiFormHelperText-root.Mui-error {
        color: #551700;
      }

      .payment-form {
        margin-top: 20px;

        .MuiOutlinedInput-input {
          padding: 10px 14px;
        }

        .label {
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
        }

        .my-button {
          width: 100% !important;
        }

        .payment-info {
          display: flex;
          font-weight: bold;
          justify-content: space-between;

          .left,
          .right {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }

      .auth-wrapper {
        text-align: center;
        text-align: -webkit-center;
        min-height: 100px;
        max-height: 100px;

        .my-facebook-button-class {
          border: none;
          height: 40px;
          border-radius: 5px;
          background: #3284f4;
          color: white;
          width: 100%;
          max-width: 250px;

          svg {
            float: left;
            margin-left: 10px;
          }
        }
      }
    }

    .logo {
      max-width: 100%;
      padding-top: 20px;
    }

    .checkbox {
      .MuiCheckbox-root {
        color: white;
      }

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: unset !important;
      }

      .MuiFormLabel-root {
        span,
        a {
          color: white;
        }
      }
    }
  }

  .MuiListItem-root {
    .locked {
      opacity: 0.5;
    }

    &:hover {
      .MuiListItemIcon-root,
      a,
      .MuiListItemText-primary {
        color: $primary;
      }
    }

    .MuiListItemIcon-root,
    a,
    .MuiListItemText-primary {
      font-size: 1em;
      line-height: 1;
    }

    .MuiListItemIcon-root {
      min-width: 36px !important;
    }
  }

  .MuiAlert-root {
    &.bg-secondary {
      .MuiAlert-icon {
        color: white;
      }
    }
  }

  .MuiFormLabel-root {
    font-size: 1.1em !important;
    //letter-spacing: 0.9px;
    //font-family: 'Bison' !important;
    //text-transform: uppercase !important;
  }

  .onboarding-tooltip {
    word-wrap: break-word;
    max-width: 400px;
    background: $primary;
    border: 3px solid white;
    border-radius: 10px;
    padding: 20px;
    color: white;
    position: relative;
    text-align: center;
    font-weight: bold;

    .close-icon {
      position: absolute;
      right: 7px;
      top: 7px;
      cursor: pointer;
    }

    button {
      display: block;
      width: fit-content !important;
      margin: auto;
    }
  }

  .top-right {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .team {
    width: 700px;
    max-width: 100%;

    .disabled {
      opacity: 0.5;
    }

    .user {
      .right {
        display: -webkit-inline-box;
        width: 70px;
      }
    }
  }

  .border-secondary {
    border: 3px solid $secondary !important;
  }

  .gdd-side-menu-3,
  .details-panel {
    .generated-game-card {
      cursor: pointer;
      margin: 10px 0;

      .description {
        cursor: pointer;
      }
    }

    .form-wrapper {
      background: #153775;
      padding: 20px;
      color: white !important;

      & > div {
        padding: 0 !important;
      }

      .universal-search {
        background: #0f2652;
        margin-bottom: 15px !important;

        input {
          color: white;
        }

        input::-webkit-input-placeholder {
          opacity: 0.6;
          color: white;
          font-weight: bold;
          font-style: italic;
        }

        .MuiInputAdornment-root svg {
          color: white;
        }
      }

      .MuiGrid-root {
        padding: 0 !important;
      }

      .MuiButtonBase-root,
      .MuiFormLabel-root {
        color: white;

        &.Mui-selected {
          color: $secondary;
        }
      }

      .MuiButton-containedPrimary {
        background: $secondary !important;
      }
    }

    .light-hr {
      background: transparentize($tertiary, 0.75);
    }

    .game-grid {
      .MuiGrid-root {
        justify-content: center;
      }
    }

    .input-fields {
      flex-direction: column !important;

      .universal-search,
      .mode-field {
        border-radius: 30px;
        width: 100%;
        background: $formBlue;

        input::-webkit-input-placeholder {
          opacity: 1;
          color: $darkPurple;
          font-weight: bold;
          font-style: italic;
        }
      }

      .mode-field {
        height: 50px;
        padding-right: 10px;
      }
    }

    .file-wrapper {
      svg {
        color: white;
      }
    }

    &.option-ideator,
    &.ideator {
      .input-fields {
        flex-direction: column;

        .universal-search,
        .mode-field {
          border-radius: 30px;
          width: 100%;
        }

        .mode-field {
          height: 50px;
          padding-right: 10px;
        }
      }

      .examples {
        flex-flow: wrap;
        gap: 5px 10px;
        .MuiIconButton-root {
          padding: 0;
        }
        .chips {
          display: contents;
          .chip {
            height: 35px;
          }
        }
      }

      .details-panel-history {
        padding-bottom: 0;

        .navigate {
          display: none;
        }
      }

      .main-generator-content {
        .loading-tip {
          margin: auto;
        }

        .buttons {
          .my-button {
            background: $lightBlue;
          }
          svg {
            color: $lightBlue;
          }
        }

        .section-title {
          display: none;
        }
      }

      .seeds {
        padding-top: 15px;
        background: $darkBlue;
      }

      .seeds > .MuiGrid-root > .MuiGrid-root {
        max-width: 100%;
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;
      }

      .type-seed {
        display: none;
      }
    }
  }

  .details-panel {
    z-index: 3;
    flex-grow: 1;
    flex-shrink: 0;
    width: 460px;
    background: $primary;
    color: white;
    font-size: 14px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    transition: width 0.01s cubic-bezier(0.25, 0.1, 0.25, 1);

    .loading-tip {
      color: black;
    }

    .large-title-top {
      position: absolute;
      margin: 0 10px;
      font-size: 20px;
      font-weight: bold;
      top: 10px;
      left: 10px;
      z-index: 1000;
    }

    .details-panel-history {
      position: sticky;
      height: 60px;
      top: 0;
      z-index: 1000;
      width: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba($primary, 1) 0%,
        rgba($primary, 0.9) 60%,
        rgba($primary, 0) 100%
      );
      padding-bottom: 10px;

      .navigate {
        float: left;
        position: relative;
        margin: 0 10px;

        svg {
          color: white !important;
        }
      }

      .close {
        float: right;
        margin-right: 5px;

        svg {
          color: white !important;
          border-radius: 50%;
        }
      }
    }

    &.index {
      .menu-section-content {
        padding: 20px;
      }
    }

    .info-element {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 15px;

      .info-title {
        font-size: 15px;
        color: $tertiary;
        font-weight: bold;
      }

      .info-content {
        font-size: 30px;
        display: flex;
        flex-direction: row;
        align-self: center;

        &.small {
          font-size: 30px;
        }

        .info-small {
          font-size: 13px;
          opacity: 0.75;
        }

        svg {
          position: relative;
          top: 12px;
        }
      }
    }

    .rating {
      color: $lightPurple;
      font-weight: bold;
      //text-shadow: 0 0 2px rgba(255, 255, 255, 1);

      span {
        font-size: 14px;
      }

      .MuiRating-root span {
        font-size: 22px !important;
      }

      .MuiRating-root {
        margin: auto;
      }
    }

    .MuiDivider-root {
      background-color: rgba(white, 0.25);
      opacity: 0.25;
      height: 2px;
    }

    .MuiCircularProgress-root {
      svg {
        color: white;
      }
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 460px;
      top: -40px;

      .small {
        position: relative;
        top: -5px;
      }

      .header {
        position: relative;

        .icons {
          position: relative;
          top: 5px;
          width: 100%;

          .right {
            position: absolute;
            right: 20px;
          }
        }
      }

      .actions {
        background: $indigo;
        padding-bottom: 12px;

        .actions-content {
          height: 60px;
          text-align: center;
          display: flex;
          flex-direction: column;

          .locked {
            opacity: 0.55;
          }

          a {
            display: block;
          }

          .MuiButtonBase-root {
            width: 47px;
            height: 47px;
          }

          svg {
            height: 30px;
            width: 30px;
          }

          .small {
            //font-family: 'Bison' !important;
            font-size: 13px;
            //letter-spacing: 0.9px;
          }

          .MuiButtonBase-root {
            margin: auto;
            transform: none !important;
            color: $tertiary;
          }
        }

        .favorite-button,
        svg,
        span {
          color: $tertiary !important;
        }
      }
    }
  }

  .multiple-genres-input,
  .platform-selection {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    padding-bottom: 10px;

    span {
      font-size: 16px;
    }

    .MuiChip-deleteIcon {
      margin-left: 2px;
      margin-right: 10px;
    }

    .add-icon-wrapper {
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .add-genre,
      .add-platform {
        width: 100%;
        top: 0;
        position: absolute;
        opacity: 0;
      }

      .add-platform {
        opacity: 1;

        &::before {
          display: none !important;
        }

        svg {
          top: 10px;
          color: white;
        }
      }
    }
  }

  .action-chip {
    background: $light;
    color: $primary;
    border-radius: 15px;
    //width: fit-content;
    margin: 20px auto 50px auto;
    padding: 30px 0;
    width: 200px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .lock-icon {
      height: 0;
      position: relative;
      bottom: -5px;
    }

    span,
    svg {
      text-align: center;
      margin: auto;
    }
  }

  .MuiButton-root {
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.45) !important;
    padding: 12px 16px;

    &.red {
      background-color: $red;
      color: white;

      &:hover {
        background-color: rgba($red, 0.9);
      }
    }
  }

  .slanted-footer {
    z-index: 11;
    height: 400px;
    position: relative;

    .content {
      margin: auto;
      padding-top: 180px;
      width: 650px;

      .text-white {
        line-height: 40px;
        text-align: right;
      }
    }

    button {
      position: relative;
      top: -10px;
    }

    .top-bg {
      top: 10px;
      max-height: calc(100% - 10px);
    }

    .white-slant {
      z-index: 1;
      position: absolute;
      background: white;
      width: 100%;
      height: 100px;
      top: -10px;
      clip-path: polygon(0 0, 100% 0, 100% 18%, 0% 100%);
    }
  }

  .MuiTabs-root {
    border-bottom: 2px solid #b4c9e8 !important;

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .MuiTabs-indicator {
      height: 4px !important;
    }

    &.primary {
      .MuiTab-root {
        color: $primary !important;
      }

      .Mui-selected {
        color: $primary;
      }

      .MuiTabs-indicator {
        background: $primary;
      }
    }
  }

  .lock-icon {
    color: $primary;

    svg {
      //transform: rotate(-25deg);
    }
  }

  .app-sidebar--content {
    height: 100% !important;

    .upgrade-button {
      background: $lightBlue !important;
    }

    .sidebar-navigation {
      height: 100%;
      display: flex;
      flex-direction: column;

      .settings-wrapper {
        transition: all 0.3s ease-in-out;
        opacity: 1;
        overflow: visible;
        visibility: visible;
        flex-grow: 1;
        justify-content: flex-end;

        li {
          padding: 0;
        }

        .settings {
          width: 100%;
          display: flex;
          flex-direction: column;

          .title {
            display: flex;
            align-items: center;
            text-align: center;
            margin: auto;
            font-size: 14px;
            font-weight: bold;
            color: $secondary;
          }

          .inputs {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            gap: 30px;
            padding: 15px;
            background: $purpleGradient3;

            .genre-field,
            .platform-field {
              display: flex;
              justify-content: center;
            }

            & > div {
              flex-grow: 1;

              .MuiInput-root {
                width: fit-content;
                text-align: center;

                &::before,
                &::after {
                  display: none;
                }

                span,
                svg {
                  color: white;
                }

                span {
                  font-weight: bold;
                  font-size: 16px;
                }
                .MuiInput-underline:before,
                .MuiInput-underline:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .logo-wrapper {
      flex-grow: 1;
      display: flex;
      margin: 20px 10px 10px 10px;

      .app-sidebar-logo {
        align-self: flex-start;

        .ludo-text {
          font-family: "Bison", Arial !important;
          height: 50px;
        }
      }
    }
  }

  button {
    box-shadow: none !important;

    .lock-icon {
      z-index: 1;
      position: absolute;
      right: -5px;
      top: 3px;
      font-size: 25px;
    }
  }

  .link {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &.hover-secondary {
      &:hover {
        color: $secondary;
      }
    }
  }

  .hide {
    display: none;
  }
}

.dropzone-wrapper {
  width: 400px;
}

.checkbox {
  margin: 10px 0;
  flex-direction: row-reverse !important;
  display: flex !important;
  float: left !important;
  align-items: flex-start;

  span {
    animation-duration: 0s;
  }

  &.Mui-error {
    span {
      animation-duration: 1s;
    }

    .MuiSvgIcon-root {
      fill: $secondary !important;
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-left: 10px;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  .MuiFormHelperText-root {
    width: 100%;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: unset !important;
  }

  .MuiFormLabel-root {
    background: none !important;

    span,
    a {
      font-family: inherit;
      font-size: inherit;
    }
  }
}

.MuiCheckbox-root {
  &.white {
    .MuiSvgIcon-root {
      color: white !important;
    }
  }
}

.dropzone-upload-wrapper {
  .dropzone-inner-wrapper {
    background: $secondary;
    color: white;
    opacity: 0.7;

    .instructions {
      display: flex;
      flex-direction: column;
      gap: 2px;
      font-weight: bold;

      span {
        opacity: 0.7;
      }

      .browse {
        border: 2px solid white;
        border-radius: 5px;
        padding: 2px 10px;
        width: fit-content;
        margin: auto;
        opacity: 0.85;
      }
    }

    .btn-icon {
      height: 50px;
      width: 50px;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.color-picker {
  .MuiPaper-root {
    max-width: 390px !important;
    min-width: 390px !important;
    padding: 15px;
  }

  .MuiChip-label {
    text-transform: capitalize;
  }
}

.color-chip {
  text-transform: capitalize;
}

.color-picker-entry {
  width: 120px !important;
  display: inline-flex !important;
  border-radius: 30px !important;
  padding: 4px !important;
  margin-right: 50px !important;

  .MuiSvgIcon-root {
    color: transparent !important;
  }

  &.Mui-selected {
    background-color: $secondary !important;

    .MuiListItemText-primary {
      color: white !important;
    }

    &:hover {
      .MuiListItemText-primary {
        color: white !important;
        opacity: 0.9;
      }
    }
  }

  svg {
    border-radius: 50%;
  }
}

.universal-search-loading-wrapper {
  color: $secondary;

  .MuiCircularProgress-root {
    position: relative;
    top: 4px;
  }
}

.MuiIconButton-root {
  &.square {
    border-radius: 10px;
  }
  &.transform-none {
    transform: none !important;
  }
  &.gradient {
    background: $purpleGradient !important;
  }
  &.secondary {
    background: $secondary !important;
  }
  &.white {
    background: white !important;
  }
}

.MuiTab-root {
  text-transform: inherit !important;
}

.MuiBadge-root {
  .MuiBadge-colorSecondary {
    background: $secondary;
    color: white !important;
  }

  span {
    justify-content: space-between;
    font-weight: bold;
  }
}

.video-stretch {
  margin: auto;

  iframe {
    max-width: 100%;
  }
}

.mobile-screen {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  opacity: 1;
  background: $primary;
  text-align: center;

  .top-bg {
    z-index: -1;
  }

  @include respond($phone) {
    display: block;
  }
}

.MuiDialog-root {
  //z-index: 1350!important;
}

.MuiListItem-button {
  .locked {
    .MuiListItemText-root,
    .MuiListItemIcon-root {
      opacity: 0.5;
    }
  }
}

.MuiAutocomplete-paper {
  .MuiListSubheader-root {
    font-weight: bold;
    color: $secondary;
  }

  .MuiAutocomplete-option {
    color: $primary;
  }
}

.MuiPaper-root {
  .MuiList-root {
    .MuiButtonBase-root {
      span,
      svg {
        color: $primary;
      }

      &.selected {
        background: $secondary;

        span,
        svg {
          color: white;
        }
      }
    }
  }
}

.strong-colors-form {
  .MuiSelect-root,
  .MuiFormControl-root input {
    color: $secondary;
    font-weight: bold;
  }

  form > .MuiFormControl-root,
  form > .MuiGrid-root {
    margin-bottom: 15px;
  }

  .MuiFormLabel-root {
    color: $primary;
    font-weight: bold;
  }
}

.dismissable-message {
  position: relative;
  background: transparentize($secondary, 0.1);
  color: white;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  white-space: break-spaces;
  text-align: center;
  display: flex;
  font-weight: bold;

  .dismiss {
    position: absolute;
    right: 0;
    top: 0;

    svg {
      color: white;
      font-size: 16px;
    }
  }
}

.loading-tip {
  display: flex;
  background: $light;
  padding: 20px 30px 20px 30px;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  position: relative;

  .icon {
    top: -15px;
    left: -15px;
    position: absolute;
    padding: 5px;
    background: $light;
    border-radius: 50%;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
  }
}

.tabs-wrapper {
  position: relative;
  width: fit-content;
  margin: auto;
}

.flip-vertical {
  transform: scale(1, -1);
}

.flip-horizontal {
  transform: scale(-1, 1);
}

.help-icon {
  position: fixed;
  right: 30px;
  bottom: 10px;
  z-index: 11111111111111111;

  .MuiButtonBase-root {
    background: $secondary;
    border-radius: 50%;

    &:hover {
      background: $secondary;
    }

    svg {
      color: white;
    }
  }
}

.gdd-menu-open {
  .help-icon {
    right: 480px;
  }
}

.generated-image {
  margin-bottom: 0;
  position: relative;

  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: fit-content;
  border-radius: 8px;
  //overflow: hidden;

  .background {
    display: none;
  }

  &.portrait {
    width: auto;

    .background {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      position: absolute;
      filter: blur(12px);
      -webkit-filter: blur(12px);
      -webkit-clip-path: inset(0);
      clip-path: inset(0);
      background-blend-mode: lighten;
    }
  }

  .my-image {
    margin: auto;
    display: flex;
    place-content: center;

    img {
      object-fit: contain;
      max-height: 336px !important;
      width: auto !important;
    }
  }
}

.confirm-dialog {
  .MuiDialogTitle-root {
    border-bottom: none;
    padding: 30px;
    h2 {
      //color: $red;
    }
  }

  .MuiTypography-colorTextSecondary {
    color: unset;
  }

  .MuiDialogContent-root {
    padding: 0 30px;
  }

  .MuiDialogActions-root {
    border-top: none;
    padding: 30px !important;

    .MuiButtonBase-root {
      margin-top: 0;
    }
  }
}

.expandable-grid {
  width: 100%;
  position: relative;

  .grid-wrapper {
    width: 100%;
    margin: 0;

    .grid-list {
      list-style: none;
      padding: 0;
      display: flex;
      text-align: center;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .expanded-detail {
        display: contents;
        position: relative;
        padding: 20px;
        transition: display 2s ease-in-out 0.5s;
        max-width: 100%;
        flex: auto;
      }

      .grid-cell {
        display: inline-flex;
        margin: 10px;
        cursor: pointer;
        margin-bottom: 40px;

        * {
          //pointer-events: none !important;
        }
      }
    }
  }
}

.image-viewer {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  &-close-button {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &-wrapper {
    max-width: 95%;
    max-height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &-loading {
    color: white;
    font-size: 1.2rem;
  }
}

.MuiSvgIcon-root {
  &.secondary {
    color: $secondary;
  }
}

.text-field {
  &.ludo-color {
    background: transparentize(white, 0.75); //#ACC2E1;
    border-radius: 30px;
    padding: 10px 15px;

    label,
    input {
      color: white;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: none !important;
    }

    input::-webkit-input-placeholder {
      opacity: 0.85;
      color: white;
      font-weight: bold;
      font-style: italic;
    }
  }
}
