@import "assets/core/_variables";
@import "assets/_mixins";

.loading-page {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: linear-gradient(120deg, #ffffff 0%, #F8F7F9 100%);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 2;

    span {
      font-size: 16px;
      max-width: 300px;
      text-align: center;
      color: transparentize($indigo, 0.52);
    }

    .title {
      background: $purpleGradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 20px;
    }

    .logo-container {
      width: 95px;
      height: 95px;
      position: relative;
      margin-bottom: 40px;

      .logo {
        width: 100%;
        height: 100%;
      }

      .MuiCircularProgress-root {
        position: absolute;
        top: -20%;
        left: -20%;
        width: 140% !important;
        height: 140% !important;
      }
    }
  }

  .decorations {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;

    @include respond($phone) {
      display: none;
    }

    @keyframes moveSquare {
    0%, 100% {
      transform: translate(var(--startX, 0px), var(--startY, 0px));
    }
    50% {
      transform: translate(var(--moveX, 100px), var(--moveY, 100px));
    }
  }
    @keyframes moveCircle {
      0%, 100% {
        transform: translate(var(--startX, 0px), var(--startY, 0px));
      }
      50% {
        transform: translate(calc(-1 * var(--moveX, 100px)), calc(-1 * var(--moveY, 100px)));
      }
    }

    .square {
      position: absolute;
      border-radius: 18px;
      filter: blur(5px);
      background-color: hsla(0, 0%, 100%, 0.15);
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      animation: moveSquare 20s ease-in-out infinite;
      animation-fill-mode: both;

      .inner {
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 2000px hsla(0, 0%, 100%, 0.15);
        background: #bbb;
        opacity: 0.2;
      }
    }

    .small-circle {
      background: #5780f7;
      box-shadow: 26px 55px 82px rgba(47, 78, 166, 0.29),
      inset 12px 27px 51px rgba(108, 146, 255, 0.72),
      inset -35px -38px 105px rgba(56, 67, 208, 0.76);
      transform: matrix(-0.87, -0.5, -0.5, 0.87, 0, 0);
      border-radius: 50%;
      display: block;
      position: absolute;
      animation: moveCircle 20s ease-in-out infinite;
      animation-fill-mode: both;
    }
  }
}
