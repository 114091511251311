@import "assets/core/_variables";
@import "assets/_mixins";

.headquarters2 {
  width: 100%;
  position: relative;
  background: white;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    background: $purpleGradient2;
    color: white;
    position: relative;
    z-index: 2;

    form {
      text-align: left;
    }

    .top-bg-content {
      .MuiFormLabel-root,
      .MuiInputBase-root {
        background: none;
      }
    }

    .large-title {
      z-index: 1;
      font-size: 96px;
      line-height: 80px;
      padding: 10px 0;
    }

    .small-text {
      margin-top: 10px;
      opacity: 0.75;
      font-weight: bold;
      font-size: 17px;
      padding: 15px 30px;
    }

    .header-form-wrapper {
      padding: 26px;

      &.short {
        .input-fields {
          margin: auto;
        }
      }

      .hq-form-wrapper {
        background: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
          padding: 0 15px 0 0;
          margin: 0 !important;
          position: relative;
          height: 50px;
        }
      }

      .form {
        width: 100%;
        margin: auto;
        padding-right: 101px;

        .input-fields {
          justify-content: center;
          position: relative;
          top: 10px;
          .info-text {
            position: absolute;
            top: -25px;
            width: 100%;
            display: flex;
            place-content: center;
            font-size: 14px;
            overflow: visible;
            text-wrap: nowrap;
            .text {
              opacity: 0.6;
            }

            .help {
              opacity: 0.6;
            }
          }
        }

        .platform-field {
          display: flex;
          padding: 5px 30px;
          background: transparentize(white, 0.7);
          border-radius: 0 30px 30px 0;
          gap: 10px;
          .MuiChip-root {
            padding: 20px;
            border-radius: 25px;
            background: none;
            span {
              color: white;
              font-size: 17px;
            }
            &.active {
              background: white;
              span {
                color: $secondary;
                font-weight: bold;
              }
            }
          }
        }

        .genre-field {
          width: 200px;
          background: white;
          border-radius: 30px 0 0 30px;
          padding-right: 10px;
          padding-left: 10px;
          height: 50px;
          display: flex;
          place-content: center;
          justify-content: center;
          margin-bottom: 0;

          span {
            color: $secondary;
            font-weight: bold;
            font-size: 17px;
            text-align: center;
            width: 100%;
          }

          svg {
            color: $secondary;
          }

          .MuiInput-root {
            margin-top: 0;
          }

          .MuiSelect-icon {
            color: $primary;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            display: none;
          }

          .MuiFormControl-root > div:before {
            border-bottom-color: white !important;
          }

          .MuiFormLabel-root {
            display: none;
          }
        }
      }
    }
  }

  .main-hq-content {
    padding: 20px;
    color: white;
    background: $darkBlue;
    flex-grow: 1;

    .main-content {
      //max-width: 1251px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      gap: 40px;
    }

    .padded-content {
      max-width: 1251px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      gap: 30px;
    }

    .welcome {
      font-size: 36px;
      font-weight: bold;
    }

    .top-row {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;
      container-type: inline-size;
    }

    .themed-box {
      width: 100%;
      position: relative;
      overflow: visible;
      flex: 1 1 calc(50% - 10px);
      container-type: inline-size;
      min-width: calc(min(500px, 90vw));
      min-height: 230px;
      height: auto;
      border-radius: 22px;

      .bg-image {
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 20px;
      }

      .bg-overlay {
        background: $purpleGradient;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.9;
        border-radius: 20px;
      }

      .content-wrapper {
        z-index: 2;
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 40px 40px;
        gap: 20px;

        @container (min-width: 625px) {
          padding: 35px 70px;
          flex-direction: row;

          .info {
            flex-direction: column;
            gap: 0;
            .line1 {
              font-size: 32px;
            }
            .line2 {
              font-size: 37px;
            }
            .line3 {
              font-size: 37px;
            }
          }
          .actions {
            width: unset;
            flex-direction: column;
            align-self: unset;
            justify-content: flex-end;
          }
        }

        @include respond($phone) {
          .actions {
            width: unset;
            flex-direction: column;
            align-self: unset;
            justify-content: flex-end;
            button {
              width: 100% !important;
            }
          }
        }

        @container (max-width: 690px) {
          padding: 40px 40px;
          svg {
            display: none;
          }
        }
      }

      .info {
        display: flex;
        //flex-direction: column;
        flex-grow: 1;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0 10px;

        .line1 {
          //font-size: 32px;
          font-size: 28px;
          font-weight: 100;
          width: 100%;
        }

        .line2 {
          //font-size: 50px;
          font-size: 28px;
          font-weight: bold;
        }

        .line3 {
          //font-size: 50px;
          font-size: 28px;
          font-weight: 100;
        }
      }

      .actions {
        display: flex;
        //flex-direction: column;
        gap: 10px;
        align-items: center;
        align-self: center;
        flex-direction: row;
        width: 100%;
        place-content: space-between;

        .my-button {
          margin-top: 0;
          width: 200px !important;
          .MuiAvatar-root {
            width: 30px;
            height: 30px;
          }
          svg,
          .MuiAvatar-root {
            font-size: 30px;
            margin-right: 10px;
          }
          .MuiButton-label {
            font-size: 25px;
          }
        }
      }
    }

    .row {
      display: flex;
      width: 100%;
      gap: 50px;
    }

    .smaller-title {
      font-size: 28px;
      padding: 20px 0;
    }

    .outer-features-container {
      background-color: green;
    }

    .outer-features-gradient {
      background: red;
    }

    .features {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      position: relative;

      .feature {
        border-radius: 20px;
        max-width: 100%;
        width: 393px;
        height: 305px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        .feature-bg {
          background: $purpleGradient;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        img {
          position: absolute;
          width: 100%;
          height: auto;
          margin: auto;
        }

        .my-button {
          position: absolute;
          bottom: 25px;
          .MuiButton-label {
            gap: 15px;
          }
        }
      }
    }
  }
}

.import-concept-modal {
  .MuiPaper-root {
    border-radius: 47px;
  }

  .MuiTypography-h6 {
    background: rgb(162, 104, 238);
    background: linear-gradient(
      81deg,
      rgba(162, 104, 238, 1) 0%,
      rgba(162, 104, 238, 1) 44%,
      rgba(69, 177, 248, 1) 74%,
      rgba(0, 212, 255, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .import-concept-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 90vw;
    width: 1200px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px 50px !important;

    .MuiTabs-root {
      height: 60px;
      min-height: 60px;
      border-bottom: 2px solid rgba($secondary, 0.3) !important;

      .MuiTabs-indicator {
        background: $secondary;
      }

      .MuiTabs-flexContainer {
        place-content: center;
        .MuiTab-root {
          height: 60px;
          span {
            color: $secondary;
            gap: 10px;
            display: flex;
          }
          svg {
            font-size: 30px;
          }
        }
      }
    }

    .add-images {
      margin: auto;
      max-width: calc(min(630px, 80vw));
      max-height: 297px;
      height: 100%;
      width: 100%;

      .dropzone-wrapper,
      .dropzone,
      .dropzone-upload-wrapper,
      .dropzone-inner-wrapper {
        width: 100% !important;
        height: 100% !important;
      }

      .dropzone-inner-wrapper {
        opacity: 1;
        background: linear-gradient(
          135deg,
          transparentize($secondary, 0.85) 0%,
          transparentize($lightBlue, 0.85) 100%
        );
        color: transparentize($secondary, 0.5);
        border: none;
        padding: 20px 0 !important;

        .document-preview {
          display: flex;
          flex-direction: column;
        }

        span {
          font-size: 22px !important;
        }

        .btn-icon {
          width: 100px;
          height: 100px;
        }

        .pdf-icon {
          font-size: 50px;
        }

        .browse {
          max-width: 100% !important;
          width: 300px !important;
          background: linear-gradient(135deg, $secondary 0%, #491988 100%);
          opacity: 1;
          border: none;
          color: white;
          font-size: 20px;
          font-weight: normal;
          cursor: pointer !important;
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 33px;

          svg {
            color: white;
          }
        }

        svg {
          color: transparentize($secondary, 0.5);
        }
      }
    }

    &.game {
      max-width: calc(min(942px, 95vw));
      max-height: 555px;
      .form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: white;

        .add-game {
          position: relative;
          width: 225px;
          height: 180px;
          z-index: 2;
          input {
            color: $secondary;
          }

          .new {
            min-width: 100%;
            width: 100%;

            display: flex;
            place-content: center;
            align-items: center;

            .plus {
              color: $secondary;
              font-size: 40px;
            }
          }

          .game-grid-item {
            align-self: flex-start;
          }

          .game-element {
            height: 100%;
            margin: 0;
            justify-content: center;
            white-space: pre-wrap;
            cursor: pointer;
            border-radius: 8px;
            background: #e3daf2;
            color: $secondary;
            border: 3px solid transparent;

            .close {
              position: absolute;
              top: 10px;
              right: 15px;
              z-index: 1;

              svg {
                color: $secondary !important;
              }
            }

            .universal-search {
              background: none;
              margin: 0;

              .input-wrapper {
                padding: 0;
                margin: 0;
                min-width: 100% !important;
                top: -10px;

                svg {
                  color: $secondary;
                }

                .MuiInputBase-root {
                  padding-right: 0 !important;
                }

                input::-webkit-input-placeholder {
                  font-style: normal;
                  color: $secondary;
                }
              }
            }
          }
        }

        .game-wrapper {
          .game-placeholder {
            width: 190px;
            height: 190px;
            background: $tertiaryLight;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              color: $secondary;
            }
          }
        }
      }
    }

    &.pdf {
      max-width: calc(min(942px, 95vw));
      max-height: 555px;
    }

    &.text {
      .forms-wrapper {
        display: flex;
        gap: 20px;

        .fields-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }

      .add-images {
        width: 335px;
        max-height: unset;
        margin: 0 auto;
        .dropzone-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .dropzone-inner-wrapper {
            padding: 30px 0 !important;
          }
        }
        .browse {
          width: 210px !important;
        }
      }

      .my-button {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .genres-wrapper {
        display: flex;
        flex-wrap: wrap;

        .genre {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .multiple-genres-input,
        .platform-selection {
          padding-bottom: 0;

          .MuiChip-deletable {
            padding-right: 0 !important;
          }

          .add-icon-wrapper {
            align-self: center;

            .add-icon {
              border: none !important;
            }
          }
        }

        .platform-selection {
          .MuiChip-label {
            padding-right: 20px;
          }

          svg {
            right: 5px;
          }
        }
      }
    }
  }
}
