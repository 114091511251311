@import "assets/core/_variables";

.tutorial-modal {

  place-content: center;
  height: 100%;
  width: 1200px;
  max-width: 80vw;

  .close-icon {
    position: absolute;
    right: 20px;
    top: -20px;
  }

  .item {
    .img-wrapper {
      min-height: 480px;
    }
  }

  .left, .right {
    position: relative;
    width: 60px;
    display: block;
    writing-mode: vertical-lr;

    .icon-wrapper {
      position: absolute;
      top: calc((480px / 2) - 10px);
    }
  }

  .right {
    writing-mode: vertical-rl;
  }
}

.tutorial {

  .tutorial-item {
    max-width: 700px;

    .img-wrapper {

      position: relative;
      cursor: pointer;

      .play {
        position: absolute;
        font-size: 100px;
        color: white;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        filter: drop-shadow(0 0 0.75rem rgba(black, 0.2));
      }

      img {
        display: block;
        width: max-content;
        margin: auto;
        max-width: 100%;
        height: auto;
      }
    }

    span {
      padding-top: 15px;
    }

    .video-container {
      position: relative;
      padding: 0;
      width: fit-content;
      display: flex;
      flex: auto;

      .cover {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: background-color 0.2s linear;

        &:hover {
          background-color: transparentize($primary, 0.8);
        }
      }
    }

  }
}

.app-page-title {

  .tutorial-item {

    span {
      color: white !important;
      opacity: 0.65;
    }

    .video-container {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
