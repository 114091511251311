// Sidebar menu dark color scheme
@import "../variables";

.app-sidebar {

  &--dark {
    .sidebar-navigation {

      .sidebar-menu-box {
        background: rgba($black, .15);
      }

      .sidebar-header {
        color: $secondary;
        font-weight: bold;
        hr {
          margin-right: 20px;
        }
      }

      ul {
        li {
          & > a {
            font-size: 1em;
            transition: none;
            color: $secondary;

            .sidebar-icon {
              & > * {
                transition: none;
                color: $primary;
              }
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, .2);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, .5);
                  }
                }
              }
            }
          }

          .sidebar-icon {
            svg {
              color: $primary;
            }
          }

          .sidebar-item-label {
            font-size: 16px;
            color: $primary;
          }

          .large-item {
            height: 70px;
            border-radius: 0 40px 40px 0 !important;

            &.active hr, &:hover hr {
              display: none;
            }

            hr {
              position: absolute;
              bottom: -15px;
              width: 280px;
              border-top: 1px solid rgba($primary, 0.1);
            }
          }

          & > a.active {
            transition: border-radius 0.2s;
          }

          & > a:hover,
          & > a.active {
            color: $white;
            background: $darkPurple;
            border-radius: 0 20px 20px 0;
            margin-right: 10px;

            .MuiBadge-badge {
              background-color: $secondary;
            }

            .sidebar-item-label {
              color: white;
            }

            .sidebar-icon {
              & > * {
                color: $white;
              }
            }

            .sidebar-icon-indicator {
              opacity: .7;
            }

            .sidebar-icon {
              & > * {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.app-sidebar-collapsed {
  .app-sidebar--dark:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($white, .15);
          }
        }
      }
    }
  }
}
