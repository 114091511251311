@import "assets/core/_variables";

.headquarters {

  width: 100%;
  position: relative;
  background: white;
  overflow-x: hidden;

  .big-circular-progress {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    position: sticky;
    z-index: 1299;
    place-content: center;
    background: $primary;
    width: 100%;

    .MuiCircularProgress-root {
      color: white;
      position: relative;
      top: 2px;
      left: 4px;
    }

    span {
      cursor: pointer;
      color: $lightBlue;
      display: block;
      margin: 10px 20px;
      transition: color 0.25s linear;
      align-self: center;
      font-weight: bold;

      &.selected {
        opacity: 1;
        color: white;
      }
    }
  }

  .header {
    text-align: center;
    background: $primary;
    color: white;
    position: relative;
    z-index: 2;

    form {
      text-align: left;
    }

    .top-bg-content {
      .MuiFormLabel-root, .MuiInputBase-root {
        background: none;
      }
    }

    .large-title {
      z-index: 1;
      font-size: 96px;
      line-height: 80px;
      padding: 10px 0;
    }

    .small-text {
      margin-top: 10px;
      opacity: 0.75;
      font-weight: bold;
      font-size: 17px;
      padding: 15px 30px;
    }

    .header-form-wrapper {
      padding: 26px;

      &.short {
        .input-fields {
          margin: auto;
        }
      }

      .hq-form-wrapper {
        background: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
          padding: 0 15px 0 0;
          margin: 0 !important;
          position: relative;
          height: 50px;
        }
      }

      .form {
        width: 100%;
        margin: auto;
        padding-right: 101px;

        .input-fields {
          justify-content: center;
          position: relative;
          top: 10px;
          .info-text {
            position: absolute;
            top: -25px;
            width: 100%;
            display: flex;
            place-content: center;
            font-size: 14px;
            overflow: visible;
            text-wrap: nowrap;
            .text {
              opacity: 0.6;
            }

            .help {
              opacity: 0.6;
            }
          }
        }

        .platform-field {
          display: flex;
          padding: 5px 30px;
          background: transparentize(white, 0.7);
          border-radius: 0 30px 30px 0;
          gap: 10px;
          .MuiChip-root {
            padding: 20px;
            border-radius: 25px;
            background: none;
            span {
              color: white;
              font-size: 17px;
            }
            &.active {
              background: white;
              span {
                color: $secondary;
                font-weight: bold;
              }
            }
          }
        }

        .genre-field {
          width: 200px;
          background: white;
          border-radius: 30px 0 0 30px;
          padding-right: 10px;
          padding-left: 10px;
          height: 50px;
          display: flex;
          place-content: center;
          justify-content: center;
          margin-bottom: 0;

          span {
            color: $secondary;
            font-weight: bold;
            font-size: 17px;
            text-align: center;
            width: 100%;
          }

          svg {
            color: $secondary;
          }

          .MuiInput-root {
            margin-top: 0;
          }

          .MuiSelect-icon {
            color: $primary;
          }

          .MuiInput-underline:before, .MuiInput-underline:after {
            display: none;
          }

          .MuiFormControl-root > div:before {
            border-bottom-color: white !important;
          }

          .MuiFormLabel-root {
            display: none;
          }
        }

        .universal-search {
          display: inline-flex;
          padding-bottom: 8px;
          flex-grow: 1;
          border-radius: 0 30px 30px 0;
          background: none;
          border: 2px solid white;

          .chips {
            top: 3px;
          }

          input {
            color: white;
          }

          input::-webkit-input-placeholder {
            color: white;
          }

          .MuiInputAdornment-root svg {
            color: white;
          }

          .MuiAutocomplete-inputRoot {

          }

          .MuiInputBase-root {
            top: 4px !important;
          }
        }

      }
    }
  }

  .body {

    overflow-x: hidden;
    background: #131648;
    position: relative;

    .decorations {

      position: absolute;
      top: 300px;
      width: 100%;

      .small-circle {
        background: #5780f7;
        box-shadow: 26px 55px 82px rgba(47, 78, 166, .29), inset 12px 27px 51px rgba(108, 146, 255, .72), inset -35px -38px 105px rgba(56, 67, 208, .76);
        transform: matrix(-.87, -.5, -.5, .87, 0, 0);
        border-radius: 50%;
        display: block;
        position: absolute;
        z-index: 0;
      }

      .square {
        position: absolute;
        border-radius: 18px;
        z-index: 1;
        filter: blur(5px);
        background-color: hsla(0, 0%, 100%, .15);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);

        .inner {
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 2000px hsla(0, 0%, 100%, .15);
          background: #bbb;
          opacity: .2;
        }
      }
    }

    .link-platform {
      color: $secondary;
      display: block;
      text-align: center;
      padding-bottom: 45px;
    }

    & > div {
      margin: auto;
      width: fit-content;
    }

    .moodboard-data {
      width: 100%;
      background: #CBDDF5;

      .gallery-wrapper {
        max-width: 800px;
        width: 100%;
        margin: auto;
        padding-bottom: 15px;
        padding-top: 20px;
      }
    }

    .hq-section {
      padding: 0;
      margin: auto;

      .trend-games-data, .topics-data {
        padding-top: 15px;

        .topics-wrapper .topic-list-expanded .topic-expanded .top-row {
          color: $tertiary;

          .option, .position {
            color: $tertiary;
          }
        }
      }

      .top-charts-data {
        padding-top: 30px;
        .chart-title {
          color: $primary;
        }
        .chart-game {
          span {
            color: $primary !important;
          }

          .MuiChip-label {
            color: white !important;
          }
        }
      }
    }

    .section-title, .subtitle {
      text-align: center;
      display: block;
      color: $primary;
    }

    .section-title {
      font-size: 2.3rem;
      padding-bottom: 10px;
      padding-top: 50px;
      font-weight: bold;
    }

    .top-charts-content {
      .chart-title {
        color: white;
      }

      .chart-game {
        .title, .position {
          color: white;
        }

        .sub-title {
          color: transparentize(white, 0.3);
        }
      }
    }

    .topics-wrapper {
      //max-width: 90%;
      padding-left: 20px;
      margin: auto;
    }

    .MuiTabs-root {
      border-bottom: 1px solid rgba(#3F66A9, 0.5);

      button {
        overflow: visible;

        .tab-hint {
          display: flex;
        }
      }
    }

    .trend-games-data {
      width: 100%;
      overflow: hidden;

      .games-wrapper {
        //width: 1100px !important;
        max-width: 100%;

        .game-card {
          max-width: 100%;
        }
      }

      &.new-releases {

        background: $purpleGradient4;
        position: relative;

        .small-circle {
          &.one {
            width: 200px;
            height: 200px;
            right: calc(50% - 150px - 300px);
            bottom: 50px;
          }

          &.two {
            width: 150px;
            height: 150px;
            right: calc(50% + 150px + 200px);
            top: 100px;
          }
        }

        .square {

          &.one {
            width: 130px;
            height: 130px;
            right: calc(50% + 150px + 300px);
            bottom: -700px;
            transform: rotate(75deg);
          }

          &.two {
            width: 150px;
            height: 150px;
            right: calc(50% - 150px - 400px);
            top: 200px;
            transform: rotate(35deg);
          }

          &.three {
            width: 90px;
            height: 90px;
            right: calc(50% - 150px - 400px);
            top: 320px;
            transform: rotate(75deg);
          }
        }

        .section-title {
          color: white;
        }

        .game-grid {
          z-index: 1;
        }

        .game-card {
          .card-info {
            .name {
              color: white;
            }

            .pre-release {
              color: transparentize(white, 0.35);
              border: 1px solid transparentize(white, 0.35);
            }

            .developer, .alt-text {
              color: transparentize(white, 0.35);
            }
          }
        }

        .games-wrapper {
          width: 800px !important;
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .generated-games-data {
      $size: 2000px;
      $halfSize: calc($size / 2);

      width: 100%;
      background: $primary;
      overflow: visible;
      min-height: calc($halfSize / 1.1);
      z-index: 1;

      .section-title span{
        color: #7F2BEE;
      }

      .circle {
        width: $size * 1.1;
        height: $size;
        background: white;
        position: absolute;
        top: calc($halfSize * -0.7);
        left: calc(50% - $halfSize * 1.1);
        border-radius: 50%;
        box-shadow: inset 0 0 30px 0 transparentize(black, 0.3);
      }

      .small-circle {
        &.one {
          width: 300px;
          height: 300px;
          left: calc(50% - 150px - 900px);
          bottom: -50px;
        }

        &.two {
          width: 150px;
          height: 150px;
          left: calc(50% + 150px + 650px);
          top: 100px;
        }
      }

      .square {

        &.one {
          width: 130px;
          height: 130px;
          left: calc(50% + 150px + 400px);
          bottom: -450px;
          transform: rotate(75deg);
        }

        &.two {
          width: 150px;
          height: 150px;
          left: calc(50% - 150px - 600px);
          top: 100px;
          transform: rotate(35deg);
        }

        &.three {
          width: 90px;
          height: 90px;
          left: calc(50% - 150px - 600px);
          top: 220px;
          transform: rotate(75deg);
        }

      }

      .section-title {
        color: $primary;
        position: relative;
        z-index: 2;
      }

      .link-platform button {
        background: $secondary;
      }

      .link-platform {
        padding-bottom: 25px;
      }

      .generated-game-card {

        &.index-0 {
          .inner-card {
            background: linear-gradient(90deg, #4E9AF6 0%, #5D78F4 100%);
          }
        }

        &.index-1 {
          .inner-card {
            background: linear-gradient(90deg, #5F74F3 0%, #6E52F1 100%);
          }
        }

        &.index-2 {
          .inner-card {
            background: linear-gradient(90deg, #704DF0 0%, $secondary 100%);
          }
        }

        .title {
          color: white;
          background: none;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
        }
        .description span{
          color: white;
        }

        .image-loading-wrapper {
          svg {
            color: white
          }
        }

      }

      .generated-game-card-grid-detail {
        border-radius: 25px;
        padding: 30px;
        background: linear-gradient(135deg, $indigo 0%, $secondary 100%);

        .ludo-score-wrapper {
          border-radius: 25px 0 0 0;
        }

        .subtitle {
          text-align: left;
          color: white;
        }
        .section {
          margin: unset;
        }
      }
    }

    .trends-wrapper-data {

      .MuiTabs-root {
        margin: auto;

        .MuiTab-root {
          color: white !important;
        }

        .Mui-selected {
          color: white;
        }

        .MuiTabs-indicator {
          background: white;
        }

        button {
          margin: auto;
          min-width: fit-content;
        }

        .MuiTooltip-popper {
          text-transform: none;
        }

        .hint svg {
          font-size: 23px !important;
          position: relative;
          color: white !important;
        }

        .MuiTabs-flexContainer {
          width: fit-content;
          margin: auto;
        }
      }
    }

    .chart-games-data, .trends-wrapper-data {
      width: 100%;
      padding: 0 50px;
      position: relative;
      background: white;
    }

    .trends-wrapper-data {
      background: $primary;
      .section-title {
        color: white;
      }
    }

    .topics-data {
      width: 100%;
      overflow: hidden;

      .game-card .card-info .name {
        color: white;
      }

      a button {
        background: $lightBlue;
      }
    }

    .trend-topics-data {
      max-width: 100%;
      background: #78340F;
      overflow: hidden;

      .title, .subtitle {
        color: white;
      }

      .subtitle {
        text-align: left;
        padding-bottom: 25px;
      }

      .content {
        margin: auto;
        color: white;
        max-width: 800px;
        padding: 30px 0;
      }
    }
  }
}
