// Collapsed Sidebar

.app-sidebar:not(.simulate-hover) {
  width: $sidebar-width-collapsed !important;
  position: fixed;
  left: 0;
  display: flex;

  &:hover {
    width: $sidebar-width !important;
  }

  &:hover:not(.app-sidebar--mini) {
    width: $sidebar-width;
    position: fixed;
    box-shadow: $sidebar-shadow;
  }
}

@include respond($not-phone) {
  .app-sidebar-fixed {
    .app-sidebar:not(:hover):not(.simulate-hover) {
      .app-sidebar--content {

        .sidebar-navigation {

          .sidebar-menu-box {
            padding: ($layout-spacer / 6);

            .sidebar-header {
              visibility: hidden;
            }
          }

          .settings-wrapper {
            opacity: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          ul {
            li {

              padding-left: 10px;

              & > a:hover,
              & > a.active {
                text-align: left;
                border-radius: 20px 20px 20px 20px;

                .sidebar-icon {
                  margin-left: 0 !important;
                }
              }

              a {
                font-size: 0;

                .sidebar-icon {
                  margin-left: 0 !important;
                }

                .sidebar-icon-indicator {
                  opacity: 0;
                  visibility: hidden;
                  height: 0;
                  width: 0;
                  overflow: hidden;
                  display: none;
                }

                .sidebar-item-label {
                  opacity: 0;
                  height: 0;
                  overflow: hidden;
                  width: 0;
                  visibility: hidden;

                  .badge {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }

              ul {
                height: 0;
                padding: 0;
                overflow: hidden;
              }
            }
          }
        }

        .app-sidebar-logo {
          span {
            display: none;
          }
        }

        .upgrade-button {
          opacity: 0;
          transition: opacity 0.25s linear;
        }

        .sidebar-header {
          span {
            display: none;
          }

          hr {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .active-project-widget {
          .actions {
            margin-left: 20px;
          }

          .title {
            font-size: 13px;
          }
        }
      }
    }
  }
}


.app-sidebar-fixed {
  .app-content {
    padding-left: $sidebar-width-collapsed !important;
  }

  /*&.expand-content {
    .app-content {
      padding-left: $sidebar-width !important;
    }
  }*/

  &.allow-expand {

    @include media-breakpoint-up(lg) {
      .app-sidebar {
        width: $sidebar-width !important;

        .app-sidebar--content {
          .sidebar-navigation {

            .app-sidebar-logo {
              span {
                display: block;
              }
            }

            .sidebar-header {
              span {
                display: block;
              }

              hr {
                margin-left: 10px;
              }
            }

            ul {
              li {
                padding-left: 0 !important;

                a {

                  &.active {
                    border-radius: 0 20px 20px 0;
                  }

                  .sidebar-item-label {
                    visibility: visible;
                    opacity: 1;
                    height: 22px;
                    width: auto;
                  }
                }

              }
            }
          }
        }
      }
      .app-content {
        padding-left: $sidebar-width !important;
      }
    }
  }
}
