@import "assets/core/_variables";

.digest {

  .digest-content {

    margin-top: 20px;

    form {
      margin: 0 auto 30px auto!important;
      width: 150px;

      .MuiFormLabel-root, .MuiInputBase-root, .MuiSelect-icon {
        color: $primary;
      }

      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-color: $primary;
      }

      .MuiInput-underline:before {
        border-bottom-color: $primary;
      }

      .MuiInput-underline:after {
        border-bottom-color: $primary;
      }

      .sort-icon {
        display: block;
        position: relative;
        top: 16px;
        color: $primary;
      }
    }

    .recommendation {
      max-width: 1200px;
      margin: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      &.i0 {
        .title, .sub-title {
          background: $light;
          padding: 15px;
        }

        .sub-title {
          font-size: 1.2rem;
        }

        .title {
          padding-bottom: 0;
          color: $secondary;
          font-weight: bold;
        }
      }

      .title {
        text-align: center;
        font-size: 2.3rem;
        color: $primary;
      }

      .sub-title {
        text-align: center;
        font-size: 1.5rem;
        color: $primary;
        margin-bottom: 20px;
      }

      .generated-game-card {
        text-align: unset;
        .title {
          text-align: left;
        }
      }
    }
  }
}
