//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto !important;
  padding: 0 !important;
}

.app-wrapper {
  display: flex;
  flex: 1;
  background: $page-bg;
  overflow: hidden;
  transition: all 0.2s $app-sidebar-transition;
}

.app-main {
  /*display: flex;
  min-width: 0;
  flex-direction: column;
  position: relative;
  height: 100%;
  //display: contents;
  //flex: 1 auto;
  //min-height: 100vh;
   */
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.app-content {
  //flex: 1;
  /*display: flex;
  padding: 0;
  flex-direction: column;*/
  transition: all 0.2s $app-sidebar-transition;
  position: relative;
  max-height: 100%;

  &--inner {
    overflow: hidden;
    padding: $layout-spacer;
@include media-breakpoint-down(sm) {
    padding: $layout-spacer / 3;
  }
    display: flex;
    flex: 1;
    height: 100vh;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      overflow: auto!important;
      //§background: $primary;
      >:nth-child(2) {
        //flex-grow: 1;
      }
    }

  }
}
