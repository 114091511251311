@import "assets/core/_variables";

.competitive-analysis {

  .form-wrapper {
    .slider {
      width: 100%;
      flex-shrink: 1;
      min-width: 100px;
    }
  }

  .spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparentize(white, 0.5);
    z-index: 3;
    display: flex;
    place-content: center;
    padding-top: 200px;

    .MuiCircularProgress-colorPrimary {
      color: $lightBlue;
    }

  }

  .modebar-container {
    top: 30px!important;
    right: 50px!important;
    .modebar-group {
      background: transparent!important;

      .modebar-btn {
        display: none;
      }

      [data-title="Autoscale"], [data-title="Download plot as a png"] {
        display: inline;
      }

      .plotlyjsicon {
        display: none;
      }
      path {
        fill: $secondary!important;
      }
    }
  }

  .source-country-form-wrapper {
    width: 100%;
    margin-top: 15px;

    .show-visible-if {
      display: contents;

      &.hide {
        display: block;
        visibility: hidden;
      }
    }

    .description {
      display: none;
    }

    .MuiFormLabel-root, .MuiInputBase-root, .MuiSelect-icon {
      color: $primary;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom-color: $primary;
    }

    .MuiInput-underline:before {
      border-bottom-color: $primary;
    }

    .MuiInput-underline:after {
      border-bottom-color: $primary;
    }

  }

  .rounded-box {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    border-radius: 15px;

    .MuiChip-root {
      width: fit-content;
      font-weight: bold;
      font-size: 18px;
      padding: 0 10px;
      height: unset;
      max-width: 100%;

      &.MuiChip-deletable {
        padding-right: 0;
      }

      .MuiChip-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }

    .keywords {
      display: block;
      padding: 10px 0;
      font-weight: bold;
      font-size: 14px;

      &.min-height {
        min-height: 62px;
      }
    }

    .description {
      display: block;
      padding: 10px 0;
      font-size: 14px;
    }
  }

  .clusters {
    display: flex;
    flex-direction: row;
    padding: 20px 35px;

    .cluster {

      .stats {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .title {
          font-weight: bold;
          display: block;
          font-size: 14px;
        }

        .in-charts {
          padding-right: 10px;

          .blue-number {
            position: relative;
            top: 3px;
            font-size: 20px;
            color: $lightBlue;
            font-weight: bold;
          }
        }

        .highest-ranked {
          flex-grow: 1;

          .bottom-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 5px;

            .big-number {
              background: $primary;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              color: white;
              font-size: 15px;
              font-weight: bold;
              display: flex;
              place-content: center;
              align-items: center;
              margin-right: 10px;
            }

            .game-title {
              color: $lightBlue;
              font-weight: bold;
              cursor: pointer;
              max-width: 150px;
            }

            .date {
              font-weight: bold;
              font-size: 13px;
            }
          }
        }
      }

    }
  }

  .metrics {

    display: flex;
    flex-direction: column;

    .metric {

      .content {
        display: flex;
        flex-direction: row;
        //flex-wrap: nowrap;
        max-width: 100%;

        .plot {
          display: flex;
          flex-direction: column;
          place-content: flex-end;
          width: calc(100% - 590px);
          max-width: 100%;
        }

        .content-left {
          display: flex;
          flex-direction: column;
          padding: 20px 35px;
          gap: 10px;
          min-width: 590px;
          flex-wrap: nowrap;

          .title, .name {
            display: block;
            font-weight: bold;
            margin-bottom: 20px;
            font-size: 21px;
            position: relative;
            .unit {
              font-size: 14px;
              position: absolute;
              top: 12px;
              opacity: 0.7;
            }
          }

          .help {
            font-size: 23px !important;
            margin-left: 0 !important;
          }

          .data-wrapper {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
          }

          .comparison {
            display: flex;
            flex-direction: column;
            //min-width: 370px;
            flex-grow: 1;

            .bar-graph {
              display: flex;
              flex-direction: column;

              .bar-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                &:hover {
                  opacity: 0.75;
                }

                &.active {
                  .filler {
                    visibility: visible;
                  }
                }

                .bar {
                  height: 50px;
                  position: relative;
                  display: flex;
                  align-items: center;

                  .bar-inner {
                    height: 100%;
                  }

                  &.left {
                    flex-flow: row-reverse;

                    .bar-inner {
                      border-radius: 25px 0 0 25px;
                    }
                  }

                  &.right {
                    .bar-inner {
                      height: 100%;
                      border-radius: 0 25px 25px 0;
                    }
                  }
                }

                span {
                  white-space: pre;
                  font-weight: bold;
                  padding-left: 10px;
                }

                .filler {
                  visibility: hidden;
                  border: 2px dashed lightgrey;
                  flex-grow: 1;
                  min-width: 15px;
                  margin-left: 10px;
                }
              }
            }
          }

          .description-box {
            height: fit-content;
            min-height: 250px;
            opacity: 1;

            .description {
              padding-bottom: 0;
            }

            &.inactive {
              opacity: 0;
            }

            .title {
              font-size: 45px;
              font-weight: bold;
              margin-bottom: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.game-icons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  min-height: 44px;

  .see-all {
    display: block;
    margin-top: 5px;
    color: #4E9AF6;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    align-self: center;
  }

  .icon {
    z-index: 1;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    overflow: hidden;
    border: 3px solid white;
    box-sizing: content-box;
    cursor: pointer;

    &.steam {
      img {
        width: auto;
        right: 50%;
        position: relative;
      }
    }

    img {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }
}
