@import "assets/core/_variables";

.trending, .trends {

  .sticky-top {
    z-index: 1299;
  }

  .topics-wrapper {
    .hide {
      display: block;
      visibility: hidden;
    }
  }

  .search-row {
    display: flex;

    .universal-search {
      width: unset !important;
      flex-grow: 1;
    }

    .button-wrapper {
      margin-left: 20px;

      button {
        margin-top: 10px !important;
        height: 40px;
      }
    }
  }

  .trending-content, .trends-content {
    margin-top: 0;
    //pb-4 px-5 pt-0 mt-0
    padding: 0 40px 20px 40px;

    .trending-content-games {

      max-width: 100%;

      .options-bar {
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        row-gap: 5px;
        font-size: 15px;
        min-height: 70px;
        flex-wrap: wrap;

        .toggle-wrapper {
          display: flex;
          flex-direction: row;
          font-weight: bold;
          gap: 10px;
          color: $lightBlue;
          align-items: center;
          .toggle {
            display: flex;
            flex-direction: row;
            span {
              padding: 1px 10px;
              border: 2px solid $lightBlue;
              cursor: pointer;
              &:hover {
                background: transparentize($lightBlue, 0.2);
                color: white;
              }
              &.left {
                border-radius: 20px 0 0 20px;
              }
              &.right {
                border-radius: 0 20px 20px 0;
              }
              &.active {
                border: 2px solid $secondary;
                color: $secondary;
                &:hover {
                  background: transparentize($secondary, 0.2);
                  color: white;
                }
              }
            }
          }
        }
      }

      /*.MuiTabs-root {
        margin-left: 0 !important;
        margin-right: 0 !important;
        height: fit-content;
        //border-bottom: 1px solid rgba($lightBlue, 0.3) !important;

        .MuiCircularProgress-root {
          position: absolute;
          right: 20px;
          bottom: 10px;
        }

        button {
          height: fit-content;
        }

        span {
          font-size: 32px;
        }
      }*/

      .games-wrapper {
        width: 1300px;
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .hr-title {
    padding: 0 50px;
    font-size: 32px !important;
    position: relative;
    top: -5px;

    .MuiCircularProgress-root {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  hr {
    position: relative;
    flex-grow: 1;
  }

  .sorting-form-wrapper {
    width: 250px;
    align-self: center;

    .description {
      display: none;
    }

    .sort-icon {
      display: block;
      position: relative;
      color: $lightBlue;
    }

    .MuiFormLabel-root, .MuiInputBase-root, .MuiSelect-icon {
      color: $secondary;
      svg {
        color: $lightBlue;
      }

      &:before, &:after {
        display: none;
      }
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom-color: $primary;
    }

    .MuiInput-underline:before {
      border-bottom-color: $primary;
    }

    .MuiInput-underline:after {
      border-bottom-color: $primary;
    }

    .MuiInput-root {
      //font-family: 'Bison' !important;
      //font-size: 1.3em;
    }
  }

  .mode-icon {
    display: flex;
    flex-direction: row;

    svg {
      color: $lightBlue;
    }
    span {
      color: $secondary;
      font-weight: bold;
    }

  }

  .innovative-games {

    max-width: 100%;
    margin: auto;

    .game-list {

      display: flex;
      flex-direction: column;
      margin-top: 50px;

      .game {
        cursor: pointer;
        border-radius: 5px;
        padding: 5px 0;
        display: flex;
        flex-direction: row;

        &:hover {
          background: rgba($primary, 0.1);
        }

        .position {
          width: 50px;
          text-align: center;
        }

        img {
          margin-right: 15px;
        }

        .title {
          width: 50%;
        }

        .info, .source-icon {
          position: relative;
          top: 3px;
        }

        .info {
          font-size: 13px;
          font-weight: bold;

          &.right {
            text-align: right;
            width: 15%;
            padding-right: 5px;
          }

          text-align: center;
          width: 20%;

        }
      }
    }
  }
}

.topics-wrapper {
  position: relative;

  .no-results {
    position: relative;
    left: -40px;
    bottom: -20px;
  }

  .topic-list-expanded {
    display: flex;
    flex-direction: column;

    .topic-expanded {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      position: relative;

      &.locked {
        .keywords {
          display: flex;
          align-items: flex-end;

          b, span {
            width: 100px;
            background: $secondary;
            display: inline-flex;
            height: 30px;
            opacity: 0.5 !important;
            margin-right: 10px;
          }

          span {
            width: 70px;
            height: 20px;
          }
        }
      }

      .game-card {
        &.locked {
          margin-top: 15px;
        }
      }

      .top-row {
        color: $secondary;
        overflow: visible;
        position: relative;
        left: -25px;
        width: calc(100% + 25px);
        flex-wrap: nowrap;

        .position {
          cursor: pointer;
          font-size: 32px;
          line-height: 32px;
          margin-top: 6px;
          width: fit-content;
          text-align: right;
          font-weight: bold;
          display: inline-block;
          color: $secondary;
        }

        .more-options {
          color: $secondary;
          font-weight: bold;
          flex-grow: 1;
          text-align: right;
          gap: 15px;
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          align-self: flex-end;

          .option {
            cursor: pointer;
            display: flex;
            align-items: flex-end;
            gap: 5px;

            .MuiSvgIcon-root {
              font-size: 20px;
            }
          }

        }

        .keywords {
          padding-left: 10px;
          display: flex;
          align-items: flex-end;
          cursor: pointer;
          flex-wrap: wrap;
          row-gap: 10px;

          .MuiChip-root {
            cursor: inherit;
          }

          .big span {
            font-size: 24px;
            //height: 30px;
            /*line-height: 30px;*/
          }

          .small {
            background: $lightBlue;
          }

          b {
            font-size: 30px;
            text-transform: capitalize;
          }

          span {
            font-size: 16px;
            font-weight: bold;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
      }

      .bottom-row {
        min-height: 269px;
        padding-top: 15px;

        .game-card {
          margin-bottom: 0;
        }
      }
    }
  }

  .topic-list {

    background: $light;

    .topic {
      display: flex;
      cursor: pointer;

      &:hover {
        background: #C8DCF6;
        border-radius: 5px;
      }

      .position {
        width: 30px;
        text-align: center;
        position: relative;
        top: 5px;
      }

      .keywords {
        position: relative;
        top: 5px;
        width: 50%;
        display: flex;

        .filler {
          position: relative;
          flex-grow: 1;
          border-bottom: 1px dashed rgba($primary, 0.15);
          height: 18px;
          margin: 0 3px;
        }
      }

      .bar {
        flex-grow: 1;
        width: 200px;
        display: flex;
        flex-direction: row;

        .bar-wrapper {

          &.colored {

            hr {
              border-top: 8px solid $primary;
            }

            &.negative {
              transform: scaleX(-1);

              hr {
                border-top: 8px solid $red;
              }
            }
          }

          hr {
            position: relative;
            top: -2px;
            float: left;
            border-top: 0;
          }
        }
      }
    }
  }
}
