@import "assets/core/_variables";

.details-panel {

  &.developer {

    .loading {
      padding-top: 100px;
    }

    .developer-details {

      .info {
        padding: 0 15px 15px 15px;
        margin: 10px;

        .info-content {
          display: flex;
          flex-direction: column;

          .section-title {
            font-size: 23px;
          }
        }

        .sorting-form-wrapper {
          width: 200px;
          color: white;
          margin: 0 auto 30px auto;

          .sort-icon {
            display: block;
            position: relative;
            //top: 16px;
          }

          .MuiInput-underline:before, .MuiInput-underline:after {
            display: none;
          }

          svg {
            color: $lightBlue;
          }

          .MuiSelect-root {
            text-align: center;
          }

          .MuiInput-root {
            color: $secondary;
          }
        }

        .MuiPagination-ul {
          place-content: center;
          margin-right: 10px;
        }

        .MuiPaginationItem-root {
          color: white;
        }

        .Mui-selected {
          background-color: $secondary;
        }

        .game-card {
          max-width: 170px;
          margin: 20px 10px;

          .cover-image {
            height: 150px;
          }

          .card-info .name {
            color: white;
          }
        }
      }

      .statistics {
        background: #173E83;
        font-size: 13px;
        padding: 20px;
      }

      .header {
        min-height: 220px;
        position: static;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0 10px;

        .developer-title {
          margin-top: 90px;
          font-size: 40px;
          position: relative;
          line-height: 40px;
          padding-bottom: 20px;

          .developer-header {
            font-weight: bold;
            position: absolute;
            font-size: 14px;
            color: white;
            opacity: 0.7;
            top: -35px;
          }
        }

      }
    }
  }
}
