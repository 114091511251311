@import "assets/core/_variables";

.export-project-header {
  padding-left: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1;

  .icon {
    min-width: 150px;
    width: 150px;
    height: 150px;
    background: #f0eff3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;

    .icon-image {
      width: 150px;
      height: 150px;
      background-size: cover;
    }

    img {
      height: 100%;
    }

    svg {
      font-size: 40px;
      color: #cdcdd9;
    }
  }

  .description {
    font-size: 28px;
    line-height: 30px;
    flex-grow: 1;
  }
}

.export-project {
  display: flex;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  .action-bar {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 30px;
    font-size: 14px;

    .disabled {
      opacity: 0.5;
      cursor: pointer;
      span {
        pointer-events: none;
      }
    }

    span {
      cursor: pointer;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 5px;
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }

    svg {
      height: 20px;
    }

    .MuiCircularProgress-root svg {
      height: unset;
    }
  }

  .left-column {
    display: flex;
    flex-direction: column;
    background: $indigo;
    color: white;
    min-width: calc(min(100vw, 500px));

    .unity-plugin-info {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
      width: min-content;
      align-self: center;

      .main-title {
        font-size: 32px;
        font-weight: bold;
      }
      .sub-title {
        font-size: 16px;
        opacity: 0.7;
      }

      .plugin-actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .action-bar {
      svg {
        color: white;
      }
    }

    .items {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .no-code {
        display: flex;
        flex-direction: column;
        max-width: 320px;
        align-self: center;
        text-align: center;
        gap: 30px;
        padding: 170px 0;

        .main-title {
          font-size: 32px;
          font-weight: bold;
        }
        .sub-title {
        }

        button {
          margin: auto;
        }
      }

      .item {
        display: flex;
        gap: 10px;
        cursor: pointer;
        align-items: center;

        .description,
        svg {
          opacity: 0.75;
        }

        .help {
          color: $tertiary !important;
          margin: 0 !important;
          position: relative;
        }

        .pointer {
          cursor: pointer !important;
        }

        &:hover {
          .description,
          svg {
            opacity: 0.9;
          }
          .help,
          .pointer {
            opacity: 1 !important;
            svg {
              opacity: 1 !important;
            }
          }
        }

        &.disabled {
          .description,
          svg {
            opacity: 0.75;
            //cursor: default;
          }
        }

        &.active {
          .description,
          svg {
            opacity: 1;
            font-weight: bold;
          }
        }
      }
    }

    .action-bar {
      background: #424ac7;
    }
  }

  .right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: #f0f4ff;
    color: #424ac7;
    overflow: auto;

    &.no-code {
      min-height: 600px;
      background: #f0f4ff url("/assets/images/background/code-bg.png") no-repeat
        30px 30px;
    }

    .script {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-grow: 1;
      code {
        font-family: Monaco, Courier, monospace !important;
        font-size: 13px;
        * {
          font-family: Monaco, Courier, monospace !important;
          font-size: 13px;
        }
      }
    }

    .action-bar {
      background: #d8e2ff;
    }
  }
}
