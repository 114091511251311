@import "assets/core/_variables";

.search {

  .see-all {
    cursor: pointer;
    color: $lightBlue;
    font-weight: bold;
    margin: 30px 0 10px 50px;
    display: block;
  }

  .results-wrapper {
    padding: 0 20px;

    &.games-results {
      min-height: 336px;
      padding-top: 20px;
    }

    &.icon-results {
      min-height: 187px;
    }

    &.screenshot-results {
      min-height: 505px;
    }

    .top-bar {
      display: flex;
      flex-direction: row;
      margin: 0 40px 20px 40px;
      font-weight: bold;

      .small-title {
        font-weight: bold;
        display: block;
        font-size: 18px;
        flex-grow: 1;
        text-transform: capitalize;
      }

      .action {
        cursor: pointer;
        color: $lightBlue;
      }
    }

    .icon-carousel, .screenshot-carousel {
      margin: 0 40px;
    }

    .icon-carousel {
      min-height: 100px;
      .image-wrapper {
        width: 100px;
      }
      .button-right, .button-left {
        top: 20px;
      }
    }

    .screenshot-carousel {
      .image-wrapper {
        width: 200px;
      }
    }

    .image-wrapper {
      width: inherit;
      .my-image {
        width: inherit;
        img {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .submit-button-wrapper {
    margin-top: 15px;
  }
}
