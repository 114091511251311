@import "assets/core/_variables";

.game-concept {
  &.gdd-menu-open {
    .counters-bar {
      right: 455px;
    }
  }

  .top-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba($secondary, 0.5); // Semi-transparent background
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 50px;
    backdrop-filter: blur(10px); // Gaussian blur effect
    -webkit-backdrop-filter: blur(10px); // For Safari support

    span {
      max-width: 750px;
      text-align: center;
    }

    svg {
      font-size: 100px;
      color: white;
    }
  }

  .app-content--inner__wrapper {
    //overflow: hidden!important;
  }

  .show-preview {
    display: none;
  }

  .sticky-top {
    z-index: 1299;
  }

  .ai-chat-icon {
    place-self: center;

    .icon-wrapper {
      cursor: pointer;
      background: $secondary;
      color: white;
      padding: 5px 10px;
      border-radius: 10px;
      display: flex;
      gap: 10px;
      align-items: center;

      span {
        font-weight: bold;
        font-size: 16px;
      }
    }

    &.gradient {
      .icon-wrapper {
        background: $purpleGradient;
      }
    }

    &.large .icon-wrapper {
      padding: 10px 15px;
    }
  }

  .hover-options {
    display: none;
    position: absolute;
    top: -55px;
    left: -20px;
    padding: 15px;
    width: fit-content;

    .white-shadow {
      position: absolute;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      box-shadow: 0 30px 50px 20px rgba(255, 255, 255, 1);
    }

    .MuiChip-root {
      z-index: 1;
      margin-left: 5px;
      margin-top: 2px;
      background: $secondary;
      font-weight: bold;
      box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
      border-radius: 8px;

      .MuiChip-label {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .gdd-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    //overflow-y: scroll;


    .options-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      padding: 0 40px 40px 40px;
      flex: none;

      .empty-state-heading {
        text-align: center;
        width: 100%;
        padding: 3rem 1rem 1rem;

        h1 {
          font-size: 2.75rem;
          font-weight: bold;
          margin-bottom: 1rem;
          background: $purpleGradient;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          display: inline-block;
        }

        p {
          font-size: 1.4rem;
          color: $secondary;
          opacity: 0.85;
          max-width: 800px;
          margin: 0 auto;
        }
      }

      .option-card {
        flex: 1;
        min-width: 300px;
        max-width: 450px;
        width: 100%;
        background: $primary;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.3s ease;
        cursor: pointer;
        border: 3px solid $secondary;
        overflow: hidden;
        padding: 0;

        &:hover {
          background: lighten($primary, 5%);
          transform: translateY(-5px);
        }

        .option-image {
          width: 100%;
          height: 220px;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;
          }
        }

        .option-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 1.5rem 2rem 2rem;
          min-height: 220px;
          justify-content: space-between;

          h4 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
            color: white;
          }

          p {
            font-size: 1.1rem;
            opacity: 0.8;
            margin-bottom: 1.5rem;
            color: white;
          }

          .option-button {
            align-self: center;
            width: 100%;
            background: $purpleGradient;
            border: none;
            border-radius: 25px;
            color: white;
            padding: 0.7rem 0;
            font-size: 1.1rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .toc {
      position: fixed;
      top: 0;
      z-index: 5000000;
      height: 100vh;
      display: flex;
      pointer-events: none;
      width: 100%;

      .toc-indicator {
        border-radius: 20.5px;
        border: 2px solid transparentize($indigo, 0.87);
        background: white;
        align-self: flex-end;
        justify-content: center;
        pointer-events: auto;
        position: absolute;
        bottom: 10px;
        left: calc(50% - 120px);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
        padding: 5px 20px;
        font-weight: bold;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .toc-content {
        height: fit-content;
        background: white;
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        pointer-events: auto;
        border-radius: 0 25px 0 0;
        border-right: 2px solid transparentize($indigo, 0.87);
        border-top: 2px solid transparentize($indigo, 0.87);
        padding: 15px 20px;
        cursor: pointer;
        transition: all 0.3s linear;
        color: $indigo;
        width: fit-content;

        &:hover,
        &.force-open {
          .collapsed-icon {
            display: none;
          }
          .toc-items,
          .small-title {
            display: flex;
          }
        }

        .collapsed-icon {
          display: flex;
        }

        .small-title {
          display: none;
          font-weight: bold;
          font-size: 20px;
          margin-left: 5px;
          padding-top: 10px;
        }

        .toc-items {
          display: none;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 0;
          padding: 15px 15px 15px 5px;
          list-style: none;
          color: $indigo;

          li {
            display: flex;
            align-items: center;
            gap: 15px;
            span {
              flex-grow: 1;
            }
            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }

    .concepts-menu {
      width: 330px;
      max-width: 100vw;
      background: $indigo;
      position: absolute;
      top: 66px;
      right: 0;
      z-index: 11;
      overflow: auto;
      height: calc(100% - 66px);

      .concept-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        padding: 20px 30px;

        .new-concept {
          button {
            display: flex;
            justify-content: left;
            .label {
              flex-grow: 1;
              font-weight: bold;
            }
          }
        }

        .new-concept,
        .button-wrapper,
        button {
          width: 100%;
        }

        .concept {
          color: white;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          place-content: center;
          max-width: 100%;
          align-items: center;
          gap: 10px;
          opacity: 0.6;

          .lock-icon {
            padding-left: 5px;
            color: white;
            svg {
              opacity: 1 !important;
            }
          }

          &.active {
            color: white;
            opacity: 1;
            font-weight: bold;
          }

          &:hover,
          &.active {
            button {
              visibility: visible;
            }
          }

          &:hover {
            opacity: 1;
            svg {
              opacity: 1;
            }
          }

          .icon,
          .icon-placeholder {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 10px;
            border: 2px solid $secondary;
          }

          .icon-placeholder {
            background: $light;
            padding: 5px;
            svg {
              opacity: 1;
              color: transparentize($dark, 0.1);
              width: 100%;
              height: 100%;
            }
          }

          button {
            transition: visibility 0s;
            visibility: hidden;
            width: 30px;
            height: 30px;
            position: relative;
            top: -2px;

            svg {
              color: $lightBlue;
            }
          }
        }
      }
    }

    .dropzone-wrapper {
      margin-top: 0 !important;
      width: 100%;
      height: 100%;
      opacity: 1;

      .dropzone {
        width: 100%;
        height: 100%;

        .dropzone-upload-wrapper {
          width: 100%;
          height: 100%;

          .dropzone-inner-wrapper {
            position: relative;
            scroll-margin-top: 50px;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            padding: 0;
            white-space: pre-line;
            cursor: pointer;
            background: transparentize($darkPurple, 0.3) !important;
            opacity: 1;
            color: white;
            border: 3px solid transparent;

            .instructions {
              span {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }

    .content-wrapper {
      margin-top: 0;
    }

    .fa-icon {
      display: flex;
      place-content: center;
      width: 35px;
      height: 35px;
      border: none;
      align-items: center;

      svg {
        color: $secondary;
        font-size: 20px;
      }

      &.round-border {
        border-radius: 50%;
        border: 5px solid $secondary;
        cursor: pointer;
        position: relative;
      }

      &.text-white {
        svg {
          color: white;
        }
      }
    }

    .game-grid {
      z-index: 1;
      margin-left: 10px;
      .game-grid-item {
        align-self: flex-start;
      }
    }

    .game-grid > div {
      justify-content: left;
    }

    .section-wrapper {
      width: 100%;
      container-type: inline-size;

      &.loading {
        .section-content-wrapper {
          cursor: default;
        }

        .loading-wrapper {
          width: 100%;
          height: 100%;
          background: white;
          z-index: 100001;
          opacity: 0.5;
          position: absolute;
          display: flex;
          align-items: center;
          place-content: center;

          button {
            position: absolute;
          }
        }
        .gdd-section-actions {
          display: none !important;
        }
      }

      .image-menu-wrapper {
        width: fit-content;
        //margin: 0 20px 5px 20px;
        margin: 0;
        position: relative;

        &.has-image {
          //max-width: 260px;
          width: 100%;
          margin: 0;
          padding: 0;
          //width: fit-content;
          border-radius: 14px;

          .generated-image {
            margin: auto;

            &.portrait {
              min-width: 255px;
            }
          }
        }

        .element-add-image {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;

          .dropzone-inner-wrapper {
            //background: transparentize(#E3DAF2, 0.5);
          }
        }

        &.selected {
          .element-add-image {
            display: block;
          }

          .add-image {
            svg {
              display: none;
            }
          }
        }
      }

      .icons-wrapper {
        background: white;
        padding: 10px 0 10px 0;
        height: 50px;
        position: relative;
        display: flex;
        cursor: pointer;

        .suggestions-wrapper {
          display: none;
          gap: 5px;
          align-items: center;
          margin-left: 40px;
          position: relative;

          .my-button,
          .description {
            margin-top: 0 !important;
            span {
              font-size: 14px;
              white-space: nowrap !important;
            }
          }
        }

        &:hover,
        &.active {
          background: $light;
        }

        &:hover,
        &.active,
        &.hover {
          .fa-icon {
            z-index: 1;
            //background: $light;
            display: flex;
          }
          .suggestions-wrapper {
            display: flex;
          }
        }

        .round-border {
          margin-left: 50px !important;
        }

        hr {
          position: absolute;
          top: 14px;
          border-top: 2px dashed rgba($primary, 0.1);
          width: 100%;
        }

        .fa-icon {
          display: none;
          left: 35px;
          position: relative;
        }

        &.active {
          .angles {
            display: flex;
          }
          .suggestions-wrapper {
            display: none;
          }
        }
      }

      .angles {
        display: none;

        @keyframes color-change {
          0% {
            color: transparentize($secondary, 0.5);
          }
          50% {
            color: $secondary;
          }
          100% {
            color: transparentize($secondary, 0.5);
          }
        }

        .delay-0,
        .delay-0 svg {
          animation-delay: 0s !important;
        }

        .delay-1,
        .delay-1 svg {
          animation-delay: 0.35s !important;
        }

        .delay-2,
        .delay-2 svg {
          animation-delay: 0.7s !important;
        }

        .delay-3,
        .delay-3 svg {
          animation-delay: 1.05s !important;
        }

        .fa-icon {
          width: 17px;
          position: relative;

          svg {
            animation: color-change calc(var(--animate-duration) * 3) infinite;
            font-size: 40px;
          }
        }
      }
    }

    .section-content-wrapper {
      cursor: pointer;
      position: relative;

      &.comments-active {
        .section {
          width: calc(100% - 350px);
        }
      }

      &.active {
        cursor: default;
      }

      &.active,
      &:hover {
        background: $light;

        .gdd-section-actions {
          display: flex;
        }
      }

      .gdd-section-actions {
        position: absolute;
        display: none;
        top: 20px;
        right: 35px;
        z-index: 100000;
        align-items: center;

        .action {
          color: $secondary;
          margin: 0 5px;
          cursor: pointer;

          &.ai-chat-icon,
          &.score-icon {
            place-self: center;

            .icon-wrapper {
              background: $secondary;
              color: white;
              padding: 5px 10px;
              border-radius: 10px;
              display: flex;
              gap: 10px;
              align-items: center;

              span {
                font-weight: bold;
                font-size: 16px;
              }
            }
          }

          &.score-icon {
            width: 210px;
          }

          .select-field-wrapper {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $secondary;

            .MuiFormLabel-root {
              display: none;
            }

            #chip-select-label {
              color: $secondary;
            }

            svg {
              color: $secondary;
            }

            .MuiInput-root {
              &:before,
              &:after {
                display: none;
              }
            }

            .MuiSelect-root {
              color: $secondary;
              font-weight: bold;
              width: fit-content;
            }
          }
        }
      }
    }

    .section {
      position: relative;
      transition: border 0.2s linear;
      padding: 90px 50px 70px 50px;
      max-width: 1920px;
      min-width: 450px;
      display: flex;
      gap: 40px;

      .left-content {
        display: flex;
        flex-direction: column;
        width: 150px;
        min-width: 150px;

        .description {
          font-size: 20px;
          color: $indigo;
          opacity: 0.65;
          border: 3px solid transparent;
          text-align: right;
        }

        .section-subtitle {
          font-size: 24px;
          line-height: 26px;
          position: relative;
          background: $purpleGradient;
          background-clip: text;
          -webkit-background-clip: text;
          font-weight: bold;
          color: transparent;
          display: inline-block;
          text-align: right;
          margin-top: 14px;

          &.editable {
            margin-top: 0 !important;
          }
        }
      }

      .right-content {
        flex-grow: 1;
      }

      .gallery-wrapper {
        .image-menu-wrapper {
          margin: 0;
          padding: 0;
          max-width: unset;
          max-height: unset;
          box-sizing: border-box;
          border: 3px solid transparent !important;

          .hover-options {
            left: -10px;
          }

          .my-image {
            padding: 0;
            top: -3px;
          }

          img {
            padding: 5px 2px;
          }

          &:hover {
            border: 3px solid $secondary !important;
          }
        }
      }

      &.ludo-score {
        .ludo-score-wrapper {
          .score-line {
            display: flex;
            align-items: center;
            gap: 10px;

            .score-number {
              font-size: 50px;
              color: $secondary;
              font-weight: bold;
            }

            img {
              width: 40px;
              height: 40px;
            }
          }

          .ludo-score-details {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .game-icons {
              .see-all {
                font-size: 20px;
              }
            }

            .metric {
              display: flex;
              flex-direction: column;
              gap: 5px;

              span {
                font-size: 12px;
              }

              .metric-title {
                font-weight: bold;
                font-size: 18px;
                color: $secondary;
              }

              .MuiLinearProgress-colorPrimary {
                background-color: $tertiaryLight;
              }

              .MuiLinearProgress-barColorPrimary {
                background-color: $secondary;
              }
            }
          }
        }
      }

      &.game-elements {
        .game-elements-content {
          margin: 0 !important;

          .rounded {
            border-radius: 5px !important;
          }

          .main-element-wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .image-menu-wrapper {
              align-self: center;
            }
          }
        }
      }

      &.game-summary {
        display: flex;
        flex-direction: column;
      }

      .section-content {
        max-width: 1200px;
      }

      .large {
        .hover-options {
          top: -55px;
        }
      }

      &.active {
        z-index: 10000;
        cursor: default;
        //margin-right: 460px;

        .menu-wrapper:hover {
          cursor: default;
          border: 3px solid $secondary;
        }
      }

      .editable {
        z-index: 1;
      }

      .menu-wrapper {
        border: 3px solid transparent;
        z-index: 1;
      }

      &.active .menu-wrapper {
        cursor: text;
        border-radius: 8px;
        position: relative;
      }

      .editable {
        position: relative;
        box-sizing: border-box;
        transition: border 0.1s linear;
        border-radius: 10px;
        font-size: 16px;
        padding: 3px;

        //color: $primary;

        &.section-subtitle {
          font-size: 24px;
          line-height: 26px;
          position: relative;
          background: $purpleGradient;
          background-clip: text;
          -webkit-background-clip: text;
          font-weight: bold;
          color: transparent;
          display: inline-block;
          text-align: right;

          .show-preview {
            color: transparent;
          }

          &.selected {
            color: $lightBlue;
          }
        }

        &.large {
          font-size: 32px;
          font-weight: bold;
        }
      }

      [contenteditable="true"]:empty:before {
        opacity: 0.3;
      }

      &.assets {
        .right-content {
          gap: 15px;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .three-d-assets {
          justify-content: flex-start !important;
          padding: 0 !important;
          .menu-wrapper {
            width: fit-content;
            border-radius: 17px;
          }
          .three-d-asset {
            max-width: 350px;
            max-height: 350px;
          }
        }
      }

      &.moodboard {
        .my-image {
          .remove-icon {
            display: none;
          }

          &:hover {
            .remove-icon {
              display: block;
            }
          }
        }
      }

      &.game-inspiration {
        padding-bottom: 40px;

        .add-game {
          position: relative;
          width: 225px;
          height: 180px;
          margin: 13px;
          z-index: 2;

          .new {
            min-width: 100%;
            width: 100%;

            display: flex;
            place-content: center;
            align-items: center;

            .plus {
              color: $secondary;
              font-size: 40px;
            }
          }

          .game-grid-item {
            align-self: flex-start;
          }

          .game-element {
            height: 100%;
            margin: 0;
            justify-content: center;
            white-space: pre-wrap;
            cursor: pointer;
            border-radius: 8px;
            background: #e3daf2;
            color: $secondary;
            border: 3px solid transparent;

            .close {
              position: absolute;
              top: 10px;
              right: 15px;
              z-index: 1;

              svg {
                color: $secondary !important;
              }
            }

            .universal-search {
              background: none;
              margin: 0;

              .input-wrapper {
                padding: 0;
                margin: 0;
                min-width: 100% !important;
                top: -10px;

                svg {
                  color: $secondary;
                }

                .MuiInputBase-root {
                  padding-right: 0 !important;
                }

                input::-webkit-input-placeholder {
                  font-style: normal;
                  color: $secondary;
                }
              }
            }
          }
        }

        .game-card {
          .remove-icon {
            display: none;
          }

          &:hover {
            .remove-icon {
              display: block;
            }
          }
        }
      }

      &.active {
        .game-element {
          &:hover {
            .action {
              display: block;
            }
          }
        }
      }

      &.hover-image {
        .dropdown-image {
          display: none !important;
        }
      }

      .game-element-new {
        color: $secondary;
        font-size: 20px;
        font-weight: bold;
        align-items: center;
        gap: 10px;
        margin: 12px;

        .MuiSvgIcon-root {
          margin: 0 !important;
        }
      }

      .game-element {
        position: relative;
        scroll-margin-top: 50px;
        padding: 0;
        height: fit-content;
        margin: 3px 0;
        border-radius: 8px;
        color: #3b3e66;
        white-space: pre-wrap;
        cursor: pointer;
        border: 3px solid transparent;
        box-sizing: border-box;
        width: 315px;
        min-width: 315px;

        &.hover-image {
          .dropzone-wrapper {
            display: none !important;
          }
        }

        &.large {
          width: 100%;
          min-width: unset;

          .gallery-wrapper {
          }

          .image-menu-wrapper {
            margin: 0 0 20px 0;

            .add-image {
              cursor: pointer;
              width: 150px;
              height: 150px;
              margin-left: 4px;
            }
          }

          .content-editable-wrapper {
            padding: 10px;
          }
        }

        .editable {
          cursor: pointer;
          width: 100%;
          font-size: 16px;

          .content-editable-wrapper {
            padding: 5px;
          }
        }

        .action {
          display: none;
          position: absolute;
          right: 15px;
          top: 5px;
          cursor: pointer;
          z-index: 10;
        }

        &.new {
          cursor: pointer !important;
          background: none;
          border: 3px solid $secondary;
          //transition: background 0.2s linear;
          padding: 12px;

          //background: #E3DAF2;
          color: $primary;

          .MuiAutocomplete-endAdornment {
            display: none;
          }
        }
      }

      &.layout-text {
        .image-menu-wrapper,
        .gallery-wrapper,
        .add-image {
          display: none;
        }
      }

      .gallery-wrapper div {
        padding-left: 1px;
      }

      .my-image {
        &.placeholder {
          opacity: 0.2;
        }
      }

      .placeholder {
        .chip {
          opacity: 0.2;
        }
      }

      .content {
        margin-bottom: 30px;

        &.placeholder {
          .game-grid {
            opacity: 0.2;

            .game-card,
            .game-card .description {
              &:hover {
                cursor: default;
                background: none;
              }
            }
          }
        }
      }

      &.game-summary {
        .large {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .content-editable-wrapper {
            min-width: 500px;
            max-width: calc(100% - 55px);
            margin-right: 5px;
            width: 100%;
            line-height: 45px;
          }
        }
      }

      @container (max-width: 950px) {
        flex-direction: column;
        .left-content {
          width: 100%;
          .description,
          .section-subtitle {
            text-align: left;
          }
        }
      }
    }

    .add-image {
      width: 150px;
      height: 150px;
      background: #e3daf2;
      border-radius: 8px;
      display: flex;
      place-content: center;
      align-items: center;

      svg {
        color: $secondary;
        font-size: 40px;
      }

      .dropzone-wrapper {
        svg {
          color: white;
        }
      }
    }

    .active {
      .menu-wrapper.selected,
      .editable.selected {
        border: 3px solid $secondary !important;
        background: $light;

        .hover-options {
          display: flex;
        }

        &.game-element,
        &.editable {
          background: #ede6f8;
        }

        &.game-element {
          color: $secondary;
        }
      }
    }
  }

  .comments {
    z-index: 10;
    position: absolute;
    text-align: left;
    overflow: visible;
    width: 350px;
    right: 20px;
    top: 50px;

    .close-icon {
      position: relative;
      top: 2px;
    }

    .comments-inner {
      width: 250px;
      height: 100%;
      overflow-y: auto;
      margin-left: 20px;
      padding: 0 15px;

      .comments-title {
        text-transform: capitalize;
      }
    }

    hr {
      border-top-width: 1px;
      opacity: 0.2;
    }

    .add-comment {
      cursor: pointer;
      padding: 40px;
      position: absolute;
    }

    .comments-list {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
    }

    .comment,
    .main-comment {
      display: contents;

      .author {
        width: calc(100% - 60px);
      }

      .date {
        font-size: 12px;
        margin: 5px 0;
      }

      .text {
        font-size: 15px;
      }
    }

    .comment {
      .author {
        width: 100%;
      }
    }

    .main-comment {
      border: 1px solid $light;
      background: white;
      padding: 20px;
      color: $primary;
      border-radius: 8px;
      margin: 5px 0;
      display: flex;
      flex-direction: column;
      text-align: left;
      max-height: fit-content;
      transition: all 0.3s;
      position: relative;

      .text-primary svg {
        color: $primary;
      }

      .text-secondary svg {
        color: $secondary;
      }

      &.expanded {
        cursor: unset;
      }

      .comment-actions {
        position: absolute;
        top: 15px;
        right: 10px;
        display: flex;
        flex-direction: row;

        svg {
          cursor: pointer;
        }
      }

      .expand {
        cursor: pointer;
        font-size: 14px;
        position: relative;
        left: -5px;

        span {
          display: flex;
        }

        button {
          position: relative;
        }
      }

      .reply-wrapper {
        flex-direction: row;
        align-items: flex-end;

        button {
          display: contents;
        }
      }
    }
  }

  .gdd-page-wrapper {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    height: 100%;
  }

  .gdd-page {
    overflow-x: hidden;
    overflow-y: auto;

    &.has-header-image {
      .review-bar {
        .MuiInputBase-root {
          &:before,
          &:after {
            border-bottom: 1px solid rgba(255, 255, 255, 0.42);
            color: white !important;
          }
        }

        span,
        svg {
          color: white !important;
        }

        .MuiPaper-root {
          span {
            color: $primary !important;
          }
        }
      }
    }

    &.panel-open {
      .app-page-title {
        width: calc(100% - 445px);
      }

      .gdd {
        width: calc(100% - 455px);
        z-index: 1;
      }
    }
  }

  .gdd-content {
    container-type: inline-size;
    container-name: gdd-content;

    &.export-project,
    &.game-guide,
    &.analysis {
      .toc {
        display: none;
      }
      .main-header {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    @container gdd-content (max-width: 600px) {
      .main-header {
        flex-direction: column;
        padding: 20px 10px;
        width: 100%;
        margin: auto;
        .description {
          text-align: center;
        }
      }
    }

    &.analysis {
      .gdd-top-bar-wrapper {
        .top-sections {
          .right-section {
            display: none;
          }
        }
      }
      .competitive-analysis {
        container-type: inline-size;
        container-name: competitive-analysis;

        background: #f8f7fb;

        .js-plotly-plot .plotly .main-svg {
          background: unset !important;
        }

        .form-wrapper,
        .app-page-title,
        .clusters,
        .loading-tip {
          display: none;
        }

        .source-country-form-wrapper {
          margin-top: 30px !important;

          .MuiFormLabel-root {
            color: rgba($primary, 0.5);
          }
          .MuiSelect-root {
            color: #4f80ff;
            font-weight: bold;
          }
          .MuiInput-underline {
            &::after,
            &::before {
              border-bottom: 2px solid rgba(#4f80ff, 0.15);
            }
            svg {
              color: #4f80ff;
            }
          }
        }

        .metrics {
          padding: 0 40px;
          margin-top: 30px;
          margin-bottom: 30px;
          max-width: 1920px;

          display: flex;
          flex-direction: column;
          gap: 1rem;

          .additional-title {
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            padding: 10px 0;
            display: block;
          }

          .metric {
            background: white;
            border-radius: 35px;

            .title {
              display: none;
            }

            .header {
              display: flex;
              margin: 20px 30px 5px 30px;
              padding: 0;
              border-radius: 15px 15px 0 0;
              background: white;
              align-items: center;
              gap: 20px;

              .value {
                position: relative;
              }

              .name,
              .value {
                background: rgb(162, 104, 238);
                background: linear-gradient(
                  81deg,
                  $lightBlue 0%,
                  $secondary 100%
                );
                background-clip: text;
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                .hint-wrapper,
                .hint-wrapper span {
                  color: white !important;
                }

                .unit {
                  position: absolute;
                  top: 12px;
                  right: -25px;
                  font-size: 13px;
                  background-clip: unset;
                  -webkit-background-clip: unset;
                  -webkit-text-fill-color: rgba($primary, 0.3) !important;
                }
              }

              .name {
                display: flex;
                margin: 0;
                gap: 15px;
                .hint-wrapper {
                  background-clip: unset;
                  -webkit-background-clip: unset;
                  -webkit-text-fill-color: white;
                }
              }

              .value {
                font-size: 36px;
                font-weight: bold;
              }

              svg {
                color: $secondary !important;
              }

              .grow {
                flex-grow: 1;
              }

              .separator {
                height: 35px;
                width: 2px;
                border-radius: 5px;
                background: rgba(#507fff, 0.3);
              }

              .ask-ludo-button {
                background: $purpleGradient;
                width: 140px !important;
                height: 40px;
                margin: 0;
                border-radius: 10px !important;
                svg {
                  color: white !important;
                }
              }
            }

            .content {
              .MuiLinearProgress-root {
                margin: 0 25px;
                flex-grow: 1;
                height: 6px;
                background: #f4f3f7;
                .MuiLinearProgress-bar {
                  background: $purpleGradient3;
                }
              }

              .data-wrapper,
              .plot {
                padding: 0 20px;
                max-width: 100%;
                svg {
                  max-width: 100%;
                }
              }

              .content-left {
                padding: 0;
                min-width: 350px;
                width: 100%;

                .description-box {
                  min-height: unset;
                  background: none !important;
                  border: none !important;
                  .name {
                    font-size: 15px;
                    margin: 0;
                  }
                  .keywords {
                    font-size: 15px;
                  }
                  .description {
                    font-size: 16px;
                  }
                  .keywords {
                    margin-top: 20px;
                  }
                }
                .comparison {
                  display: none;
                }
              }
              .collapse-wrapper {
                background: #f6f6f9;
                width: 100%;
                margin: 20px;
                display: flex;
                flex-direction: column;
                border-radius: 22px;
                max-width: calc(100% - 50px);

                .collapse-control {
                  width: 100%;
                  display: flex;
                  cursor: pointer;
                  padding: 20px 30px;

                  .hide-show,
                  svg {
                    color: $primary;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }

                .plot {
                  min-width: 100%;
                  width: 100%;
                }
              }
              .cluster {
                background: none;
                width: 100%;
                .MuiChip-root {
                  display: none;
                }
                .description {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
              }
            }

            &.main-metric,
            &.secondary-metrics {
              .header {
                .description {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  flex-grow: 1;
                  place-content: center;
                  img {
                    height: 40px;
                  }
                }
                padding-bottom: 20px;
                border-bottom: 2px solid transparentize($indigo, 0.87);
              }

              .data-wrapper {
                place-content: space-evenly;
                padding: 20px 0 40px 0;

                .value {
                  background: #f6f6f9;
                  padding: 30px 60px;
                  font-size: 50px;
                  font-weight: bold;
                  color: #cfccde;
                  border-radius: 76px;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .numbers {
                    align-self: center;
                    .number {
                      font-size: 85px;
                      line-height: 85px;
                      background: $secondary;
                      background: $purpleGradient;
                      background-clip: text;
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                  button {
                    background: $purpleGradient;
                    height: 40px;
                    margin: 0;
                    border-radius: 10px !important;
                    svg {
                      color: white !important;
                    }
                  }
                }

                .other-metrics {
                  display: flex;
                  flex-wrap: wrap;
                  max-width: 60%;
                  gap: 20px;
                  row-gap: 30px;
                  align-content: center;

                  .other-metric {
                    display: flex;
                    flex-direction: column;
                    width: 45%;
                    gap: 7px;

                    .MuiLinearProgress-root {
                      max-height: 6px;
                      width: 100%;
                      margin: 0;
                    }

                    .top-row {
                      display: flex;
                      .other-name {
                        font-weight: bold;
                        flex-grow: 1;
                      }
                    }
                  }
                }
              }
            }

            &.secondary-metrics {
              .header {
                border: none;
              }
              .data-wrapper {
                width: 100%;
                padding: 0 0 40px 0;
                .other-metrics {
                  padding: 0 30px;
                  max-width: unset;
                  width: 100%;
                  .other-metric {
                    width: unset;
                    flex-grow: 1;
                  }
                }
              }
            }

            @container competitive-analysis (max-width: 767px) {
              &.main-metric {
                padding: 0;

                .header {
                  flex-direction: column;
                  .description {
                    text-align: center;
                  }
                }

                .data-wrapper {
                  .value {
                    padding: 20px 0 !important;
                    align-items: center;
                    button {
                      width: 150px !important;
                    }
                  }
                  .other-metrics {
                    padding-bottom: 30px !important;
                    max-width: 100% !important;
                    width: 100%;
                    .other-metric {
                      .top-row {
                        flex-direction: column;
                      }
                    }
                  }
                }
              }

              &.secondary-metrics {
                .header {
                  text-align: center;
                }
                .data-wrapper {
                  padding: 15px !important;
                }
                .other-metrics {
                  padding: 0 0 40px 0 !important;
                }
              }
            }
          }
        }
      }
    }

    &.gdd-preview {
      [contenteditable="true"]:empty:before {
        color: transparent !important;
      }

      .loading-wrapper {
        display: none !important;
      }

      .header {
        .background-overlay {
          display: none !important;
        }

        .header-banner {
          img {
            border: none !important;
          }
        }

        &.no-icon {
          .review-bar {
            .genres-wrapper {
              margin-left: 70px;
            }
          }

          .header-icon-wrapper {
            display: none !important;
          }
        }
      }

      .platform-selection {
        .MuiChip-label {
          padding-right: 10px;
        }

        svg {
          display: none;
        }
      }

      .icons-wrapper,
      .add-game {
        display: none !important;
      }

      .show-preview {
        display: block !important;
      }

      .hide-preview {
        display: none;

        & > * {
          display: none;
        }
      }

      .game-element-new {
        display: none !important;
      }

      .dropzone-wrapper {
        display: none !important;
      }

      .menu-wrapper {
        .action {
          display: none !important;
        }

        &.selected,
        &:hover,
        .editable {
          border: 3px solid transparent !important;
          background: transparent !important;
        }
      }

      .gdd-section-actions {
        display: none;
      }

      .section {
        padding: 20px 50px !important;

        &.header,
        &.game-summary {
          padding: 0 !important;
        }

        &.empty {
          display: none;
        }
      }

      .icons-wrapper,
      .fa-icon {
        display: none !important;
      }

      .MuiBadge-root {
        display: none;
      }

      .game-element {
        max-width: 100%;
        min-width: unset;

        &.new {
          display: none !important;
        }
      }
    }

    &.game-guide,
    &.export-project {
      .top-sections .right-section {
        max-height: 265px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 100px 100%);
        .header {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .k-pdf-export {
    width: 100%;
    min-width: 1200px;

    .app-page-title {
      width: 100% !important;
    }

    .MuiChip-root svg {
      display: none;
    }

    .gdd-top-bar-wrapper {
      .top-bar-left,
      .concepts {
        display: none !important;
      }

      .header-wrapper {
        .header-banner {
          display: none;
        }
      }

      .main-description {
        .MuiSelect-root {
          display: none;
        }

        .MuiInput-underline {
          &::after,
          svg {
            display: none;
          }
        }
      }

      .no-header {
        display: none;
      }

      .has-banner {
        margin-top: 110px;
        img {
          border-radius: 8px;
        }
      }
    }

    .loading-wrapper {
      display: none;
    }

    .portrait {
      .background {
        display: none;
      }

      .my-image {
        place-content: flex-start;
      }
    }

    .gdd {
      width: 100% !important;
    }

    .concept-bar {
      display: none !important;
    }

    .editor {
      width: 100%;
    }

    .comments {
      display: none !important;
    }

    .gdd-section-actions {
      display: none;
    }

    .section-content-wrapper {
      .section {
        width: 100%;
      }
    }

    .section {
      width: 100% !important;
      transition: none !important;
      padding: 20px !important;

      .left-content {
        .section-subtitle {
          background: unset;
          background-clip: unset;
          -webkit-background-clip: unset;
          font-weight: bold;
          color: $secondary;
          &.editable .show-preview {
            color: $secondary;
          }
        }
      }

      .game-card {
        transform: scale(0.9);
      }

      .gdd-section-actions {
        display: none !important;
      }

      .game-element {
        &.new,
        &.empty {
          display: none !important;
        }
      }

      .game-card {
        transform: scale(0.9);
      }

      .gdd-section-actions {
        display: none !important;
      }

      .large {
        .content-editable-wrapper {
          padding: 0 !important;
        }
      }

      &.empty {
        position: fixed !important;
        height: 0 !important;
        width: 0 !important;
        display: none !important;
        animation-duration: 0s !important;
      }
    }

    .my-image,
    .animate__animated {
      animation-duration: 0s !important;
    }

    .icons-wrapper,
    .fa-icon {
      display: none !important;
    }

    .MuiBadge-root {
      display: none;
    }

    .show-preview {
      display: block !important;
    }

    .hide-preview {
      display: none;

      & > * {
        display: none;
      }
    }

    .editor {
      padding: 0;
      margin: 0;
    }

    .icons-wrapper {
      display: none;
    }

    .section-content-wrapper {
      background: white !important;
    }
  }
}

.add-to-gdd-modal {
  .MuiPaper-root {
    //max-width: unset;
    //width: fit-content;
  }

  .modal-content {
    box-shadow: unset;
    min-width: 400px;

    button {
      .MuiCircularProgress-root {
        color: white;
      }
    }

    .icon {
      width: 50px;
      height: 50px;
      background: #f0eff3;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 30px;

      img {
        height: 100%;
      }

      svg {
        font-size: 40px;
        color: #cdcdd9;
      }
    }
  }
}

.gdd-floating-input-wrapper {
  background: white;
  position: absolute;
  z-index: 10000;
  left: 0;
  padding: 5px 0 5px 20px;
  top: 60px;
  display: flex;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: $primary;
  background: linear-gradient(135deg, #eaf0f6 0%, #d6dbf5 100%);
  border-radius: 30px;
  align-items: center;
  gap: 15px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.3);

  .MuiInput-underline {
    &::after,
    &::before {
      display: none;
    }
  }

  input {
    color: $secondary;
  }

  .icon-wrapper {
    width: 40px;
    height: 100%;
    cursor: pointer;
    svg {
      font-size: 20px;
      color: $secondary;
    }
  }
}
