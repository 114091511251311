@import "assets/core/_variables";
@import "assets/_mixins";

.generated-game-card {
  max-width: 380px;
  position: relative;
  text-align: left;

  .MuiCircularProgress-root {
    color: $secondary;
    margin: 0 2px;
  }

  .loading-wrapper {
    cursor: default;
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    z-index: 1;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    order: 3;
    width: 100%;
    margin: 0 !important;
    .MuiButtonBase-root {
      margin: 0;
    }
  }

  .image-loading-wrapper {
    display: flex;
    align-self: center;
    place-content: center;
  }

  .generated-image,
  .image-loading-wrapper {
    height: 336px;
    .my-image {
      margin: auto;
      width: fit-content;

      img {
        height: unset !important;
      }
    }
  }

  .game-actions {
    svg {
      color: white !important;
    }
  }

  &:hover {
    .game-actions {
      display: flex !important;
    }
  }

  .blending-games {
    position: absolute;
    top: -20px;
    left: 30px;
    background: white;
    padding: 3px 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    box-shadow: 0 2px 10px 0 rgb(0, 0, 0, 0.3);

    .plus {
      font-size: 20px;
      font-weight: bold;
      color: $primary;
      padding: 0 5px;
    }

    .no-icon {
      display: flex;
      justify-content: center;
      background: $secondary;
      color: white;
      font-weight: bold;
      align-items: center;
    }

    .icon,
    .no-icon {
      z-index: 1;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      box-sizing: content-box;
      cursor: pointer;

      img {
        display: block;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }

  .inner-card {
    background: $light;
    border: 1px solid $light !important;
    box-shadow: none !important;
    padding: 15px 0;
    border-radius: 15px;

    .MuiCardContent-root {
      padding: 0 !important;
    }

    .description {
      white-space: pre-line;
      width: 100%;
      cursor: text;
      line-height: 19px;

      b {
        display: block;
        margin-bottom: 10px;
      }
    }

    .text-element {
      padding: 15px 25px !important;
    }

    .game-actions {
      position: absolute;
      display: none;
      flex-direction: row;
      right: -10px;
      top: 17px;
      z-index: 1;

      button {
        padding: 5px;
      }

      svg {
        color: $secondary !important;
      }

      .MuiAvatar-root {
        display: inline-flex;
        top: 15px;
      }
    }

    .title-wrapper {
      margin-right: 50px;

      .title {
        overflow: hidden;
        text-align: left;
        line-height: 23px;
      }
    }

    .game-info {
      //max-height: 85px;

      .genres {
        gap: 10px;
        display: flex;
        flex-wrap: wrap;

        .MuiChip-root {
          height: 27px;
          padding: 0 5px;
          position: relative;
          font-size: 1em;
        }
      }

      .separator {
        margin: 0 9px;
      }

      .font-weight-bold {
        margin-right: 5px;
      }

      .MuiRating-root {
        height: 20px;
        top: 4px;
      }
    }
  }

  &.small-version {
    display: inline-flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    .favorite-button-wrapper {
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      transition: all 0.2s linear;
      button {
        background: #faf5ff;
        padding: 5px;
      }
    }

    &:hover {
      .favorite-button-wrapper {
        opacity: 1;
      }
    }

    .MuiCardContent-root {
      height: 100%;
      display: flex;
    }

    .game-actions {
      display: none !important;
    }

    .blending-games {
      top: 27px;
      left: 22px;
      border-radius: 0.65rem 0 0.65rem 0;
      box-shadow: none;
      background: linear-gradient(135deg, $lightBlue 0%, $secondary 100%);
      z-index: 10;
      .plus {
        color: white;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-image: linear-gradient(
        135deg,
        $lightBlue 0%,
        $secondary 100%
      ); // Example gradient
      border-radius: 15px;
      transition: all 0.1s ease-in-out;
    }

    .inner-card {
      border-radius: 12px;
      border: none !important;
    }

    .card-box {
      background: #faf5ff;
    }

    span {
      color: $indigo;
    }

    .game-info,
    .genres {
      display: none !important;
    }

    .description {
      font-weight: 300;
    }

    .description .description-1 {
      display: none;
    }

    .generated-image {
      order: 1;
      border-radius: 15px;
      width: 100%;

      .ludo-score-wrapper {
        position: absolute;
        bottom: 2px;
        left: 2px;
        z-index: 10;

        .ludo-score {
          bottom: 5px;
          left: 5px;
          z-index: 1;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          background: transparentize(white, 0);
          padding: 15px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5),
            0 12px 24px rgba(0, 0, 0, 0.2);

          img {
            width: 35px;
            height: 35px;
          }

          &::before {
            content: "";
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border-radius: 50%;
            padding: 3px; /* This creates the border thickness */
            background: linear-gradient(
              90deg,
              rgb(78, 154, 246) 0%,
              rgb(119, 61, 239) 100%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
          }

          span {
            font-size: 16px;
            position: relative;
            background: linear-gradient(90deg, #fde52f 0%, #e99700 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            display: inline-block;
          }

          span::after {
            content: attr(data-text);
            position: absolute;
            left: 2px;
            top: 2px;
            z-index: -1;
            color: black;
            filter: blur(1px);
            opacity: 0.25;
          }
        }
      }
      .my-image {
        padding: 3px;
      }

      &.has-image {
        &:before {
          content: "";
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          z-index: 0;
          background-image: linear-gradient(
            135deg,
            $lightBlue 0%,
            $secondary 100%
          ); // Example gradient
          border-radius: 15px;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .title-wrapper {
      order: 2;
      .title {
        background: rgb(162, 104, 238);
        background: linear-gradient(81deg, #4e9af6 0%, $secondary 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 26px;
      }
    }
    .description {
      order: 3;
      flex-grow: 1;
      cursor: pointer;
    }

    &.selected {
      box-shadow: none;

      .inner-card {
        border: none !important;
      }

      &:before {
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -35px; // Adjust based on the size of the triangle
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 35px solid transparent; // Adjust size to change the width of the triangle
        border-right: 35px solid transparent; // Adjust size to change the width of the triangle
        border-top: 35px solid $secondary; // Adjust color and size to change the color and height of the triangle
      }

      .generated-image {
        .my-image {
          padding: 0;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}

.generated-game-card-grid-detail {
  width: 100%;
  background: linear-gradient(135deg, $lightBlue 0%, $secondary 100%);
  color: white;
  text-align: left;
  position: relative;
  padding: 30px 30px 0 30px;
}

.generated-game-card-grid-detail,
.headquarters3 .showcase-gallery-container {
  .ludo-score-wrapper {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    width: 160px;
    height: 160px;
  }

  .ludo-score {
    bottom: 5px;
    left: 5px;
    z-index: 1;
    flex-direction: column;
    position: relative;
    display: flex;
    padding-top: 25px;
    padding-left: 30px;
    align-items: center;
    width: 50px;
    height: 50px;
    gap: 10px;

    span {
      background: linear-gradient(90deg, #4f8edf 0%, #538eda 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      display: inline-block;
      background-color: #ffc107;
      font-weight: bold;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2),
        -1px -1px 1px rgba(0, 0, 0, 0.2);
      font-size: 20px;
    }

    .score {
      line-height: 15px;
    }

    .number-wrapper {
      position: relative;

      .number {
        font-size: 45px;
        font-weight: bold;
        line-height: 45px;
      }

      .unit {
        position: absolute;
        font-size: 12px;
        opacity: 0.7;
        top: 0;
      }
    }
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    cursor: pointer;
    opacity: 0.5;
  }

  .image-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.05;
    z-index: 0;
    pointer-events: none;
  }

  .MuiCircularProgress-root {
    color: white;
    margin-top: 10px;
  }

  .padded-content {
    padding: 35px 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1500px;
    margin: auto;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;

    &.space-between {
      justify-content: space-between;
    }

    &.horizontal {
      flex-direction: row;
      gap: 20px;
    }

    &.text {
      gap: 0;
    }

    &.info {
      .left {
        max-width: calc(100% - 300px);

        .chips {
          margin-bottom: 20px;
          gap: 10px;
          display: flex;
        }

        .main-info {
          .main-title {
            font-size: 42px;
            font-weight: bold;
            line-height: 42px;
            margin-bottom: 10px;
          }
        }

        .subtitle {
          font-size: 20px;
          font-weight: bold;
        }

        .description {
          white-space: break-spaces;
          margin-bottom: 20px;
        }
      }

      .right {
        width: 100%;
        flex-grow: 1;
        padding-top: 40px;
        gap: 30px;
      }
    }

    .games {
      width: 100%;
      .games-carousel {
        min-height: 78px;
        position: relative;
        display: flex;
        gap: 20px;

        .react-multi-carousel-list {
          width: calc(100% - 50px);
        }

        .carousel-button-group {
          top: unset;
          right: unset;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .button-right,
          .button-left {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
            font-size: 20px;
            padding: 10px;
            width: 30px;
            height: 30px;
            svg {
              font-size: 30px !important;
            }
          }
        }
      }
    }

    .chart-game {
      display: flex;

      .MuiChip-root {
        background: #d1c8ff !important;
        color: #424ac7 !important;
      }

      .trend {
        color: white;
      }

      .title {
        color: white;
      }

      .dev {
        opacity: 0.65;
      }

      .sub-title {
        color: white;
        opacity: 1;
      }

      &:hover {
        .title,
        .sub-title {
          max-width: unset;
        }
      }
    }

    &.trends {
      .chips {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        .MuiChip-root {
          background: #d1c8ff;

          span {
            color: #424ac7;
            font-size: 13px;
          }
        }
      }
    }

    &.actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 0 30px;

      hr {
        flex-grow: 1;
        margin: 12px 20px;
        border-top: 2px solid rgba(white, 0.15);
      }

      .action {
        display: flex;
        gap: 5px;
        cursor: pointer;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-size: 14px;

        a {
          color: white;
        }

        &:hover {
          color: $tertiaryLight;
          a {
            color: $tertiaryLight;
          }
        }

        svg {
          font-size: 20px;
        }
      }
    }

    &.params {
      justify-content: space-between;

      .space-between {
        padding: 0;
      }

      .generation-params {
        background: transparentize(#d1c8ff, 0.25);
        color: #525ad4;
        padding: 10px 15px;
        border-radius: 12px;

        span {
          place-content: center;
        }

        .copy {
          display: flex;
          align-items: center;
          gap: 5px;
          border-left: 2px solid transparentize(#525ad4, 0.7);
          padding-left: 10px;
          place-content: center;
          cursor: pointer;
          transition: color 0.2s ease-in-out;

          &:hover {
            color: $primary;
          }

          svg {
            font-size: 19px;
          }
        }
      }

      .my-button {
        margin: 0;
      }
    }
  }

  .small-title {
    font-size: 20px;
    font-weight: bold;
    text-wrap: nowrap;
  }

  .tiny-title {
    font-weight: bold;
    text-wrap: nowrap;
  }

  hr {
    flex-grow: 1;
    border-top: 2px solid rgba(white, 0.15);
  }

  .main-button {
    padding: 0 50px;
    margin-bottom: 20px;
  }

  .navigation {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 5px 20px;
    width: fit-content;
    margin: 30px auto 0 auto;
    background: transparentize(#2f279c, 0.75);
    border-radius: 40px 40px 0 0;

    .text {
      gap: 0;
      text-align: center;
      font-size: 14px;
      .tiny-title {
        font-size: 24px;
      }
    }

    .arrow-wrapper {
      width: 50px;
    }

    .arrow {
      font-size: 40px;
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &.active {
        font-size: 50px;
        opacity: 1;
        &:hover {
          font-size: 55px;
        }
      }
    }
  }

  &.small-version {
    .ludo-score-wrapper {
      width: 80px;
      height: 80px;

      .ludo-score {
        padding-top: 10px;
        left: 0;
        top: 2px;
        align-items: flex-start;
        padding-left: 5px;
        gap: 5px;
        .number {
          width: 12px;
        }

        .score {
          font-size: 15px;
          line-height: 15px;
          height: 15px;
        }

        .number {
          font-size: 22px;
          font-weight: bold;
          line-height: 20px;
          height: 20px;
        }

        span {
          width: 40px;
        }
      }
    }

    .padded-content {
      padding: 10px;
      gap: 0;
    }

    .collapse {
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 2px solid transparentize(white, 0.79);

      .collapse-title {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        padding: 0 10px;

        svg {
          font-size: 30px !important;
        }
      }

      .chips {
        padding: 0 10px;
      }

      &.last {
        border-bottom: 2px solid transparentize(white, 0.79);
      }
    }

    .actions {
      border: none;
      gap: 15px;
      width: 100%;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      padding: 20px;

      hr {
        display: none;
      }
      .action {
        flex-direction: row;
      }
      .small-title {
        padding: 15px 10px 10px 0;
      }
    }

    .section {
      gap: 0;

      .collapse-title {
        padding: 0;
        svg {
          margin: 0 !important;
        }
      }

      &.right {
        gap: 0;
      }

      &.actions {
        gap: 20px;
      }

      &.info {
        flex-direction: column;
        gap: 0;

        .description {
          margin-top: 10px;
        }

        .subtitle {
          line-height: 23px;
          margin-top: 10px;
        }

        .chips {
          margin-top: 10px;
        }

        .left {
          max-width: unset;
        }
        .right {
          min-width: unset;
          padding-top: 0;
        }
      }

      .generation-params {
        padding-bottom: 15px;

        .params {
          margin-top: 0;
          padding: 0;
        }

        .horizontal {
          //flex-direction: column;
          flex-wrap: wrap;
          gap: 20px;
        }

        hr {
          display: none;
        }
      }

      .games,
      .trends,
      .generation-params {
        padding-top: 15px;
        .small-title {
          display: none;
        }
      }
    }

    .main-button {
      padding: 0 10px;
    }
  }
}

.ludo-score-tooltip-main {
  .MuiTooltip-tooltip {
    background-color: $light;
  }

  .ludo-score-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: $light;
    color: $secondary;

    .metric {
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        font-size: 12px;
        color: $indigo;
      }

      .metric-title {
        font-weight: bold;
        font-size: 18px;
        color: $secondary;
      }

      .MuiLinearProgress-colorPrimary {
        background-color: $tertiaryLight;
      }

      .MuiLinearProgress-barColorPrimary {
        background-color: $secondary;
      }
    }
  }
}
