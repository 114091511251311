@import "assets/core/_variables";

.top-charts {

  //background: white;

  .form-wrapper {

    margin-bottom: 0;

    .store-input {
      .MuiSelect-root {
        span {
          margin-left: 25px;
        }

        .MuiAvatar-root {
          position: absolute !important;
          top: 7px !important;
        }
      }
    }
  }

  .updated-bar {
    display: block;
    width: calc(100% - 80px);
    align-items: center;
    color: $secondary;
    font-style: italic;
    place-content: flex-start;
    margin: 0 40px;
    padding: 15px 0 10px 0;
    border-bottom: 2px solid $light;
  }

}

.top-charts-content {

  max-width: 1300px;
  margin: auto;

  .card-box {
    border: none;
  }

  .chart-title {
    padding-left: 45px;
  }
}

.chart-game {
  padding: 5px;
  height: 78px;
  overflow: visible;

  &:hover {
    background: rgba($secondary, 0.1);
    border-radius: 10px;

    .title, .sub-title {
      max-width: calc(100% - 75px);
    }

    .actions {
      visibility: visible;

      svg {
        color: $secondary !important;
      }
    }
  }

  .actions {
    visibility: hidden;

    display: flex;
    position: absolute;
    right: 0;
    top: 6px;
    place-items: center;
    transition: none;
    animation: none;

    .icon-button {
      margin: 0;
      height: 35px;
      width: 35px;
      transition: none;
    }
  }

  &:not(.action-menu) {
    .position {
      width: 50px;
      text-align: right;
      font-size: 25px;
    }

    .MuiListItemAvatar-root {
      padding-right: 5px;
    }

    .MuiListItem-root {
      padding: 0;
      margin: 0;
    }

    .MuiListItemText-root {
      width: 230px;
    }

    .MuiCircularProgress-root {
      top: 4px;
      position: relative;
      margin: 0 5px;
    }

    .title {
      font-size: 16px;
      padding-bottom: 2px;
      font-weight: bold;
    }

    .sub-title {
      color: $lightBlue;
      position: relative;
      display: flex;
      font-size: 14px;
      flex-direction: row;
      gap: 5px;

      .game-topic {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
        transition: all 0.05s linear;

        &:hover {
          transform: scale(1.05);
          .MuiChip-root {
            opacity: 1;
          }
        }
      }

      .MuiChip-root {
        background: $purpleGradient;
        opacity: 0.8;
        color: white;
        border: none;
        font-weight: bold;
        height: 20px;
        cursor: pointer;

        span {
          text-transform: capitalize;
          font-size: 13px;
        }
      }
    }

    .dev, .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: unset;
    }

    .trend {
      align-items: center;
      font-weight: bold;
      display: inline-flex;
      color: $secondary;
    }

    .dev {
      display: block;
    }

    .MuiListItemAvatar-root {
      min-width: unset !important;
      margin: 0 5px;

      img {
        height: 55px;
        border-radius: 5px;
      }
    }
  }

  .action-menu {
    .MuiListItem-root {
      padding: 10px!important;
    }
  }
}
