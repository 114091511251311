@import "assets/core/_variables";

.assistant-chat {

  display: flex;
  flex-direction: row;
  height: 100%;
  z-index: 10;
  position: relative;
  width: 100% !important;

  .content-wrapper {
    flex-grow: 1;
  }

  .top-bar {
    height: 62px;
    width: 100%;
    display: flex;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    place-items: center;
    padding-left: 30px;

    .buttons {
      color: $lightBlue;
      flex-grow: 1;
      place-items: center;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }

    span {
      font-weight: bold;
      font-size: 18px;
      max-width: 400px;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .archives {
    background: $indigo;
    color: white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 130px);
    z-index: 99999;
    width: 100%;
    max-width: 330px;
    gap: 15px;
    padding-left: 10px;

    .archive-header {
      padding: 15px;
      display: flex;
      place-items: center;
      .gradient-text {
        font-size: 20px;
        font-weight: bold;
        flex-grow: 1;
      }
    }

    .archive {
      display: flex;
      flex-direction: row;
      place-items: center;
      cursor: pointer;
      padding: 0 20px;
      opacity: 0.9;

      .options {
        display: none;
      }

      &.active {
        color: $lightBlue;
        .options {
          display: none!important;
        }
      }

      .description {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }

      &:hover {
        opacity: 1;
        .options {
          display: block;
        }
      }
    }
  }

  .chat-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 50px;
    flex-grow: 1;
  }

  * {
    user-select: text !important;
  }

  .top-banner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;

    .logo-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: 90px;
      height: 90px;
      border: 3px solid $secondary;
      display: flex;
    }

    .top-text {
      display: inline-block;
      font-weight: bold;
      font-size: 22px;
      line-height: 25px;
      text-align: center;
      .blue {
        color: $lightBlue;
      }

      .purple {
        color: $tertiary;
      }
    }
  }

  .refresh-follow-ups {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin: auto;
    max-width: 1000px;
    margin-top: 30px;

    span {
      color: transparentize($secondary, 0.25);
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: $secondary;
      }
    }

    .MuiCircularProgress-root {
      margin: 0 30px;
    }

    hr {
      flex-grow: 1;
      border-top: 2px solid rgba($primary, 0.1);
    }
  }

  .follow-ups {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    flex-grow: 1;
    place-content: flex-end;

    .follow-up {
      color: transparentize($indigo, 0.5);
      border: 1px solid transparentize($indigo, 0.5);
      padding: 5px 10px;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      max-width: calc(50% - 10px);
      flex: auto;
      align-content: center;

      &:hover {
        color: $indigo;
        border: 1px solid $indigo;
      }
    }

    &.large {
      flex-direction: row;
      flex-wrap: wrap;
      align-self: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      max-width: 1300px;

      container-type: inline-size;

      .follow-up-large {

        flex: 1 0 20%;

        border-radius: 27px;
        background: linear-gradient(135deg, #EAF0F6 0%, #D6DBF5 100%);
        padding: 20px;
        color: $indigo;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        position: relative;
        padding-bottom: 48px;
        //max-width: 250px;
        //min-width: 200px;

        .icon {
          width: fit-content;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }

        &:hover {
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
          transform: translateY(-1px);
        }
      }

      @container (max-width: 700px) {
        .follow-up-large {
          flex: 1 0 45%; /* Each item will take up 25% of the container width */
        }
      }

      @container (max-width: 500px) {
        .follow-up-large {
          flex: 1 0 100%; /* Each item will take up 25% of the container width */
        }
      }

    }
  }

  .messages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    min-height: 500px;
    max-height: calc(100vh - 300px);
    width: 100%;
    max-width: 890px;
    margin: auto;
    color: black;
    gap: 10px;
    padding: 10px 20px;
    background: white;
    border-radius: 25px;

    .message {
      color: white;
      display: flex;
      flex-direction: column;
      //font-weight: bold;
      position: relative;

      .body-wrapper {
        display: flex;
        flex-direction: column;
      }

      .actions {
        display: flex;
      }

      .regenerate, .cancel, .copy {
        margin: 5px 0;
        align-self: flex-start;
      }

      &.waiting {

        @keyframes cursor-blink {
          0% {
            opacity: 0;
          }
        }

        .message-body {
          &::after {
            content: "";
            width: 5px;
            height: 15px;
            background: $indigo;
            display: inline-block;
            animation: cursor-blink 1.5s steps(2) infinite;
          }
          &.loading {
            &::after {
              display: none;
            }
          }
        }
      }

      .message-author {
        display: none;
      }

      .message-body {
        overflow-wrap: break-word;
        white-space: pre-wrap;
        margin: 0;
        flex-grow: 1;
        font-size: 16px;
        max-width: 100%;
        color: $indigo;
        background: linear-gradient(135deg, #EAF0F6 0%, #D6DBF5 100%);
        padding: 15px 20px;
        border-radius: 15px;
        gap: 5px;
        display: flex;
        flex-direction: column;

        .generated-game-card-grid-detail {
          max-width: 760px;
          width: 100%;
          border-radius: 15px;
        }

        .generating-image {
          position: relative;
          border-radius: 10px;
          max-width: 336px;
          background: transparentize($indigo, 0.9);

          img {
            max-width: 100%;
            height: auto;
          }

          .button-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .my-button {
              height: 50px;
              max-width: 100%;
            }
          }
        }

        .image-description {
          opacity: 0.75;
          font-style: italic;
        }

        .links {
          display: flex;
          flex-direction: row;
          gap: 0 10px;
          flex-wrap: wrap;
          padding-bottom: 15px;
        }

        .references {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-style: italic;
          gap: 5px;

          .description {
            opacity: 0.5;
          }

          .show-all {
            cursor: pointer;
            text-decoration: underline;
            opacity: 0.5;
          }

          .reference {
            background: none;
            border: 1px solid $secondary;
            .MuiChip-label {
              color: $secondary;
              padding-left: 5px;
            }
            svg, .MuiChip-label {
              color: #a268ee !important;
            }
            .topic-icon {
              position: relative;
              top: 0;
              border-radius: 50%;
              height: 23px;
              width: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
              margin-right: 2px;

              svg {
                height: 23px;
                width: 23px;
                border: 1px solid $secondary;
                border-radius: 50%;
              }
            }
          }
        }

        pre, p, ul, ol {
          padding-bottom: 0;
          margin-bottom: 0;
          white-space-collapse: collapse;
        }

        p {
          white-space: break-spaces;
        }

        ol {
          padding-inline-start: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        li {

          padding: 2px 0!important;

          &::marker {

          }
        }

        ol li{
          padding-top: 0;
          padding-bottom: 0;
        }

        pre code {
          padding: 20px;
        }

        code {
          font-family: "Fira Code", monospace !important;
          font-size: 13px;
          background: $primary;
          color: $tertiaryLight;
          border-radius: 6px;
          overflow-x: auto;
          word-wrap: break-word;
          width: fit-content;
          white-space: pre-wrap !important;
          display: inline-flex;
          padding: 0 5px;
          &[class^="language"] {
            display: flex;
            width: 100%;
          }
        }

      }

      &.own {
        .body-wrapper {
          .message-body {
            font-size: 15px;
            max-width: calc(100% - 50px);
            width: fit-content;
            align-self: end;
            background: $indigo;
            color: white;
          }
        }
      }
    }
  }

  .disclaimer {
    padding: 15px;
    max-width: 500px;
    margin: auto;
    opacity: 0.7;

    .disclaimer-content {
      padding: 5px 50px 5px 15px;
      font-size: 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-radius: 5px;
      text-align: center;
    }
  }

  .input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 890px;
    margin: auto;
    padding: 0 20px;
    position: relative;
    align-items: center;
    gap: 5px;

    .clear-history {
      background: $secondary;
    }

    .input-box-wrapper {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      transition: border 0.1s linear;
      font-size: 16px;
      font-weight: bold;
      color: $primary;
      background: linear-gradient(135deg, #EAF0F6 0%, #D6DBF5 100%);
      padding: 20px 20px;
      border-radius: 30px;
      min-height: 46px;
      width: 100%;
      align-items: center;
      gap: 15px;

      .horizontal {
        width: 100%;
        align-items: flex-start;
        display: flex;
      }

      .images {
        display: flex;
        width: 100%;
      }

      .attachment {
        margin-right: 5px;
        max-height: 50px;
        position: relative;
        .close {
          z-index: 1;
          position: absolute;
          top: -7px;
          right: -5px;
          font-size: 20px;
          cursor: pointer;
          background: white;
          border-radius: 50%;
          border: 1px solid rgba(0,0,0, 0.2);
        }
      }

      .add-attachment {
        margin-right: 10px;
      }

      .send-message, .cancel, .add-attachment {
        height: 20px;
        width: 20px;
      }

      .input-box {
        width: 100%;
        position: relative;

        &::before {
          color: transparentize($primary, 0.5);
        }
      }
    }
  }
}

.assistant-chat-page {
  .messages {
    max-height: calc(100vh - 298px);
  }
}
