@import "assets/core/_variables";

.details-panel {

  .image-details {
    margin-top: 25px;

    .actions {
      padding-bottom: 25px!important;
    }

    .header {
      height: fit-content;

      .image-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
          max-height: 550px;
          width: auto;
          margin: auto;
          max-width: 100%;

          &.horizontal {
            width: 100%;
          }
        }
      }
    }

    .game-info {
      margin: auto;
      //padding: 2px 10px;
      border-radius: 10px;

      .rating {
        color: $lightPurple;

        .MuiRating-root {
          margin: auto;
        }
      }

      .link {
        max-width: 90%;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .rating {
        height: 50px;
      }

      .more-box {
        background: $indigo;
        margin-top: 20px;
        padding: 30px;

        .info-element {
          margin-bottom: 15px;
          margin-top: 0;

          .info-content {
            span {
              font-size: 24px;
            }

            .info-small {
              font-size: 13px;
            }
          }
        }

        button {
          margin: 10px auto !important;
        }
      }

      .icon {
        z-index: 1;
        width: 60px;
        height: 60px;
        margin: 10px auto;
        border-radius: 20px;
        overflow: hidden;
        border: 4px solid white;
        box-sizing: content-box;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .subtitle {
        font-size: 13px;
        overflow: hidden;
        min-height: 40px;
        padding-top: 5px;
      }
    }
  }
}
