@import "assets/core/_variables";

.gdd-side-menu-3 {
  width: 460px;
  //min-width: 460px;
  max-width: 100vw;
  background: $primary;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 15000;
  overflow-y: scroll;
  position: fixed;
  height: 100%;
  right: 0;

  &.option-chat {

    overflow-y: hidden;

    .menu-top-bar {
      .title-icon {
        display: none!important;
      }
    }

    .menu-section-content, .menu-section {
      height: 100%;
    }

  }

  .loading-tip {
    display: none;
  }

  * {
    user-select: none !important;
  }

  .main-title {
    font-weight: bold;
    margin: 10px 0;
  }

  .secondary-title {
    color: $tertiary;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    display: block;

    .hint-wrapper svg {
      color: $tertiary!important;
    }
  }

  .menu-section {
    display: flex;
    flex-direction: column;

    .menu-top-bar {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 10px 10px 20px;

      .title {
        flex-grow: 1;
        display: inline-flex;
        font-size: 24px;
        font-weight: bold;
        color: $lightBlue;
      }

      .close-menu-button {
        display: inline-flex;
        svg {
          color: $lightBlue;
        }
      }

      .back-menu-button {
        padding-left: 30px;
      }
    }

    .menu-section-content {
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .menu-option-title-content {
      color: $tertiary;
      border-bottom: 1px solid transparentize($light, 0.85);
      border-top: 1px solid transparentize($light, 0.85);
      padding: 15px 0;
      margin: 0;
      display: flex;
      width: 100%;

      span {
        flex-grow: 1;
      }

      &.active, &:hover {
        font-weight: bold;
      }
    }

    .section-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      place-content: center;
      margin-bottom: 10px;
      margin-right: 3px;
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 15px;
      cursor: pointer;
      transition: border-color 0.1s linear;
      height: 50px;
      background: $indigo;
      color: white;

      &:hover, &.active {
        border: 2px solid $secondary;
      }

      &.disabled {
        border: 2px solid #1D4EA4 !important;
        opacity: 0.3;
        cursor: default;
      }

      svg {
        color: white;
      }

      span {
        display: block;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
        text-align: center;
      }
    }

    .mechanics-wrapper {
      .mechanic {
        cursor: pointer;
      }
    }

    &.assets {

      .MuiTabs-flexContainer {
        overflow: auto;
      }

      .form {
        justify-content: center;
        .thumbnail-preview {
          align-self: center;
        }
      }

      .main-generator-content {
        .buttons {
          justify-self: center;
        }
      }

    }

    &.index {
      .menu-section-content {
        padding: 0 20px 20px 20px;

        .suggestions-loading {

          display: flex;
          height: 120px;
          justify-content: center;
          align-items: center;

          svg {
            color: white;
          }

        }

      }
    }

    .image-generator {

      .form-wrapper {
        .image-options-wrapper {
          gap: 15px;
          .slider-wrapper {
            button {
              width: 200px!important;
            }
          }
        }
      }

      .main-generator-content {
        background: $indigo;
      }
    }

    .game-ideator2 {

      .main-generator-content {
        background: $indigo;

        .section-title {
          display: none;
        }
      }

      .seeds > .MuiGrid-root > .MuiGrid-root {
        max-width: 100%;
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;

        .game-actions .more-actions {
          display: none;
        }
      }

      .type-seed {
        display: none;
      }

    }

    .top-charts {
      .form-wrapper {
        padding: 40px 50px;
      }

      .chart-title {
        color: white;
      }

      .chart-game {
        .position, .title {
          color: white!important;
        }
      }
    }

    .asset-generator {
      .secondary-title {
        color: $lightBlue;
        font-size: 16px;
        padding-left: 20px;
      }
      .form-wrapper {
        .image-options-wrapper {
          display: flex;
          gap: 15px;
          .slider-wrapper {
            button {
              width: 200px!important;
            }
          }
        }
      }
    }

    .three-d-asset {
      cursor: pointer;
      max-width: 350px;
    }

    .game-trends {

      .form-wrapper {
        position: relative !important;
        z-index: 1;
      }

      .search-row {
        flex-direction: column;
      }

      .trending-content {
        padding: 0;

        .sorting-form-wrapper {
          margin-left: 30px;
        }
      }
    }

    .image-search, .game-search {

      background: $indigo;

      .results-wrapper, .icon-gallery {
        padding: 0 15px;
      }

      .icon-wrapper {
        .details {
          .title {
            color: white !important;
          }
        }
      }

      .submit-button-wrapper {
        margin-top: 0;

        .MuiButtonBase-root {
          //height: 30px;
          width: 100% !important;
        }
      }
    }

    .dropzone {
      .dropzone-inner-wrapper {
        height: calc(100vh - 350px);
        background: #133F84;
        border: 2px dashed transparentize(#e6e7f1, 0.8);
      }
    }

    .game-card {
      width: 190px;

      .name {
        color: white;
      }
    }

    .explanation {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      width: calc(100% - 40px);
      color: $tertiary;
      margin: 0 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid transparentize($light, 0.85);
    }

    .suggestions {

      display: flex;
      flex-direction: column;

      .suggestions-wrapper {
        padding: 0 23px;
      }

      .suggestion {
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        margin: 10px 0;
        color: white;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        border-bottom: 1px solid transparentize($light, 0.85);

        .spinner {
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .generated-image {
          width: 336px;
          position: relative;
          margin: 0 auto 10px auto;

          .background {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            filter: blur(8px);
            -webkit-filter: blur(8px);
            -webkit-clip-path: inset(0);
            clip-path: inset(0);
            background-blend-mode: darken;
          }

          .my-image {
            height: 336px;
            margin: auto;
            width: fit-content;

            img {
              max-height: 336px !important;
              width: auto !important;
            }
          }
        }

        .option-index {
          color: transparentize($light, 0.75);
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;

          .copy-chip {
            color: $lightBlue;
            font-weight: bold;
            background: transparent;
            margin-left: -10px;
            font-size: 16px;
          }

          .add {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            .MuiButtonBase-root {
              background: #4E9AF6;
              border-radius: 5px;
              padding: 15px 10px;
              .MuiChip-label {
                font-size: 16px;
              }
            }
          }
        }
      }

      .load-more-button {
        margin: 25px auto;
        border-image: linear-gradient(45deg, #812aef, #45b1f8) 1;
        border-radius: 10px;
        background: linear-gradient($darkBlue, $darkBlue) padding-box,
        linear-gradient(45deg, $tertiary, #45b1f8) border-box;
        border: 2px solid transparent;

        .MuiButton-label {
          color: $tertiary;
          background:$tertiary;
          background: linear-gradient(81deg, rgba(162,104,238,1) 0%, rgba(162,104,238,1) 44%, rgba(69,177,248,1) 74%, rgba(0,212,255,1) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &:hover {
          border: 2px solid $tertiary;
          .MuiButton-label {
            background: transparent;
            color: $tertiary;
            background-clip: unset;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
          }
        }

        &.loading {
          .MuiButton-label {
            background: transparent;
            background-clip: unset;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
          }
        }
      }
    }
  }
}
