@import "assets/core/_variables";

.projects-modal {
  .MuiDialog-paper {
    width: 1000px !important;
    max-width: 100vw;
    max-height: 500px;
  }

  .MuiDialog-container {
    .MuiPaper-root {
      padding: 0 !important;
    }
  }

  .content-wrapper {
    width: 100%;
  }
}

.active-project-widget {

  .top {
    font-size: $sidebar-header-font-size;
    font-weight: bold;
    display: flex;
    flex-direction: row;

    .actions {
      flex-grow: 1;
      text-align: left;
      position: relative;
      top: -4px;
    }
  }

  .title {
    font-size: 28px;
    //cursor: pointer;
    line-height: 28px;
    padding: 10px 20px 10px 20px;
    margin-right: 20px;
    display: block;
    font-weight: bold;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .thumbnail {
    padding-right: 5px;
  }

}

.projects-new {
  overflow: auto;
  display: flex;
  flex-direction: column;

  .back {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }

  .main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 300px;

    .project-list {
      width: 311px;
      overflow-y: auto;

      .project-badge {
        background: white;
        height: 75px;
        cursor: pointer;
        position: relative;

        .delete-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          display: none;
        }

        &:hover {
          .delete-icon {
            display: block;
          }
        }

        p {
          opacity: 0.8;
          margin-top: 5px;
        }

        span {
          font-weight: bold;
        }

        span, svg, .MuiTypography-root {
          color: $primary;
        }

        &.blue {
          background: $primary;

          span, svg, .MuiTypography-root {
            color: white;
          }

          &:hover {
            background: transparentize($primary, 0.15);
          }
        }

        &.Mui-selected {
          background: $secondary;

          span, svg, .MuiTypography-root {
            color: white;
            font-weight: bold;
          }

          &:hover {
            background: transparentize($secondary, 0.15);
          }
        }

        &:hover {
          background: lightgray;
        }
      }

    }

    .project-details {
      flex-grow: 1;
      padding: 30px;
    }
  }

}
