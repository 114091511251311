@import "assets/core/_variables";

.register-paywall2 {

  .info {
    font-size: 17px;
  }

  .payment-form {
    .MuiTextField-root {
      margin-bottom: 5px;
    }

    .MuiAutocomplete-root {
      input, .MuiInputBase-root {
        padding: 5px !important;
      }
    }

    .new-credit-card {
      margin-top: 20px;
    }

    .plan-info {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .info {
        margin-top: 15px;
      }

      .box {
        display: flex;
        flex-direction: column;
        background: transparentize(white, 0.8);
        border-radius: 10px;
        padding: 20px;
        gap: 10px;
      }

      .row-wrapper {
        padding: 10px 0;
        &.line {
          border-bottom: 1px solid transparentize(white, 0.8);
        }

        .row {
          display: flex;
          flex-direction: row;

          .title {
            font-weight: bold;
          }

          .description {
            flex-grow: 1;
            text-align: right;
          }

          .amount {
            text-align: right;
            width: 50px;
          }
        }
      }
    }

  }

}
