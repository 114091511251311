@import "assets/core/_variables";

.gdd-top-bar-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  container-type: inline-size;
  position: relative;
  background: white;

  .hover-options2 {
    position: relative;
    top: 0;
    display: none;
    z-index: 1;

    .MuiButtonBase-root {
      background: none;
      border: 1px solid white;
      box-shadow: none;
    }
  }

  .concepts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $indigo;
    height: 66px;
    padding-left: 40px;

    .selected-concept {
      display: flex;
      flex-grow: 1;
      .concept {
        width: fit-content;
        justify-content: flex-start;
        min-width: 250px;
      }
    }

    .other-concepts {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }

    .concept {
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      place-content: center;
      max-width: 100%;
      align-items: center;
      gap: 10px;
      position: relative;

      &.image-only {
        .lock-icon {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1;
          padding: 0;
          pointer-events: none;
          svg {
            padding: 0;
            opacity: 1;
            color: $dark !important;
            filter: drop-shadow(0 0 2px white);
          }
        }
      }

      &.locked {
        span {
          opacity: 0.5;
        }
      }

      .lock-icon {
        padding-left: 5px;

        svg {
          color: white !important;
          opacity: 0.5;
        }
      }

      &.active {
        color: white;

        svg {
          color: white;
        }
      }

      &:hover,
      &.active {
        button {
          visibility: visible;
        }
      }

      .icon,
      .icon-placeholder {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: 2px solid $secondary;
      }

      .icon-placeholder {
        background: $light;
        padding: 5px;
        svg {
          color: transparentize($dark, 0.3);
          width: 100%;
          height: 100%;
        }
      }

      button {
        transition: visibility 0s;
        visibility: hidden;
        width: 30px;
        height: 30px;
        position: relative;
        top: -2px;

        svg {
          color: $lightBlue;
        }
      }
    }

    .all-concepts {
      min-width: fit-content;
    }

    .new-concept {
      margin-right: 10px;
      button {
        width: 40px;
        height: 40px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    @container (max-width: 862px) {
      .other-concepts,
      .selected-concept {
        display: none;
      }
    }
  }

  .top-sections {
    display: flex;
    position: relative;

    .left-section {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: calc(100% - min(500px, 50%));
      min-width: 50%;
      z-index: 1;

      .top-bar-left {
        display: flex;
        flex-direction: row;
        padding: 15px 30px 15px 40px;
        height: auto;
        position: relative;
        max-width: 100%;
        flex-wrap: wrap;
        gap: 15px;

        &::after {
          content: "";
          display: block;
          height: 2px;
          width: calc(100% - 50px);
          background: $purpleGradient;
          opacity: 0.3;
          position: absolute;
          bottom: -3px;
          left: 40px;
        }

        .my-button {
          min-width: fit-content;
        }

        .main-options {
          display: flex;
          gap: 20px;
          flex-grow: 1;

          .read-only {
            display: flex;
            align-items: center;
          }

          .tabs {
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            align-items: center;
            span {
              font-size: 20px;
              background: rgb(162, 104, 238);
              background: $purpleGradient;
              background-clip: text;
              background-size: 100% 100%;
              font-weight: bold;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              cursor: pointer;
              position: relative;

              &::after {
                position: absolute;
                bottom: -8px;
                content: "";
                width: 100%;
                height: 1px;
                opacity: 0;
                left: 0;
                border-radius: 10px;
                background: $purpleGradient;
                transition: all 0.2s ease;
              }

              &:hover {
                &::after {
                  height: 1px;
                  opacity: 0.5;
                }
              }

              &.selected {
                &::after {
                  opacity: 1;
                  height: 5px;
                }
              }
            }
          }

          .MuiTabs-root {
            border: none !important;

            .MuiTab-wrapper {
              font-size: 20px;
              background: rgb(162, 104, 238);
              background: $purpleGradient;
              background-clip: text;
              background-size: 100% 100%;
              font-weight: bold;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              flex-direction: row;
              gap: 10px;
              img {
                margin-bottom: 0;
              }
            }

            .MuiTouchRipple-root {
              display: none;
            }

            .MuiTabs-indicator {
              min-height: 10px;
              bottom: -8px;
              background: $purpleGradient;
            }
          }
        }

        .actions {
          display: flex;
        }
      }

      .icon {
        .loading-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .right-section {
      display: flex;
      flex-grow: 1;
      width: calc(min(500px, 50%));
      flex-direction: column;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 250px 100%);
    }

    @container (max-width: 862px) {
      flex-direction: column-reverse;

      .export-project-header {
        padding: 10px;
      }

      .left-section,
      .right-section {
        width: 100%;
      }
      .right-section {
        clip-path: unset!important;
        overflow: hidden!important;
        justify-content: center!important;
        max-height: 200px!important;
      }

      .top-bar-left {
        flex-wrap: wrap;
        height: auto;
        padding: 10px;
        gap: 20px 0;
        justify-content: flex-end;

        &::after {
          left: 15px;
          width: calc(100% - 30px);
        }
      }

      .main-description {
        padding: 20px 40px 20px 40px;
        .row {
          flex-direction: column;
          .icon {
            align-self: center;
          }
          .section-content-wrapper {
            width: unset;
          }
        }
      }
    }
  }

  .main-description {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 40px;
    gap: 15px;

    .row {
      display: flex;
      gap: 20px;

      .icon {
        margin-top: 35px;
        padding: 0 32px 0 0;
      }

      .section-content-wrapper {
        background: none;
        width: calc(100% + 50px);

        .gdd-section-actions {
          top: 29px;
        }

        .comments {
          right: -320px;
          z-index: 1;
        }

        .game-summary {
          width: 100%;
        }

        .section {
          padding: 0 0 40px 0;
          min-width: unset;

          .content-editable-wrapper {
            min-width: unset;
          }
        }
      }
    }

    .genres-wrapper {
      margin: 0;

      .MuiInput-underline {
        &::after {
          display: none;
        }
      }

      .genre {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }

      .multiple-genres-input,
      .platform-selection {
        padding-bottom: 0;

        .MuiChip-deletable {
          padding-right: 0 !important;
        }

        .add-icon-wrapper {
          align-self: center;

          .add-icon {
            border: none !important;
          }
        }
      }

      .platform-selection {
        .MuiChip-label {
          padding-right: 20px;
        }

        svg {
          right: 5px;
        }
      }
    }

    .header-icon-wrapper {
      z-index: 11 !important;
      width: 150px !important;
      height: 150px !important;
      cursor: pointer;
      position: relative;

      .icon-gradient {
        display: none;
        z-index: 4;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 8px;
        pointer-events: none;
        background: linear-gradient(
          180deg,
          transparentize($secondary, 0.3) 0%,
          transparent 20%,
          transparent 80%,
          transparentize($secondary, 0.3) 100%
        );
      }

      &:hover {
        .icon-gradient {
          display: flex;
        }
      }

      &:hover,&.selected {
        &:before {
          content: "";
          position: absolute;
          top: -7px;
          left: -7px;
          right: -7px;
          bottom: -7px;
          z-index: 0;
          background-image: linear-gradient(
              135deg,
              $lightBlue 0%,
              $secondary 100%
          ); // Example gradient
          border-radius: 15px;
          transition: all 0.3s ease-in-out;
        }
      }

      &.generating {
        .header-icon {
          display: flex !important;
          z-index: 10;

          .dropzone-wrapper {
            display: none;
          }
        }

        &.has-image {
          .header-icon {
            opacity: 0;
          }
        }
      }

      .icon-image {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
      }

      .add-wrapper {
        //this is the little icon when there's no image
        position: absolute;
        background: #f0eff3;
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;

        svg {
          color: #cdcdd9;
          font-size: 100px !important;
        }
      }

      .header-icon {
        width: 100%;
        height: 100%;
        box-shadow: 0 10px 10px 5px transparentize(black, 0.85);
        border-radius: 8px;
        position: absolute;
        opacity: 1;
        display: none;

        .add-icon {
          place-content: center;
          border-radius: 12px;
          overflow: hidden;

          .loading-wrapper {
            z-index: 100;
          }

          .hover-options {
            display: none !important;
          }
        }

        .MuiCircularProgress-root {
          position: absolute;
        }

        .icon-image {
          z-index: 19;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 8px;
        }
      }

      &.selected {
        border-radius: 10px;

        .add-wrapper {
          display: none;
        }

        .hover-options {
          display: flex !important;
        }

        .header-icon {
          display: block;
        }
      }
    }
  }

  .header {
    max-width: 100%;
    padding: 0;
    position: relative;
    //height: 350px;
    z-index: 10;

    .hidden-image {
      display: none;
    }

    .add-button {
      button {
        background: $secondary;
      }
    }

    &.active,
    .selected {
      z-index: 1;

      .header-gradient {
        display: block;
      }
    }

    &.has-banner {
      margin-top: 0;
    }

    .loading-wrapper,
    .add-button {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .header-gradient {
      display: none;
      z-index: 4;
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        transparentize($secondary, 0.3) 0%,
        transparent 20%,
        transparent 80%,
        transparentize($secondary, 0.3) 100%
      );
    }

    .header-wrapper {
      //position: absolute;
      width: 100%;
      height: 100%;
      //max-height: 1000px;
      top: 0;

      &:hover {
        .header-gradient {
          display: block;
        }

        .header-banner {
          cursor: pointer;
          border: 1px solid $tertiary;
          img {
            border: 1px solid $tertiary;
          }
        }
      }

      .header-banner {
        z-index: 3;
        //position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        place-content: center;

        img {
          position: relative;
          aspect-ratio: auto;
          max-width: 100%;
          max-height: 100%;
          align-self: center;
          width: 100%;
        }
      }
    }

    .empty-header {
      width: 100%;
      height: 300px;
      background-repeat: repeat no-repeat;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EBEBEF;

      svg {
        font-size: 350px;
        color: #DBDAE4;
        position: relative;
        right: -50px;
      }
    }

    .header-gradient {
      display: none;
      z-index: 4;
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        transparentize($secondary, 0.3) 0%,
        transparent 20%,
        transparent 80%,
        transparentize($secondary, 0.3) 100%
      );
    }

    &.no-header {
      height: 100%;
      .review-bar {
        .right-section .icon-buttons {
          span,
          svg {
            color: $secondary !important;
          }

          .MuiFormControl-root ::after,
          .MuiFormControl-root ::before {
            border-color: $secondary !important;
          }
        }
      }

      .header-add-image {
        padding-top: 50px;
      }

      .header-wrapper {
        //height: 200px !important;
        border: 3px solid #e7e7ec;

        &:hover {
          border: 3px solid $secondary;
        }
      }

      .header-popup-wrapper {
        top: 40% !important;
      }
    }

    .header-banner-add {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      display: flex;
      z-index: 1;
      position: relative;

      .header-popup-wrapper {
        z-index: 3;
        position: absolute;
        left: calc(50% - 22px);
        top: calc(50% - 22px);

        .add-button {
          top: 0;
        }
      }

      .header {
        z-index: 1;
      }

      .white-shadow {
        box-shadow: none;
      }

      .background-overlay {
        background: transparentize($secondary, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        display: none;
      }

      &.generating {
        .add-button,
        .header-popup-wrapper {
          display: none !important;
        }
      }

      &.selected {
        .background-overlay,
        .header-add-image {
          display: flex;
        }

        .add-button {
          display: none;
        }

        .header-wrapper {
          .header-banner {
            border: 3px solid $tertiary;
            img {
              border: 3px solid $tertiary;
            }
          }
        }

        .hover-options {
          display: flex;
          top: 40%;
          z-index: 10;
          left: unset;
          width: 100%;
          right: 0;
          justify-content: center;
        }
      }
    }
  }
}
