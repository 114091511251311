@import "assets/core/_variables";

.game-elements {

  .form-wrapper {
    textarea {
      min-width: 300px;
    }
  }

  .section-icon {
    display: flex;
    flex-direction: column;
    width: fit-content;
    cursor: pointer;
    border: 3px solid transparent;
    text-align: center;
    justify-content: center;
    place-content: center;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    transition: border-color 0.1s linear;
    align-items: center !important;
    background: $indigo;
    color: $tertiary;
    padding: 5px;
    min-width: 100px;

    img {
      height: 55px;
      width: auto;
      padding: 5px;
    }

    &.active {
      border: 3px solid white;
      background: white;
      color: $secondary;
    }

    &:hover {
      border: 3px solid transparentize($secondary, 0.25);
    }
  }

  .checkbox-wrapper {
    .MuiFormControl-root {
      display: inline-block;

      .MuiFormLabel-root {
        padding-right: 5px;
      }
    }
  }
}
