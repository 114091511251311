@import "assets/core/_variables";

.trend-topic-details-panel {

  .loading {
    padding-top: 100px;
  }

  .topic-details {

    .white-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      display: block;
    }

    .info {
      padding: 0 15px 15px 15px;

      .info-content {
        display: flex;
        flex-direction: column;

        .section-title {
          font-size: 23px;
        }

        .game-card {
          width: 190px;
          margin: 0;

          &.locked {
            .name {
              background: white;
              opacity: 0.4;
            }
          }

          .cover-image {
            height: 150px;
          }

          .card-info .name {
            color: white;
          }
        }

      }
    }

    .keywords {
      background: $indigo;
      padding: 20px 0;
      gap: 20px;
      display: flex;
      flex-direction: column;
      place-content: center;
      align-items: center;

      .summary {
        padding: 0 10px;
        color: $tertiaryLight;
      }

      .chips {
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-flow: wrap;
        place-content: center;

        .MuiChip-root {
          background: $purpleGradient;
          color: white;
          border: none;
          font-weight: bold;
          height: 20px;
          width: fit-content;

          span {
            text-transform: capitalize;
            font-size: 15px;
          }
        }

      }

    .topic-actions {
      display: flex;
      flex-direction: row;
      color: $tertiary;
      font-weight: bold;
      gap: 5px;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }
    }

    }

    .card-info span {
      color: $tertiary !important;
    }

    .header {
      height: fit-content;
      position: relative;
      padding-bottom: 10px;

      &.cover-header {
        position: static;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
      }

      .topic-title {
        margin-top: 60px;
        position: relative;
        display: flex;
        align-content: center;

        .center-column {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }

        .topic-header {
          font-size: 14px;
          color: $tertiaryLight;
          font-weight: bold;
        }

        .topic-keyword {
          font-size: 30px;
          text-transform: capitalize;
          font-weight: bold;
        }
      }

      .previous, .next {
        position: relative;
        z-index: 1;
      }

    }
  }
}
