@import "assets/core/_variables";

.game-ideator {

  .form-wrapper {

    .input-fields {
      flex-direction: column;
    }
    .generate-images-checkbox {
      display: flex;
      align-items: flex-end;
    }

  }

  &.full {

    .form-wrapper {
      .input-fields {
        flex-direction: row;
      }
    }
  }

  .image-loading-wrapper {
    height: 336px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: transparentize($secondary, 0.95);
  }

  .generator-wrapper {
    .main-generator-content {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      z-index: 1;

      .clear-seeds {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
      }

      .loading-tip {
        margin-left: 60px;
      }

    }

    .MuiButtonBase-root {
      //margin: 40px auto !important;
    }

    .big-button {
      display: block;
      width: 100%;
      height: 140px;
      line-height: 140px;
      background: $light;
      text-align: center;
      border-radius: 25px;
      font-size: 28px;
      font-weight: bold;
      color: $primary;
      cursor: pointer;

      &:hover {
        background: #C8DCF6;
      }
    }

    .generated-game-card {
      min-width: 330px;

      .title-wrapper {
        margin-right: 0px;
        margin-top: 5px;
      }

      .game-actions {
        top: 0;
      }
    }

    .seeds {

      display: flex;
      flex-direction: row;
      padding-top: 20px;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      position: relative;
      background: white;

      .more-ideas {
        display: flex;
        align-self: center;
        position: relative;
        .icon-button {
          border: 1px solid $secondary;
          padding: 5px!important;
          svg {
            font-size: 35px;
            color: $secondary;
          }
        }
      }

      .type-seed {
        margin: 10px;
      }

      .generated-game-card {
        margin-top: 15px;
      }

      .generation-seed {
        color: $primary;
        border-radius: 15px;
        position: relative;

        .generated-image {
          img {
            height: unset !important;
          }
        }

        .content-editable-wrapper ::before {
          color: transparentize($primary, 0.5);
        }

        &:hover {
          .game-actions {
            display: flex;
          }
        }

        .genres {
          display: flex;
          flex-flow: wrap;
          flex-direction: row;
          padding-bottom: 15px;

          span {
            font-size: 16px;
            padding: 3px 8px;
          }
        }

        .game-actions {
          position: absolute;
          right: 0;
          top: 10px;
          display: none;
          flex-direction: row;

          button {
            width: 36px;
            height: 36px;
          }

          svg {
            color: $secondary;
            font-size: 18px;
          }
        }

        &.cover {
          background-color: black;
          background-position: center !important;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          color: white;
        }

        .title {
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 15px;
          display: block;
          font-size: 24px;
          line-height: 24px;
        }

        .description {
          white-space: pre-line;
          width: 100%;
          cursor: text;
          line-height: 19px;

          b {
            display: block;
            margin-bottom: 10px;
          }
        }

        .action {
          align-self: center;

          .action-button {
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span, svg {
              color: $secondary;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }

      .content-editable-wrapper {
        ::before {
          color: transparentize(white, 0.35);
        }
      }
    }

    .reload-button {
      cursor: pointer;

      &.inactive {
        cursor: default;
        opacity: 0.7;
      }
    }

    .section-title {
      font-size: 20px;
      padding: 20px 15px;
      display: flex;
      flex-direction: row;
    }

    .section-title, .generics {
      .title-text {
        font-size: 20px;
        font-weight: bold;
        color: transparentize($primary, 0.4);
      }
    }

    .section-title .title-text {
      flex-grow: 1;
    }
  }

}
