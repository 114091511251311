@import "assets/core/_variables";

.details-panel {
  position: relative;

  .video-gallery {

    padding: 5px 14px 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }
  }

  .top-source-icon {
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 25%;
    display: flex;
    justify-content: center;
    width: 50%;
    margin: auto;
  }

  .top-center {
    position: absolute;
    width: 100%;
    margin: auto;
    text-align: center;
    top: -45px;
  }

  .game-details {

    .title {
      font-weight: bold;
      line-height: 30px;
      padding: 10px 40px;
    }

    .body {
      .content-wrapper {
        min-height: 100vh;
      }
    }

    .info-stats {
      padding-bottom: 10px;
      padding-top: 5px;

      .stats-title {
        color: $tertiary;
      }

      .info-content {
        text-align: center;
        display: flex;
        flex-direction: column;

        &.developer {
          &:hover {
            color: $tertiary;
          }
        }
      }
    }

    .genres {
      position: relative;
      top: -13px;
      line-height: 30px;
      padding: 0 5px;

      .MuiChip-root {
        height: 26px;
      }

      .MuiChip-label {
        font-size: 0.9em;
        padding: 25px;
        font-weight: bold;
        position: relative;
      }
    }

    .header {
      padding-top: 80px;
      padding-bottom: 10px;

      &.cover-header {
        position: static;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
      }

      .title {
        font-size: 30px;
      }

      .rating {
        height: 50px;
        color: $lightPurple;
      }

      .subtitle {
        font-size: 13px;
        overflow: hidden;
        min-height: 40px;
        padding-top: 5px;
      }

      .image-wrapper {
        height: 150px;

        button {
          width: 60px;
        }

        .icon img {
          height: 100px;
          margin: auto;
          position: relative;
          display: block;
          top: 30px;
          box-shadow: 8px 8px 10px 1px rgba(0, 0, 0, 0.6);
          border-radius: 20px;
          border: 4px solid white;
          cursor: pointer;
        }

        .previous-game {
          position: absolute;
          left: 20px;
          top: 100px;
        }

        .next-game {
          position: absolute;
          right: 20px;
          top: 100px;
        }
      }
    }

    .description {
      white-space: break-spaces;
      overflow: hidden;
      transition: all 2s;
      padding-top: 10px;
      line-height: 19px;
    }

    .expand {
      display: block;
      margin: 5px 0;
      font-weight: bold;
      cursor: pointer;
      width: fit-content;
      color: $tertiary;
    }

    .trend-info {

      padding: 10px 20px;

      .info {
        display: flex;
        flex-direction: column;

        .info-element {
          .MuiSelect-root {
            color: white !important;
            width: 110px;
            padding-right: 0;
          }

          .MuiInputBase-root .MuiSelect-icon {
            color: white;
            position: relative;
            top: 2px;
          }

          .MuiInput-underline:before, .MuiInput-underline:after {
            border-bottom: 1px solid white;
          }
        }
      }

      .js-plotly-plot {
        margin-top: 25px;
        width: 100%;

        .modebar-group {
          display: none;
        }

        .rangeslider-container {
          transform: translate(30px, 330px);
        }


        .zerolinelayer path {
          stroke: rgb(170, 189, 217) !important;
          stroke-opacity: 1 !important;
          stroke-width: 1px !important;
        }

        .xtick, .xtick tspan {
          font-family: 'Bison' !important;
        }
      }
    }

    .tabs {
      text-align: center;
      margin: auto;
      margin-top: 15px;
      color: $lightBlue !important;
      border-bottom: 2px solid transparentize($lightBlue, 0.7) !important;

      &.one {
        width: 63%;

        .MuiTab-root {
          width: calc(100%);
        }
      }

      &.two {
        .MuiTab-root {
          width: calc(50% - 3px);
          padding: 0;
        }
      }

      .MuiButtonBase-root {
        color: $lightBlue !important;
      }

      .MuiTabs-indicator {
        background: $lightBlue !important;
      }
    }
  }
}
