@import "assets/core/_variables";

.competitive-analysis-details-panel {

  .loading {
    padding-top: 100px;
  }

  .info {
    padding: 0 15px 15px 15px;

    .info-content {
      display: flex;
      flex-direction: column;

      .section-title {
        font-size: 23px;
      }
    }
  }

  .games {
    padding: 0 15px 15px 15px;
    .game-card {
      width: 190px;
      margin: 0;

      &.locked {
        .name {
          background: white;
          opacity: 0.4;
        }
      }

      .cover-image {
        height: 150px;
      }

      .card-info .name {
        color: white;
      }
    }
  }

  .keywords {
    background: $indigo;
    text-transform: capitalize;
    font-size: 13px;
    padding: 20px;
    color: $tertiary;
  }

  .card-info span {
    color: $tertiary !important;
  }

  .header {
    height: 170px;
    position: relative;

    &.cover-header {
      position: static;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    .topic-title {
      margin-top: 75px;
      position: relative;

      .topic-header {
        position: absolute;
        font-size: 14px;
        color: $secondary;
        top: -15px;
      }

      .topic-keyword {
        font-size: 30px;
        text-transform: capitalize;
      }
    }

    .previous, .next {
      position: absolute;
      z-index: 1;
      top: 70px;
    }

    .previous {
      left: 20px;
    }

    .next {
      right: 20px;
    }

  }
}
